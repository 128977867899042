import { Formik } from "formik";
import React, { FC } from "react";
import Box from "../../Unknown/Box";
import Paper from "../../Unknown/Paper";
import PatientChangeInformationForm from "../PatientChangeInformationForm";
import useFormikComponent from "./useFormikComponent";
import usePatientFormComponent from "./usePatientFormComponent";
import { Appointment } from "./types";

export type PatientChangeInformationFormContainerProps = {
  appointment: Appointment | null;
};

const PatientChangeInformationFormContainer: FC<
  PatientChangeInformationFormContainerProps
> = ({ appointment }) => {
  const formikProps = useFormikComponent({ appointment });

  const PatientChangeInformationFormProps = usePatientFormComponent();

  return (
    <Formik {...formikProps}>
      <Paper>
        <Box p={6}>
          <PatientChangeInformationForm
            {...PatientChangeInformationFormProps}
          />
        </Box>
      </Paper>
    </Formik>
  );
};

export default PatientChangeInformationFormContainer;
