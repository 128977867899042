import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import AlertTitle from "../AlertTitle";
import Alert, { AlertProps } from "../Alert";
import Grid from "../Grid";
import { CheckCircleOutlineIcon } from "../Icons";

const FormContext = createContext<FormContextProps>({} as FormContextProps);

interface AlertParams {
  isShown: boolean;
  severity?: AlertProps["severity"];
  message?: string;
  description?: string;
}

type MaybeString = string | null;

interface FormContextProps {
  setFormAlert: Dispatch<SetStateAction<AlertParams>>;
  formPatientId: MaybeString;
  setFormPatientId: Dispatch<SetStateAction<MaybeString>>;
  formAppointmentId: MaybeString;
  setFormAppointmentId: Dispatch<SetStateAction<MaybeString>>;
}

type FormAlertProviderProps = {
  children: ReactNode;
};

export const useFormContext = () => useContext(FormContext);

export const FormContextProvider: React.FC<FormAlertProviderProps> = ({
  children,
}) => {
  const [formPatientId, setFormPatientId] = useState<string | null>(null);
  const [formAppointmentId, setFormAppointmentId] = useState<string | null>(
    null,
  );
  const [formAlert, setFormAlert] = useState<AlertParams>({
    isShown: false,
    severity: "success",
    message: "",
  });

  useEffect(() => {
    let clearAlertTimeout: NodeJS.Timeout | undefined;

    if (formAlert.isShown) {
      clearAlertTimeout = setTimeout(() => {
        setFormAlert((prev) => ({ ...prev, isShown: false }));
      }, 5000);
    }
    return () => {
      clearTimeout(clearAlertTimeout);
    };
  }, [formAlert]);

  return (
    <FormContext.Provider
      value={{
        setFormAlert,
        formPatientId,
        formAppointmentId,
        setFormPatientId,
        setFormAppointmentId,
      }}
    >
      {formAlert.isShown && (
        <Grid item pb={6} width="100%">
          <Alert
            iconMapping={{
              success: <CheckCircleOutlineIcon fontSize="inherit" />,
            }}
            severity={formAlert.severity}
          >
            <AlertTitle sx={{ margin: 0 }}>{formAlert.message}</AlertTitle>
          </Alert>
        </Grid>
      )}
      {children}
    </FormContext.Provider>
  );
};
