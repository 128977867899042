import { CircularProgress } from "@mui/material";
import React, { FC, ReactNode } from "react";
import Alert, { AlertProps } from "../../Unknown/Alert";
import Button from "../../Unknown/Button";
import Grid from "../../Unknown/Grid";
import TextField from "../../Unknown/TextField";
import { EmailFieldProps } from "./types";
import useTranslations from "./useTranslations";

export type AuthCheckUserFormProps = {
  emailFieldProps: EmailFieldProps;
  handleSubmit: () => void | Promise<void>;
  alert?: {
    props: AlertProps;
    children: ReactNode | string;
  };
  isSubmitDisabled?: boolean;
  isSubmitLoading?: boolean;
};

const AuthCheckUserForm: FC<AuthCheckUserFormProps> = ({
  emailFieldProps,
  handleSubmit,
  alert,
  isSubmitDisabled,
  isSubmitLoading,
}) => {
  const { btnSubmit } = useTranslations();

  return (
    <Grid container direction="column" wrap="nowrap" spacing={3}>
      {alert && (
        <Grid item mb={6}>
          <Alert {...alert.props}>{alert.children}</Alert>
        </Grid>
      )}
      <Grid item>
        <TextField {...emailFieldProps} fullWidth />
      </Grid>
      <Grid item>
        <Button
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
          fullWidth
          variant="contained"
          size="large"
          startIcon={isSubmitLoading ? <CircularProgress size={20} /> : null}
        >
          {btnSubmit}
        </Button>
      </Grid>
    </Grid>
  );
};

export default AuthCheckUserForm;
