import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      btnDownloadReport: formatMessage(messages.btnDownloadReport),
      btnRequestImages: formatMessage(messages.btnRequestImages),
      requestSuccessMessage: formatMessage(messages.requestSuccessMessage),
      requestSuccessDescription: formatMessage(
        messages.requestSuccessDescription,
      ),
      requestErrorMessage: formatMessage(messages.requestErrorMessage),
      requestErrorDescription: formatMessage(messages.requestErrorDescription),
    };
  }, [formatMessage]);

  return translations;
};

export default useTranslations;
