import { useMemo } from "react";
import { AppointmentListProps } from "../AppointmentList";
import convertAppointmentToListItem from "./convertAppointmentToListItem";
import { Appointment } from "./types";
import useAppointmentListItemChildrenGetter from "./useAppointmentListItemChildrenGetter";
import sortAppointmentsComparator from "./sortAppointmentsComparator";

const useAppointmentListComponent = (params: {
  appointments: Appointment[] | null;
}) => {
  const { appointments } = params;

  const appointmentListItemChildrenGetter =
    useAppointmentListItemChildrenGetter();

  const props = useMemo<AppointmentListProps | null>(() => {
    if (!appointments) return null;

    const result: AppointmentListProps = {
      appointments: appointments
        .sort(sortAppointmentsComparator)
        .map((appointment) => {
          const data = convertAppointmentToListItem(appointment);

          const children = appointmentListItemChildrenGetter(appointment);

          return { data, children };
        }),
    };
    return result;
  }, [appointmentListItemChildrenGetter, appointments]);

  return props;
};

export default useAppointmentListComponent;
