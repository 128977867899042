import { httpsCallable } from "firebase/functions";
import { useFunctions } from "reactfire";
import { useMemo } from "react";
import {
  SendCustomerPortalAuthCodeParams,
  SendCustomerPortalAuthCodeResponse,
  CreateCustomerPortalCustomTokenParams,
  CreateCustomerPortalCustomTokenResponse,
  ProvideCustomerPortalPatientInformationParams,
  ProvideCustomerPortalPatientInformationResponse,
  ChangeCustomerPortalPatientInformationParams,
  ProvideCustomerPortalPatientPrivacyFormParams,
  ProvideCustomerPortalPatientPrivacyFormResponse,
  ProvideCustomerPortalPatientConsentFormParams,
  ProvideCustomerPortalPatientConsentFormResponse,
  ProvideCustomerPortalAppointmentScreeningFormParams,
  ProvideCustomerPortalAppointmentScreeningFormResponse,
  CustomerPortalReportUnexpectedFindingsConfirmationParams,
  CustomerPortalReportUnexpectedFindingsConfirmationResponse,
  GetAppointmentReportSignedUrlParams,
  GetAppointmentReportSignedUrlResponse,
  RequestImageArchiveDownloadingParams,
  RequestImageArchiveDownloadingResponse,
} from "@Shape-Digital/kudzu-data/lib/types/actions";

import { FUNCTIONS_PREFIX } from "../common/constants";

type FunctionResource = {
  sendCustomerPortalAuthCode: [
    SendCustomerPortalAuthCodeParams,
    SendCustomerPortalAuthCodeResponse,
  ];
  createCustomerPortalCustomToken: [
    CreateCustomerPortalCustomTokenParams,
    CreateCustomerPortalCustomTokenResponse,
  ];
  provideCustomerPortalPatientInformation: [
    ProvideCustomerPortalPatientInformationParams,
    ProvideCustomerPortalPatientInformationResponse,
  ];
  changeCustomerPortalPatientInformation: [
    ChangeCustomerPortalPatientInformationParams,
    ProvideCustomerPortalPatientInformationResponse,
  ];
  provideCustomerPortalPatientPrivacyForm: [
    ProvideCustomerPortalPatientPrivacyFormParams,
    ProvideCustomerPortalPatientPrivacyFormResponse,
  ];
  provideCustomerPortalPatientConsentForm: [
    ProvideCustomerPortalPatientConsentFormParams,
    ProvideCustomerPortalPatientConsentFormResponse,
  ];
  provideCustomerPortalAppointmentScreeningForm: [
    ProvideCustomerPortalAppointmentScreeningFormParams,
    ProvideCustomerPortalAppointmentScreeningFormResponse,
  ];
  provideCheckInAppPatientInformation: [
    ProvideCustomerPortalPatientInformationParams,
    ProvideCustomerPortalPatientInformationResponse,
  ];
  provideCheckInAppPatientPrivacyForm: [
    ProvideCustomerPortalPatientPrivacyFormParams,
    ProvideCustomerPortalPatientPrivacyFormResponse,
  ];
  provideCheckInAppPatientConsentForm: [
    ProvideCustomerPortalPatientConsentFormParams,
    ProvideCustomerPortalPatientConsentFormResponse,
  ];
  provideCheckInAppAppointmentScreeningForm: [
    ProvideCustomerPortalAppointmentScreeningFormParams,
    ProvideCustomerPortalAppointmentScreeningFormResponse,
  ];
  reportUnexpectedFindingsConfrimation: [
    CustomerPortalReportUnexpectedFindingsConfirmationParams,
    CustomerPortalReportUnexpectedFindingsConfirmationResponse,
  ];
  getAppointmentReportSignedUrl: [
    GetAppointmentReportSignedUrlParams,
    GetAppointmentReportSignedUrlResponse,
  ];
  requestImageArchiveDownloading: [
    RequestImageArchiveDownloadingParams,
    RequestImageArchiveDownloadingResponse,
  ];
};

type FunctionReq<K extends keyof FunctionResource> = FunctionResource[K][0];
type FunctionRes<K extends keyof FunctionResource> = FunctionResource[K][1];

const useFirebaseAppFunction = <K extends keyof FunctionResource>(
  functionName: K,
) => {
  const functions = useFunctions();

  const func = useMemo(() => {
    return httpsCallable<FunctionReq<K>, FunctionRes<K>>(
      functions,
      `${FUNCTIONS_PREFIX}-${functionName}`,
    );
  }, [functionName, functions]);

  return func;
};

export default useFirebaseAppFunction;
