import { defineMessages } from "react-intl";

const commonMessages = defineMessages({
  defaultError: {
    id: "common.messages.defaultError",
    defaultMessage: "Unknown error",
  },
  patientFormSuccessMessage: {
    id: "common.messages.patientFormSuccessMessage",
    defaultMessage: "Patient information form saved successfully",
  },
  patientPrivacyFormSuccessMessage: {
    id: "common.messages.patientPrivacyFormSuccessMessage",
    defaultMessage: "Patient privacy form saved successfully",
  },
  patientConsentFormSuccessMessage: {
    id: "common.messages.patientConsentFormSuccessMessage",
    defaultMessage: "Patient consent form saved successfully",
  },
  appointmentScreeningFormSuccessMessage: {
    id: "common.messages.appointmentScreeningFormSuccessMessage",
    defaultMessage: "Appointment screening form saved successfully",
  },
  errorEmailIsInUse: {
    id: "common.messages.errorEmailIsInUse",
    defaultMessage:
      "This email address cannot be used as it is already in use by another customer or patient",
  },
  appointmentNotFound: {
    id: "common.messages.appointmentNotFound",
    defaultMessage: "Appointment not found",
  },
  subtitleRequiredInformation: {
    id: "common.messages.subtitleRequiredInformation",
    defaultMessage: "Required Information",
  },
  descriptionRequiredInformation: {
    id: "common.messages.descriptionRequiredInformation",
    defaultMessage: "The state requires us to gather this information.",
  },
  race: {
    id: "common.messages.race",
    defaultMessage: "Race",
  },
  sex: {
    id: "common.messages.sex",
    defaultMessage: "Sex",
  },
  heightFt: {
    id: "common.messages.heightFt",
    defaultMessage: "Height (ft)",
  },
  heightIn: {
    id: "common.messages.heightIn",
    defaultMessage: "Height (in)",
  },
  weightLbs: {
    id: "common.messages.weightLbs",
    defaultMessage: "Weight (lbs)",
  },
});

export default commonMessages;
