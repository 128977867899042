import * as Yup from "yup";
import { EMAIL_REGEXP_STR, MAX_STRING_LEN } from "../../../common/constants";
import useTranslations from "./useTranslations";

const useValidationSchema = () => {
  const { labelEmail, errorEmailIsInvalid } = useTranslations();

  const email = Yup.string()
    .label(labelEmail)
    .trim()
    .max(MAX_STRING_LEN)
    .matches(new RegExp(`^${EMAIL_REGEXP_STR}$`), errorEmailIsInvalid)
    .required();

  return Yup.object().shape({ email });
};

export default useValidationSchema;
