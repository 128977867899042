import { defineMessages } from "react-intl";

const commonMessages = defineMessages({
  appointmentScreeningFormSuccessMessage: {
    id: "AppointmentForm.AppointmentScreeningForm.appointmentScreeningFormSuccessMessage",
    defaultMessage: "Appointment screening form saved successfully",
  },
});

export default commonMessages;
