import { defineMessages } from "react-intl";

const commonMessages = defineMessages({
  patientPrivacyFormSuccessMessage: {
    id: "common.messages.patientPrivacyFormSuccessMessage",
    defaultMessage: "Patient privacy form saved successfully",
  },
});

export default commonMessages;
