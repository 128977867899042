const getFirebaseConfig = () => {
  const { REACT_APP_RELEASE_CHANNEL } = process.env;

  switch (REACT_APP_RELEASE_CHANNEL) {
    case "prod": {
      return {
        apiKey: "AIzaSyCvBFWeGOCxUlWIEe86VJjuYj_9EbBAiVk",
        authDomain: "kudzu-usa-prod.firebaseapp.com",
        projectId: "kudzu-usa-prod",
        storageBucket: "kudzu-usa-prod.appspot.com",
        messagingSenderId: "296947342003",
        appId: "1:296947342003:web:5c55d872bea6cb26892ec6",
        measurementId: "G-TY7P62PT53",
      };
    }
    case "staging": {
      return {
        apiKey: "AIzaSyBkrNki60hY203muLEVashMeDudd_XlayQ",
        authDomain: "kudzu-usa-staging.firebaseapp.com",
        projectId: "kudzu-usa-staging",
        storageBucket: "kudzu-usa-staging.appspot.com",
        messagingSenderId: "487088368746",
        appId: "1:487088368746:web:416531a24250847164ae13",
        measurementId: "G-1ZYDMB8FR4",
      };
    }
    default: {
      return {
        apiKey: "AIzaSyDTzx0pRDodTxjA5OxEyezmmF_twcxmGyU",
        authDomain: "kudzu-usa-dev.firebaseapp.com",
        databaseURL: "https://kudzu-usa-dev-default-rtdb.firebaseio.com",
        projectId: "kudzu-usa-dev",
        storageBucket: "kudzu-usa-dev.appspot.com",
        messagingSenderId: "873827156163",
        appId: "1:873827156163:web:444701f98714f3f0356276",
        measurementId: "G-8Y1HC23W5L",
      };
    }
  }
};

const firebaseConfig = getFirebaseConfig();

export default firebaseConfig;
