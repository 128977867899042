import React, { FC, useState, useCallback, useEffect } from "react";
import { Auth as AWSAuth } from "aws-amplify";
import {
  useAuth as useFirebaseAuth,
  useUser as useFirebaseUser,
} from "reactfire";
import { signInWithCustomToken } from "firebase/auth";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import {
  getSupabaseAccessToken,
  initializeSupabaseAccessToken,
  setSupabaseAccessToken,
} from "../../../hooks/useSupabase";
import { AuthContextProps, AuthContextProviderProps, UserData } from "./types";
import { AuthContext } from "./authContext";
import useTranslations from "./useTranslations";
import useExpiredSignOut from "./useExpiredSignOut";
import parseJwt from "../../../common/parseJwt";

export const AuthContextProvider: FC<AuthContextProviderProps> = ({
  children,
}) => {
  const { defaultError } = useTranslations();

  const firebaseAuth = useFirebaseAuth();
  const firebaseUser = useFirebaseUser();
  const [isAppLoaded, setIsAppLoaded] = useState(false);
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  const sendCustomerPortalAuthCode = useFirebaseAppFunction(
    "sendCustomerPortalAuthCode",
  );
  const createCustomerPortalCustomToken = useFirebaseAppFunction(
    "createCustomerPortalCustomToken",
  );

  const [userData, setUserData] = useState<UserData | null>(null);

  const updateUserData = useCallback(
    async (params: { firebaseUserUid?: string }) => {
      const { firebaseUserUid } = params;

      try {
        const awsSession = await AWSAuth.currentSession();
        const awsSub = awsSession?.getIdToken()?.payload?.sub;
        const innerFirebaseUserUid = firebaseUserUid || firebaseUser?.data?.uid;

        if (!awsSub || !innerFirebaseUserUid) {
          throw new Error(defaultError);
        }

        const supabaseToken = getSupabaseAccessToken();

        const parsedToken = supabaseToken
          ? parseJwt(supabaseToken)
          : { exp: 0 };

        setUserData({
          awsSub,
          id: innerFirebaseUserUid,
          exp: parsedToken.exp,
        });
      } catch (error) {
        setUserData(null);
      }
    },
    [defaultError, firebaseUser?.data?.uid],
  );

  const signOut = useCallback<AuthContextProps["signOut"]>(async () => {
    setIsUserLoaded(false);
    await AWSAuth.signOut();
    await firebaseAuth.signOut();
    setSupabaseAccessToken("");

    setUserData(null);
    setIsUserLoaded(true);
  }, [firebaseAuth]);

  useEffect(() => {
    firebaseUser.firstValuePromise.then(() => setIsAppLoaded(true));
  }, [firebaseUser.firstValuePromise]);

  useEffect(() => {
    if (!isAppLoaded) {
      return;
    }

    const fetch = async () => {
      initializeSupabaseAccessToken();
      await updateUserData({});
      setIsUserLoaded(true);
    };

    fetch();
  }, [isAppLoaded, updateUserData]);

  const signIn = useCallback<AuthContextProps["signIn"]>(
    async (params) => {
      const { data } = await sendCustomerPortalAuthCode(params);
      return data;
    },
    [sendCustomerPortalAuthCode],
  );

  const answerCustomChallenge: AuthContextProps["answerCustomChallenge"] =
    async (params) => {
      const { data } = await createCustomerPortalCustomToken(params);

      if (data.status === "error") {
        return data;
      }

      const { cognitoUsername, firebaseToken, supabaseToken } = data.data;

      const cognitoUser = await AWSAuth.signIn(cognitoUsername);

      await AWSAuth.sendCustomChallengeAnswer(cognitoUser, params.code);

      const firebaseUserData = await signInWithCustomToken(
        firebaseAuth,
        firebaseToken,
      );

      setSupabaseAccessToken(supabaseToken);

      updateUserData({ firebaseUserUid: firebaseUserData?.user?.uid });

      return data;
    };

  useExpiredSignOut({ userData, signOut });

  return (
    <AuthContext.Provider
      value={{
        isUserLoaded,
        signIn,
        answerCustomChallenge,
        signOut,
        userData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
