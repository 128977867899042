import * as Yup from "yup";
import { CODE_LENGTH } from "./constants";
import useTranslations from "./useTranslations";

const useValidationSchema = () => {
  const { labelCode, errorCodeType } = useTranslations();

  const code = Yup.string()
    .label(labelCode)
    .trim()
    .matches(new RegExp(`^\\d{${CODE_LENGTH}}$`), errorCodeType)
    .required();

  return Yup.object().shape({ code });
};

export default useValidationSchema;
