import { addMilliseconds, addMinutes, isBefore } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import getTimezoneOffset from "date-fns-tz/getTimezoneOffset";

export const getTimezoneOffsetMillis = (timezone?: string | null) => {
  if (!timezone) return 0;

  const offset = getTimezoneOffset(timezone);

  if (!offset || Number.isNaN(offset)) return 0;

  return offset;
};

export const getNowUTC = () => {
  return addMinutes(new Date(), new Date().getTimezoneOffset());
};

export const UTCDateTimeToTimezone = (
  date: Date,
  timezoneOffsetMillis: number,
) => {
  return addMilliseconds(date, timezoneOffsetMillis);
};

export const isTimeZonePast = (params: {
  date: Date;
  timezone?: string | null;
}) => {
  const { date, timezone } = params;

  const timezoneOffsetMillis = getTimezoneOffsetMillis(timezone);

  const nowTimezone = UTCDateTimeToTimezone(getNowUTC(), timezoneOffsetMillis);

  const dateTimezone = UTCDateTimeToTimezone(date, timezoneOffsetMillis);

  const isPast = isBefore(dateTimezone, nowTimezone);

  return isPast;
};

export const createUTCDate = (dateString: string): Date => {
  const [year, month, day, hour, minute, second] = dateString
    .split(/[-T:]/)
    .map(Number);

  return new Date(Date.UTC(year, month - 1, day, hour, minute, second));
};

export const convertDbDateToCenterTZ = ({
  dateString,
  timezone,
}: {
  dateString: string;
  timezone: string;
}) => {
  return utcToZonedTime(createUTCDate(dateString), timezone);
};

export const getTimeWithEnglishTimeNotation = (date: Date) => {
  let hours: string | number = date.getHours();
  let minutes: string | number = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  hours %= 12;
  hours = !hours ? 12 : hours;

  minutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${minutes}${ampm}`;
};

export const getFormDate = (date: Date) => {
  const currentDate = new Date(date);
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();

  return `${month}/${day}/${year}`;
};
