import supabaseClient from "../../../common/supabaseClient";
import { Patient } from "./types";

const supabaseRequest = `
  id,
  first_name,
  last_name,
  city,
  postal_code,
  state,
  country,
  mobile_phone_number,
  email,
  patient_addresses (
      type,
      address
  ),
  patient_forms (
    id,
    type,
    expiration_at
  ),
  legalGuardian: customers (
  id
  )
`;

const getPatient = async (params: { patientId: string }) => {
  const { patientId } = params;

  const query = supabaseClient
    .from<Patient>("patients")
    .select(supabaseRequest)
    .eq("id", patientId)
    .single();

  const { data, error } = await query;

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export default getPatient;
