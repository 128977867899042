const checkIsCheckInApp = (subdomain: string) => {
  const { REACT_APP_CHECK_IN_APP_SUBDOMAIN } = process.env;

  if (!REACT_APP_CHECK_IN_APP_SUBDOMAIN) {
    throw new Error(
      "Please provide REACT_APP_CHECK_IN_APP_SUBDOMAIN in .env file",
    );
  }
  return subdomain === REACT_APP_CHECK_IN_APP_SUBDOMAIN;
};

export default checkIsCheckInApp;
