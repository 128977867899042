import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthLoginScreen from "../../Auth/AuthLoginScreen";
import GuestLayout from "../../Auth/GuestLayout";
import { UserData } from "../../Auth/AuthContextProvider/types";
import AuthenticatedRootCustomerPortalApp from "../../Auth/AuthenticatedRootCustomerPortalApp";
import AppointmentReportUnexpectedFindingsConfirmationScreen from "../../AppointmentReport/AppointmentReportUnexpectedFindingsConfirmationScreen";

const CustomerPortalAppRoot = ({ userData }: { userData: UserData | null }) => {
  if (!userData) {
    return (
      <Routes>
        <Route
          path="/"
          element={
            <GuestLayout>
              <AuthLoginScreen />
            </GuestLayout>
          }
        />
        <Route
          path="/confirmation/:confirmationToken"
          element={<AppointmentReportUnexpectedFindingsConfirmationScreen />}
        />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    );
  }

  return <AuthenticatedRootCustomerPortalApp />;
};

export default CustomerPortalAppRoot;
