import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "Patient.PatientConsentForm.title",
    defaultMessage: "Patient Information",
  },
  headerNameTitle: {
    id: "Patient.PatientConsentForm.headerNameTitle",
    defaultMessage: "Name",
  },
  headerAddressTitle: {
    id: "Patient.PatientConsentForm.headerAddressTitle",
    defaultMessage: "Address",
  },
  headerEmailTitle: {
    id: "Patient.PatientConsentForm.headerEmailTitle",
    defaultMessage: "Email address",
  },
  headerMobileNumberTitle: {
    id: "Patient.PatientConsentForm.headerMobileNumberTitle",
    defaultMessage: "Mobile number",
  },
  patientPregnancyConfirmationLabel: {
    id: "Patient.PatientConsentForm.patientPregnancyConfirmationLabel",
    defaultMessage: "Pregnancy Confirmation",
  },
  patientPregnancyConfirmationText: {
    id: "Patient.PatientConsentForm.patientPregnancyConfirmationText",
    defaultMessage: "Are you pregnant or feel there is any chance you may be?",
  },
  signatureCheckboxUnderstandText: {
    id: "Patient.PatientConsentForm.signatureCheckboxUnderstandText",
    defaultMessage:
      "By checking this I confirm I understand this acts as a digital signature",
  },
  dateTitle: {
    id: "Patient.PatientConsentForm.dateTitle",
    defaultMessage: "Date",
  },
  signatureCheckboxSuffix: {
    id: "Patient.PatientConsentForm.signatureCheckboxSuffix",
    defaultMessage: "explicit consent",
  },
  signatureTitle: {
    id: "Patient.PatientConsentForm.signatureCheckboxUnderstandText",
    defaultMessage: "Signature",
  },
  cancelButtonTitle: {
    id: "Patient.PatientPrivacyForm.cancelButtonTitle",
    defaultMessage: "Cancel",
  },
  saveButtonTitle: {
    id: "Patient.PatientPrivacyForm.saveButtonTitle",
    defaultMessage: "Save",
  },
  consentToImagingScanTitle: {
    id: "Patient.PatientPrivacyForm.consentToImagingScanTitle",
    defaultMessage: "Consent to Imaging Scan",
  },
  consentToImagingScanText: {
    id: "Patient.PatientPrivacyForm.consentToImagingScanText",
    defaultMessage:
      "You are requesting to voluntarily undergo a diagnostic imaging procedure known as magnetic resonance imaging (MRI) to obtain additional information that may aid in identifying, diagnosing and treating your medical condition. MRI does not use x-rays or radiation. Instead a magnetic field and radio waves are used to create an image of internal body structures. MRI is a painless scan that only requires that you lie quietly on a padded table that gently glides you into the magnet. While the scanner is performing your scan, you will hear some humming and thumping sounds. These are normal and should not worry you. Clients who experience severe pain associated with existing medical conditions should plan for this, timing their pain medications to achieve optimal relief during the time of the examination. A technologist will be available to communicate with you at any time during the scan.",
  },
  potentialRisksTitle: {
    id: "Patient.PatientPrivacyForm.potentialRisksTitle",
    defaultMessage: "Potential Risks",
  },
  potentialRisksFirstText: {
    id: "Patient.PatientPrivacyForm.potentialRisksTitle",
    defaultMessage:
      "MRI are machines approved by the Food and Drug Administration in the US for diagnostic and research purposes. MRI uses large magnet fields. There are no known or foreseeable risks or side effects associated with scanning procedures except for those people who have electrically, magnetically or mechanically activated implants, or metal in or on their bodies.",
  },
  potentialRisksSndText: {
    id: "Patient.PatientPrivacyForm.potentialRisksSndText",
    defaultMessage:
      "In order to make sure the MRI scan will be safe, we will ask you to fill out a screening form before starting the MRI screening scan. It is important that you tell the technician or the physician if you have any history of the items listed on the screening form. History of any items on the screening form may not automatically disqualify you, but instead are important to address with the technologist prior to your scan. After review of your screening form, we will discuss any additional risks and work to accommodate you as needed.",
  },
  patientConsentTitle: {
    id: "Patient.PatientPrivacyForm.imagoMRIPatientConsentTitle",
    defaultMessage: "Imago MRI (“Practice”) Patient Consent",
  },
  patientConsentFirstTextFirstPart: {
    id: "Patient.PatientPrivacyForm.imagoMRIPatientConsentFirstTextFirstPart",
    defaultMessage: "Consent to MRI Scan.",
  },
  patientConsentFirstTextSndPart: {
    id: "Patient.PatientPrivacyForm.imagoMRIPatientConsentFirstTextSndPart",
    defaultMessage:
      'I consent to the Magnetic Resonance Imaging ("MRI") scan. I understand that if I ordered this examination without a doctor\'s order it may not be medically necessary.',
  },
  patientConsentSndTextFirstPart: {
    id: "Patient.PatientPrivacyForm.imagoMRIPatientConsentSndTextFirstPart",
    defaultMessage: "Treating Providers.",
  },
  patientConsentSndTextSndPart: {
    id: "Patient.PatientPrivacyForm.imagoMRIPatientConsentSndTextSndPart",
    defaultMessage:
      "I understand that Imago MRI is not a treating provider. I understand that Imago MRI provides physicians that specialize in radiology to interpret the MRI screening scan. I acknowledge that I am voluntarily requesting this MRI scan and that the procedure may not be medically necessary based upon my symptoms.",
  },
  patientConsentThirdTextFirstPart: {
    id: "Patient.PatientPrivacyForm.imagoMRIPatientConsentThirdTextFirstPart",
    defaultMessage: "I further acknowledge that I am",
  },
  patientConsentThirdTextSndPart: {
    id: "Patient.PatientPrivacyForm.imagoMRIPatientConsentThirdTextSndPart",
    defaultMessage: "solely responsible",
  },
  patientConsentThirdTextThirdPart: {
    id: "Patient.PatientPrivacyForm.imagoMRIPatientConsentThirdTextThirdPart",
    defaultMessage:
      "for sharing the results of the MRI scan with my treating providers.",
  },
  patientConsentThirdTextFourthPart: {
    id: "Patient.PatientPrivacyForm.imagoMRIPatientConsentThirdTextFourthPart",
    defaultMessage:
      "I acknowledge and agree that Imago MRI has no responsibility or liability to me for any direct or indirect harm or damages, including without limitations costs or expenses related to my failure to obtain treatment from my treating providers or the disclosure of the images, medical records and interpretation reports.",
  },
  patientConsentFourthTextFirstPart: {
    id: "Patient.PatientPrivacyForm.imagoMRIPatientConsentFourthTextFirstPart",
    defaultMessage: "Personal Valuables.",
  },
  patientConsentFourthTextSndPart: {
    id: "Patient.PatientPrivacyForm.imagoMRIPatientConsentFourthTextSndPart",
    defaultMessage:
      "You are encouraged not to bring any personal valuables to the Practice location. If you have personal valuables, it is recommended for you to leave the items with a family member. Practice maintains a safe for the safekeeping of money and valuables; however, Practice shall not be liable for the loss of or damage to any money, jewelry, documents, furs, fur coats and fur garments, or other articles of unusual value and small size, and shall not be liable for the loss or damage to any other personal property, unless deposited with the Practice for safekeeping. The liability of the Practice for loss of any personal property that is deposited with the Practice for safekeeping is limited to the lesser of five hundred dollars ($500.00) or the real value of the item. The Practice is not responsible for the loss or damage of cell phones, glasses or dentures or personal valuables unless they are placed in the Practice safe in accordance with the terms as stated above.",
  },
  patientConsentFifthTextFirstPart: {
    id: "Patient.PatientPrivacyForm.imagoMRIPatientConsentFifthTextFirstPart",
    defaultMessage: "Financial Obligations.",
  },
  patientConsentFifthTextSndPart: {
    id: "Patient.PatientPrivacyForm.imagoMRIPatientConsentFifthTextSndPart",
    defaultMessage:
      "By signing below, I acknowledge that I understand the Practice",
  },
  patientConsentFifthTextThirdPart: {
    id: "Patient.PatientPrivacyForm.imagoMRIPatientConsentFifthTextThirdPart",
    defaultMessage: "does not accept assignment of benefits",
  },
  patientConsentFifthTextFourthPart: {
    id: "Patient.PatientPrivacyForm.imagoMRIPatientConsentFifthTextFourthPart",
    defaultMessage:
      "from any third party payer or insurance company. Practice only accepts direct payment from the patient. I hereby voluntarily acknowledge that I am",
  },
  patientConsentFifthTextFifthPart: {
    id: "Patient.PatientPrivacyForm.imagoMRIPatientConsentFifthTextFifthPart",
    defaultMessage: "personally and solely",
  },
  patientConsentFifthTextSixthPart: {
    id: "Patient.PatientPrivacyForm.imagoMRIPatientConsentFifthTextSixthPart",
    defaultMessage:
      "responsible for payment in full of the charges to the Practice.",
  },
  patientConsentSixthTextFirstPart: {
    id: "Patient.PatientPrivacyForm.imagoMRIPatientConsentSixthTextFirstPart",
    defaultMessage:
      "Consent to Email or Text Usage for Healthcare Communications.",
  },
  patientConsentSixthTextSndPart: {
    id: "Patient.PatientPrivacyForm.imagoMRIPatientConsentSixthTextSndPart",
    defaultMessage:
      "If at any time I provide Imago MRI (Practice) an email or text address at which I may be contacted, I consent to receiving instructions and other healthcare communications at the email or text address I have provided to the Practice or its designees including collection agencies may also contact me at any text number forwarded or transferred from the number that I provided. The other healthcare communications may include, but are not limited to communications related to your treating providers, to family or designated representatives regarding my treatment or condition, reminder messages to me regarding appointments for medical care and results of the MRI scan, or offers for products and services.",
  },
  pregnancyConsentTitle: {
    id: "Patient.PatientPrivacyForm.pregnancyConsentTitle",
    defaultMessage: "Pregnancy Consent",
  },
  pregnancyConsentFirstText: {
    id: "Patient.PatientPrivacyForm.pregnancyConsentFirstText",
    defaultMessage:
      "This consent is to inform you the Magnetic Resonance Imaging (MRI) non-contrast procedure you are having today is at a possible risk to your unborn child/fetus. By signing this you are consenting to understanding all of the information below and have asked all questions needed to understand the risks associated with the procedure.",
  },
  pregnancyConsentSndText: {
    id: "Patient.PatientPrivacyForm.pregnancyConsentSndText",
    defaultMessage:
      "To date, there are no reports of injury to children who underwent MR imaging before birth. While the number of patients scanned during pregnancy is small, with limited follow-up, in the past several years, numerous pregnant patients have undergone non-contrast MRI with no ill effects.",
  },
  pregnancyConsentThirdText: {
    id: "Patient.PatientPrivacyForm.pregnancyConsentThirdText",
    defaultMessage: "I",
  },
  pregnancyConsentFourthText: {
    id: "Patient.PatientPrivacyForm.pregnancyConsentFourthText",
    defaultMessage:
      "have read the above warning and understand the potential harmful effects to my unborn fetus. I consent to have this MRI procedure understanding the potential risks. I acknowledge that I have been given ample opportunity to ask questions and that all questions have been answered to my satisfaction. Furthermore, I fully understand that I may refuse to have this MRI procedure conducted on me. Also, I understand that I may stop this MRI procedure at anytime during its process. Furthermore, I fully agree that the risks described herein are risks that I am willing to accept. Also, I agree that I will hold harmless Imago MRI of Pelham, LLC, owners, and employees should I, or my fetus, experience any negative effects from this MRI procedure.",
  },
  acknowledgementTitle: {
    id: "Patient.PatientPrivacyForm.acknowledgementTitle",
    defaultMessage: "Acknowledgement",
  },
  acknowledgementText: {
    id: "Patient.PatientPrivacyForm.acknowledgementText",
    defaultMessage:
      "I have been given the opportunity to read and ask questions about the information contained in this form and I acknowledge that I either have no questions or that my questions have been answered to my satisfaction and that I have signed this document freely and without inducement other than the rendition of services by Imago.",
  },
});
