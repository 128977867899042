import React from "react";
import {
  FunctionsProvider,
  AuthProvider,
  useFirebaseApp,
  StorageProvider,
} from "reactfire";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectStorageEmulator, getStorage } from "firebase/storage";

type FirebaseSDKProvidersProps = {
  children?: React.ReactNode;
};

const FirebaseSDKProviders: React.FC<FirebaseSDKProvidersProps> = ({
  children,
}) => {
  const {
    REACT_APP_EMULATOR_FUNCTIONS_HOST,
    REACT_APP_EMULATOR_FUNCTIONS_PORT,
    REACT_APP_EMULATOR_AUTH_URL,
    REACT_APP_EMULATOR_STORAGE_HOST,
    REACT_APP_EMULATOR_STORAGE_PORT,
  } = process.env;

  const app = useFirebaseApp();
  const functions = getFunctions(app);
  const auth = getAuth(app);
  const storage = getStorage(app);

  const isFunctionsEmulatorSet =
    REACT_APP_EMULATOR_FUNCTIONS_HOST &&
    REACT_APP_EMULATOR_FUNCTIONS_PORT &&
    !!Number(REACT_APP_EMULATOR_FUNCTIONS_PORT);

  if (isFunctionsEmulatorSet) {
    connectFunctionsEmulator(
      functions,
      REACT_APP_EMULATOR_FUNCTIONS_HOST,
      Number(REACT_APP_EMULATOR_FUNCTIONS_PORT),
    );
  }

  const isAuthEmulatorSet = REACT_APP_EMULATOR_AUTH_URL;

  if (isAuthEmulatorSet) {
    connectAuthEmulator(auth, REACT_APP_EMULATOR_AUTH_URL);
  }

  const isStorageEmulator =
    REACT_APP_EMULATOR_STORAGE_HOST &&
    REACT_APP_EMULATOR_STORAGE_PORT &&
    !!Number(REACT_APP_EMULATOR_STORAGE_PORT);

  if (isStorageEmulator) {
    connectStorageEmulator(
      storage,
      REACT_APP_EMULATOR_STORAGE_HOST,
      Number(REACT_APP_EMULATOR_STORAGE_PORT),
    );
  }

  return (
    <FunctionsProvider sdk={functions}>
      <AuthProvider sdk={auth}>
        <StorageProvider sdk={storage}>{children}</StorageProvider>
      </AuthProvider>
    </FunctionsProvider>
  );
};

export default FirebaseSDKProviders;
