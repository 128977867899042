import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  return useMemo(() => {
    return {
      title: formatMessage(messages.title),
      dateTitle: formatMessage(messages.dateTitle),
      headerNameTitle: formatMessage(messages.headerNameTitle),
      headerAddressTitle: formatMessage(messages.headerAddressTitle),
      headerEmailTitle: formatMessage(messages.headerEmailTitle),
      headerMobileNumberTitle: formatMessage(messages.headerMobileNumberTitle),
      patientPregnancyConfirmationLabel: formatMessage(
        messages.patientPregnancyConfirmationLabel,
      ),
      patientPregnancyConfirmationText: formatMessage(
        messages.patientPregnancyConfirmationText,
      ),
      signatureCheckboxSuffix: formatMessage(messages.signatureCheckboxSuffix),
      signatureTitle: formatMessage(messages.signatureTitle),
      signatureCheckboxUnderstandText: formatMessage(
        messages.signatureCheckboxUnderstandText,
      ),
      saveButtonTitle: formatMessage(messages.saveButtonTitle),
      cancelButtonTitle: formatMessage(messages.cancelButtonTitle),
      consentToImagingScanTitle: formatMessage(
        messages.consentToImagingScanTitle,
      ),
      consentToImagingScanText: formatMessage(
        messages.consentToImagingScanText,
      ),
      potentialRisksTitle: formatMessage(messages.potentialRisksTitle),
      potentialRisksFirstText: formatMessage(messages.potentialRisksFirstText),
      potentialRisksSndText: formatMessage(messages.potentialRisksSndText),
      patientConsentTitle: formatMessage(messages.patientConsentTitle),
      patientConsentFirstTextFirstPart: formatMessage(
        messages.patientConsentFirstTextFirstPart,
      ),
      patientConsentFirstTextSndPart: formatMessage(
        messages.patientConsentFirstTextSndPart,
      ),
      patientConsentSndTextFirstPart: formatMessage(
        messages.patientConsentSndTextFirstPart,
      ),
      patientConsentSndTextSndPart: formatMessage(
        messages.patientConsentSndTextSndPart,
      ),
      patientConsentThirdTextFirstPart: formatMessage(
        messages.patientConsentThirdTextFirstPart,
      ),
      patientConsentThirdTextSndPart: formatMessage(
        messages.patientConsentThirdTextSndPart,
      ),
      patientConsentThirdTextThirdPart: formatMessage(
        messages.patientConsentThirdTextThirdPart,
      ),
      patientConsentThirdTextFourthPart: formatMessage(
        messages.patientConsentThirdTextFourthPart,
      ),
      patientConsentFourthTextFirstPart: formatMessage(
        messages.patientConsentFourthTextFirstPart,
      ),
      patientConsentFourthTextSndPart: formatMessage(
        messages.patientConsentFourthTextSndPart,
      ),
      patientConsentFifthTextFirstPart: formatMessage(
        messages.patientConsentFifthTextFirstPart,
      ),
      patientConsentFifthTextSndPart: formatMessage(
        messages.patientConsentFifthTextSndPart,
      ),
      patientConsentFifthTextThirdPart: formatMessage(
        messages.patientConsentFifthTextThirdPart,
      ),
      patientConsentFifthTextFourthPart: formatMessage(
        messages.patientConsentFifthTextFourthPart,
      ),
      patientConsentFifthTextFifthPart: formatMessage(
        messages.patientConsentFifthTextFifthPart,
      ),
      patientConsentFifthTextSixthPart: formatMessage(
        messages.patientConsentFifthTextSixthPart,
      ),
      patientConsentSixthTextFirstPart: formatMessage(
        messages.patientConsentSixthTextFirstPart,
      ),
      patientConsentSixthTextSndPart: formatMessage(
        messages.patientConsentSixthTextSndPart,
      ),
      pregnancyConsentTitle: formatMessage(messages.pregnancyConsentTitle),
      pregnancyConsentFirstText: formatMessage(
        messages.pregnancyConsentFirstText,
      ),
      pregnancyConsentSndText: formatMessage(messages.pregnancyConsentSndText),
      pregnancyConsentThirdText: formatMessage(
        messages.pregnancyConsentThirdText,
      ),
      pregnancyConsentFourthText: formatMessage(
        messages.pregnancyConsentFourthText,
      ),
      acknowledgementTitle: formatMessage(messages.acknowledgementTitle),
      acknowledgementText: formatMessage(messages.acknowledgementText),
    };
  }, [formatMessage]);
};

export default useTranslations;
