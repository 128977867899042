export const DEFAULT_REGION = "us-central1";
export const FUNCTIONS_PREFIX = "customerPortal";

export const EMAIL_REGEXP_STR =
  "([a-zA-Z0-9+._-]+@[a-zA-Z0-9_-]+\\.[a-zA-Z0-9_-]{2,})";

export const PHONE_NUMBER_REGEXP_STR = /[0-9]+$/;

export const MAX_STRING_LEN = 100;

export const SUPABASE_TOKEN = "SUPABASE_TOKEN";

export const MAX_PHONE_NUMBER_LEN = 10;
export const MIN_PHONE_NUMBER_LEN = 10;
export const infoEmail = "info@imagomri.com";
export const legalEmail = "legal@imagomri.com";
export const hhsHipaaCompilantsLink =
  "www.hhs.gov/ocr/privacy/hipaa/complaints/";
export const hhsHipaaUnderstandingLink =
  "www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html";

export const DEFAULT_COUNTRY_CODE = "US";

export const delayBeforeRedirectToMainViewSeconds = 30;
