import { useField } from "formik";
import { useCallback } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { FilesFieldProps } from "../../Unknown/FilesField";
import { acceptTypes, maxFilesCount, maxFileSizeBytes } from "./constants";
import getFormNames from "./getFormNames";
import { AppointmentFile } from "../CheckInAppPatientProvideInformationFormContainer/types";

const useFilesFieldComponent = ({
  onFilesError,
}: {
  onFilesError: (errors: string[]) => void;
}): FilesFieldProps => {
  const formNames = getFormNames();

  const [field, , helpers] = useField<File[]>(formNames.doctorsOrderFiles);
  const [existedFiles] = useField<AppointmentFile[]>(
    formNames.existedDoctorsOrderFiles,
  );

  const onDropAccepted = useCallback(
    (newFiles: File[]) => {
      const filesCountAccepted = Math.max(
        0,
        maxFilesCount - field.value.length - existedFiles.value.length,
      );
      const filteredFiles = newFiles.slice(0, filesCountAccepted);

      helpers.setValue([...field.value, ...filteredFiles]);
    },
    [field.value, existedFiles.value.length, helpers],
  );

  const onDropRejected = (rejections: FileRejection[]) => {
    const errorMessages = rejections
      .map(({ errors, file }) => {
        return errors.map((error) => `${file.name}: ${error.message}`);
      })
      .flat();

    onFilesError(errorMessages);
  };

  const { getInputProps, getRootProps } = useDropzone({
    accept: acceptTypes,
    maxFiles: maxFilesCount,
    maxSize: maxFileSizeBytes,
    onDrop: () => onFilesError([]),
    onDropRejected,
    onDropAccepted,
  });

  return {
    dragDropFileFieldBaseProps: {
      rootProps: getRootProps(),
      inputProps: getInputProps(),
    },
    files: field.value,
    existedFiles: existedFiles.value,
  };
};

export default useFilesFieldComponent;
