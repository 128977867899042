import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "Patient.AppointmentScreeningForm.title",
    defaultMessage: "Patient Information",
  },
  headerNameTitle: {
    id: "Patient.AppointmentScreeningForm.headerNameTitle",
    defaultMessage: "Name",
  },
  headerDateOfBirthTitle: {
    id: "Patient.AppointmentScreeningForm.headerDateOfBirthTitle",
    defaultMessage: "Date of birth",
  },
  headerPatientIdTitle: {
    id: "Patient.AppointmentScreeningForm.headerPatientIdTitle",
    defaultMessage: "Patient ID",
  },
  dateTitle: {
    id: "Patient.AppointmentScreeningForm.dateTitle",
    defaultMessage: "Date",
  },
  patientSignatureTitle: {
    id: "Patient.AppointmentScreeningForm.patientSignatureTitle",
    defaultMessage: "Patient Signature",
  },
  patientUnderstandingText: {
    id: "Patient.AppointmentScreeningForm.patientUnderstandingText",
    defaultMessage:
      "I verify that I do not have any of the metallic objects listed above within or on my body. If I have some I cannot remove I will inform the technologist and discuss and accept the risks of an MRI should I proceed with the exam. By checking this I confirm I understand this acts as a digital signature.",
  },
  cancelButtonTitle: {
    id: "Patient.AppointmentScreeningForm.cancelButtonTitle",
    defaultMessage: "Cancel",
  },
  saveButtonTitle: {
    id: "Patient.AppointmentScreeningForm.saveButtonTitle",
    defaultMessage: "Save",
  },
  metalicElectronicItemsTitle: {
    id: "Patient.AppointmentScreeningForm.metalicElectronicItemsTitle",
    defaultMessage: "Metallic/Electronic Items",
  },
  metalicElectronicItemsFirstParagraph: {
    id: "Patient.AppointmentScreeningForm.metalicElectronicItemsFirstParagraph",
    defaultMessage:
      "All metallic and electronic items must be removed in order for you to receive an MRI scan.",
  },
  metalicElectronicItemsSndParagraph: {
    id: "Patient.AppointmentScreeningForm.metalicElectronicItemsSndParagraph",
    defaultMessage:
      "Having one of the items below does not automatically disqualify you from receiving a scan. Instead, it provides our technologist with important information that will allow them to discuss additional risks and accommodate you as needed.",
  },
  metalicElectronicItemsThirdParagraph: {
    id: "Patient.AppointmentScreeningForm.metalicElectronicItemsThirdParagraph",
    defaultMessage:
      "If you are unsure if any of the following apply to you, please check the item and indicate that you wish to speak with an MRI Technologist or give us a call before you come in for your scan. We will be happy to answer any questions.",
  },
  metalicElectronicItemsFourthParagraph: {
    id: "Patient.AppointmentScreeningForm.metalicElectronicItemsFourthParagraph",
    defaultMessage: "Please select if you have any of the following:",
  },
  cardiacPacemakerText: {
    id: "Patient.AppointmentScreeningForm.cardiacPacemakerText",
    defaultMessage: "Cardiac Pacemaker",
  },
  implantedCardiacDefibrillatorText: {
    id: "Patient.AppointmentScreeningForm.implantedCardiacDefibrillatorText",
    defaultMessage:
      "Implanted Cardiac Defibrillator, Cardiac Electrodes, Pacing Wires, Internal Electrodes",
  },
  aneurysmClipText: {
    id: "Patient.AppointmentScreeningForm.aneurysmClipText",
    defaultMessage: "Aneurysm Clip(s)",
  },
  cochlearText: {
    id: "Patient.AppointmentScreeningForm.cochlearText",
    defaultMessage: "Cochlear, Otologic, or other Ear Implant",
  },
  hearingAidText: {
    id: "Patient.AppointmentScreeningForm.hearingAidText",
    defaultMessage: "Hearing Aid",
  },
  softTissueExpanderText: {
    id: "Patient.AppointmentScreeningForm.softTissueExpanderText",
    defaultMessage: "Soft Tissue Expander (e.g. breast)",
  },
  swanGanzCatheterText: {
    id: "Patient.AppointmentScreeningForm.swanGanzCatheterText",
    defaultMessage: "Swan-Ganz Catheter",
  },
  workedWithMetalText: {
    id: "Patient.AppointmentScreeningForm.workedWithMetalText",
    defaultMessage: "Worked with Metal OR Metal Fragments in Eyes",
  },
  eyeProsthesisText: {
    id: "Patient.AppointmentScreeningForm.eyeProsthesisText",
    defaultMessage:
      "Eye Prosthesis or device such as eyelid spring, wire, or implant",
  },
  metallicFragmentOrForeignBodyText: {
    id: "Patient.AppointmentScreeningForm.metallicFragmentOrForeignBodyText",
    defaultMessage:
      "Metallic Fragment or Foreign Body such as, but not limited to, bullets or shrapnel",
  },
  heartValveProsthesisText: {
    id: "Patient.AppointmentScreeningForm.heartValveProsthesisText",
    defaultMessage: "Heart Valve Prosthesis",
  },
  electronicImplantText: {
    id: "Patient.AppointmentScreeningForm.electronicImplantText",
    defaultMessage: "Electronic Implant or Device",
  },
  neurostimulationText: {
    id: "Patient.AppointmentScreeningForm.neurostimulationText",
    defaultMessage: "Neurostimulation or Spinal Cord Stimulator",
  },
  boneGrowthStimulatorText: {
    id: "Patient.AppointmentScreeningForm.boneGrowthStimulatorText",
    defaultMessage: "Bone Growth Stimulator",
  },
  magneticallyActivatedImplantText: {
    id: "Patient.AppointmentScreeningForm.magneticallyActivatedImplantText",
    defaultMessage: "Magnetically Activated Implant or Device",
  },
  magneticStentCoilFilterText: {
    id: "Patient.AppointmentScreeningForm.magneticStentCoilFilterText",
    defaultMessage: "Magnetic Stent, Coil, or Filter",
  },
  wireMeshImplantText: {
    id: "Patient.AppointmentScreeningForm.wireMeshImplantText",
    defaultMessage: "Wire Mesh Implant",
  },
  surgicalStaplesClipsMetallicSuturesText: {
    id: "Patient.AppointmentScreeningForm.surgicalStaplesClipsMetallicSuturesText",
    defaultMessage: "Surgical Staples, Clips, or Metallic Sutures",
  },
  anyOtherProsthesisText: {
    id: "Patient.AppointmentScreeningForm.anyOtherProsthesisText",
    defaultMessage: "Any Other Prosthesis (Limb, Penile Implant, Eye)",
  },
  jointReplacementText: {
    id: "Patient.AppointmentScreeningForm.jointReplacementText",
    defaultMessage: "Joint Replacement",
  },
  boneJointText: {
    id: "Patient.AppointmentScreeningForm.boneJointText",
    defaultMessage: "Bone/Joint Pin or Screw, Nail, Wire, or Plate",
  },
  iudDiaphragmPessaryText: {
    id: "Patient.AppointmentScreeningForm.iudDiaphragmPessaryText",
    defaultMessage: "IUD, Diaphragm, or Pessary",
  },
  medicinePatchesText: {
    id: "Patient.AppointmentScreeningForm.medicinePatchesText",
    defaultMessage: "Medicine Patches",
  },
  shuntText: {
    id: "Patient.AppointmentScreeningForm.shuntText",
    defaultMessage: "SHUNT(S) - spinal/intraventricular/peritoneal",
  },
  infusionPumpText: {
    id: "Patient.AppointmentScreeningForm.infusionPumpText",
    defaultMessage:
      "Infusion Pump such as Insulin Pump or Other Drug Infusion Device",
  },
  dentalImplantsText: {
    id: "Patient.AppointmentScreeningForm.dentalImplantsText",
    defaultMessage: "Dentures, Magnetic Dental Implants, or Hearing Aids",
  },
  bodyPiercingJewelryText: {
    id: "Patient.AppointmentScreeningForm.bodyPiercingJewelryText",
    defaultMessage: "Body Piercing Jewelry",
  },
  tattoosOrPermanentMakeupText: {
    id: "Patient.AppointmentScreeningForm.tattoosOrPermanentMakeupText",
    defaultMessage: "Tattoos or Permanent Makeup",
  },
  anyOtherDeviceText: {
    id: "Patient.AppointmentScreeningForm.anyOtherDeviceText",
    defaultMessage: "Any Other Device or Object in Body",
  },
  metalicElectronicItemsFifthParagraph: {
    id: "Patient.AppointmentScreeningForm.metalicElectronicItemsFifthParagraph",
    defaultMessage:
      "If you checked any of the above, please discuss the details with your MRI Technologist.",
  },
  doNotHaveAnyItemTitle: {
    id: "Patient.AppointmentScreeningForm.doNotHaveAnyItemTitle",
    defaultMessage: "I do not have any of the above.",
  },
  itemRemovalTitle: {
    id: "Patient.AppointmentScreeningForm.itemRemovalTitle",
    defaultMessage: "Item Removal",
  },
  implantCardTitle: {
    id: "Patient.AppointmentScreeningForm.implantCardTitle",
    defaultMessage: "Implant Card",
  },
  implantCardDescription: {
    id: "Patient.AppointmentScreeningForm.implantCardTitle",
    defaultMessage:
      "If you have an implant card please upload an image of the front and back on the {patientInformationForm} during check-in or email it to info@imagomri.com.",
  },
  patientInformationFormText: {
    id: "Patient.AppointmentScreeningForm.patientInformationFormText",
    defaultMessage: "“Patient Information” form",
  },
  itemRemovalDescription: {
    id: "Patient.AppointmentScreeningForm.itemRemovalDescription",
    defaultMessage:
      "We will provide a locker so any items you remove may be stored and locked safely during your scan. You may bring the key in the scan room with you. Before entering the MRI machine please:",
  },
  itemRemovalFirstRecomendationTitle: {
    id: "Patient.AppointmentScreeningForm.itemRemovalFirstRecomendationTitle",
    defaultMessage: "Remove all jewelry, body piercings, and hair accessories.",
  },
  itemRemovalSndRecomendationTitle: {
    id: "Patient.AppointmentScreeningForm.itemRemovalSndRecomendationTitle",
    defaultMessage:
      "Remove dentures, false teeth, partial dental plates, retainers.",
  },
  itemRemovalThirdRecomendationTitle: {
    id: "Patient.AppointmentScreeningForm.itemRemovalThirdRecomendationTitle",
    defaultMessage: "Remove hearing aids and eyeglasses.",
  },
  itemRemovalFourthRecomendationTitle: {
    id: "Patient.AppointmentScreeningForm.itemRemovalFourthRecomendationTitle",
    defaultMessage:
      "Lock your clothes and valuables in the locker provided and remove the key.",
  },
  itemRemovalFifthRecomendationTitle: {
    id: "Patient.AppointmentScreeningForm.itemRemovalFifthRecomendationTitle",
    defaultMessage: "Use the restroom before your MRI exam.",
  },
});
