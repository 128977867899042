import { useMemo } from "react";
import {
  ACCESS_CODE_LIFETIME_MINUTES,
  RESEND_CODE_LIMIT_MINUTES,
  ENTER_CODE_LIMIT_MINUTES,
} from "@Shape-Digital/kudzu-data/lib/constants/customerPortal";
import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";
import messages from "./messages";
import { CODE_LENGTH } from "./constants";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      defaultError: formatMessage(commonMessages.defaultError),
      labelCode: formatMessage(messages.labelCode),
      placeholderCode: formatMessage(messages.placeholderCode),
      helperCodeValidFor: formatMessage(messages.helperCodeValidFor, {
        minutes: ACCESS_CODE_LIFETIME_MINUTES,
      }),
      errorCodeInvalid: formatMessage(messages.errorCodeInvalid),
      errorCodeType: formatMessage(messages.errorCodeType, {
        length: CODE_LENGTH,
      }),
      errorResendAttemptsLimit: formatMessage(
        messages.errorResendAttemptsLimit,
        {
          minutes: RESEND_CODE_LIMIT_MINUTES,
        },
      ),
      errorEnterAttemptsLimit: formatMessage(messages.errorEnterAttemptsLimit, {
        minutes: ENTER_CODE_LIMIT_MINUTES,
      }),
    };
  }, [formatMessage]);

  return translations;
};

export default useTranslations;
