import { defineMessages } from "react-intl";

export default defineMessages({
  labelCode: {
    id: "Auth.AuthConfirmUserFormContainer.labelCode",
    defaultMessage: "Confirmation code",
  },
  placeholderCode: {
    id: "Auth.AuthConfirmUserFormContainer.placeholderCode",
    defaultMessage: "Enter confirmation code",
  },
  helperCodeValidFor: {
    id: "Auth.AuthConfirmUserFormContainer.helperCodeValidFor",
    defaultMessage: "The confirmation code is valid for {minutes} minutes",
  },
  errorCodeInvalid: {
    id: "Auth.AuthConfirmUserFormContainer.errorCodeInvalid",
    defaultMessage: "Confirmation code is invalid. Please try again",
  },
  errorCodeType: {
    id: "Auth.AuthConfirmUserFormContainer.errorCodeType",
    defaultMessage: "Confirmation code must be exactly {length} digits",
  },
  errorResendAttemptsLimit: {
    id: "Auth.AuthConfirmUserFormContainer.errorResendAttemptsLimit",
    defaultMessage:
      "Resend code limit exceeded. Please try again in {minutes} minutes",
  },
  errorEnterAttemptsLimit: {
    id: "Auth.AuthConfirmUserFormContainer.errorEnterAttemptsLimit",
    defaultMessage:
      "Enter code limit exceeded. Please try again in {minutes} minutes",
  },
});
