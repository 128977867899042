import { defineMessages, useIntl } from "react-intl";

export const raceTranslations = defineMessages({
  white: {
    id: "common.messages.white",
    defaultMessage: "White",
  },
  africanAmerican: {
    id: "common.messages.africanAmerican",
    defaultMessage: "African American",
  },
  asianPacificIslander: {
    id: "common.messages.asianPacificIslander",
    defaultMessage: "Asian/Pacific Islander",
  },
  americanIndian: {
    id: "common.messages.americanIndian",
    defaultMessage: "American Indian",
  },
  hispanic: {
    id: "common.messages.hispanic",
    defaultMessage: "Hispanic",
  },
  other: {
    id: "common.messages.other",
    defaultMessage: "Other",
  },
  preferNotToAnswer: {
    id: "common.messages.preferNotToAnswer",
    defaultMessage: "Prefer not to answer",
  },
});

export type Race =
  | "white"
  | "african_american"
  | "asian_pacific_islander"
  | "american_indian"
  | "hispanic"
  | "other"
  | "prefer_not_to_answer";

export const raceTranslationKeys: Record<Race, keyof typeof raceTranslations> =
  {
    white: "white",
    african_american: "africanAmerican",
    asian_pacific_islander: "asianPacificIslander",
    american_indian: "americanIndian",
    hispanic: "hispanic",
    other: "other",
    prefer_not_to_answer: "preferNotToAnswer",
  };

const useRaceOptions = () => {
  const { formatMessage } = useIntl();

  const options: { value: Race; label: string }[] = [
    {
      value: "white",
      label: formatMessage(raceTranslations.white),
    },
    {
      value: "african_american",
      label: formatMessage(raceTranslations.africanAmerican),
    },
    {
      value: "asian_pacific_islander",
      label: formatMessage(raceTranslations.asianPacificIslander),
    },
    {
      value: "american_indian",
      label: formatMessage(raceTranslations.americanIndian),
    },
    {
      value: "hispanic",
      label: formatMessage(raceTranslations.hispanic),
    },
    {
      value: "other",
      label: formatMessage(raceTranslations.other),
    },
    {
      value: "prefer_not_to_answer",
      label: formatMessage(raceTranslations.preferNotToAnswer),
    },
  ];

  return options;
};

export default useRaceOptions;
