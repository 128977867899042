import * as Yup from "yup";
import {
  EMAIL_REGEXP_STR,
  MAX_STRING_LEN,
  PHONE_NUMBER_REGEXP_STR,
} from "../../../common/constants";
import useValidationSchemaTranslations from "./useValidationSchemaTranslations";

const optionalString = Yup.string().trim().max(MAX_STRING_LEN);
const requiredString = optionalString.required();

const useValidationSchema = () => {
  const translations = useValidationSchemaTranslations();

  const shape = {
    contactEmail: requiredString
      .matches(
        new RegExp(`^${EMAIL_REGEXP_STR}$`),
        translations.errorEmailIsInvalid,
      )
      .label(translations.labelContactEmail),
    contactPhone: requiredString
      .trim()
      .matches(PHONE_NUMBER_REGEXP_STR)
      .required()
      .label(translations.labelContactPhone),
    addressPrimary: requiredString.label(translations.labelAddressPrimary),
    addressSecondary: optionalString.label(translations.labelAddressSecondary),
    addressCity: requiredString.label(translations.labelAddressCity),
    addressState: requiredString.label(translations.labelAddressState),
    addressPostalCode: requiredString.label(
      translations.labelAddressPostalCode,
    ),
    addressCountry: requiredString.label(translations.labelAddressCountry),
  };

  const schema = Yup.object().shape(shape);

  return schema;
};

export default useValidationSchema;
