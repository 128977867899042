import React, { FC, ReactNode } from "react";
import { useAuthContext } from "../AuthContextProvider/useAuthContext";
import Box from "../../Unknown/Box";
import Container from "../../Unknown/Container";
import Navbar from "../../Unknown/Navbar";
import useStyles from "./useStyles";
import { FormContextProvider } from "../../Unknown/FormContext";

type AuthenticatedLayoutProps = {
  children: ReactNode;
};

const AuthenticatedLayout: FC<AuthenticatedLayoutProps> = ({ children }) => {
  const { layoutRoot, containerRoot } = useStyles();
  const { signOut } = useAuthContext();

  return (
    <Box className={layoutRoot}>
      <Navbar onLogOutClick={signOut} />
      <Container className={containerRoot}>
        <FormContextProvider>{children}</FormContextProvider>
      </Container>
    </Box>
  );
};

export default AuthenticatedLayout;
