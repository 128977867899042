import React, { FC } from "react";
import AuthLoginFormContainer from "../AuthLoginFormContainer";

const AuthLoginScreen: FC = () => {
  return (
    <>
      <AuthLoginFormContainer />
    </>
  );
};

export default AuthLoginScreen;
