import { Formik } from "formik";
import React, { FC } from "react";
import Box from "../../Unknown/Box";
import Paper from "../../Unknown/Paper";
import CheckInAppPatientProvideInformationForm from "../CheckInAppPatientProvideInformationForm";
import useFormikComponent from "./useFormikComponent";
import usePatientFormComponent from "./usePatientFormComponent";
import { Appointment } from "./types";

export type PatientProvideInformationFormContainerProps = {
  appointment: Appointment | null;
};

const PatientProvideInformationFormContainer: FC<
  PatientProvideInformationFormContainerProps
> = ({ appointment }) => {
  const formikProps = useFormikComponent({ appointment });

  const PatientProvideInformationFormProps = usePatientFormComponent();

  return (
    <Formik {...formikProps}>
      <Paper>
        <Box p={6}>
          <CheckInAppPatientProvideInformationForm
            {...PatientProvideInformationFormProps}
          />
        </Box>
      </Paper>
    </Formik>
  );
};

export default PatientProvideInformationFormContainer;
