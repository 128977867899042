import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { PatientProvideInformationFormProps } from "../CheckInAppPatientProvideInformationForm";
import usePatientProvideInformationFormTranslations from "./usePatientFormTranslations";

const usePatientProvideInformationFormComponent =
  (): PatientProvideInformationFormProps => {
    const navigate = useNavigate();

    const formTranslations = usePatientProvideInformationFormTranslations();

    const handleCancel = useCallback<
      PatientProvideInformationFormProps["handleCancel"]
    >(() => {
      navigate("/");
    }, [navigate]);

    return {
      formTranslations,
      handleCancel,
    };
  };

export default usePatientProvideInformationFormComponent;
