import React from "react";
import { AppointmentReport } from "../AppointmentListContainer/types";
import Grid from "../../Unknown/Grid";
import AppointmentPreviewReportCard from "../AppointmentPreviewReportCard";

interface AppointmentPreviewReportsListProps {
  appointmentReports: AppointmentReport[];
  appointmentId: string;
  patientId: string;
}

const AppointmentPreviewReportsList = ({
  appointmentReports,
  appointmentId,
  patientId,
}: AppointmentPreviewReportsListProps) => {
  return (
    <Grid container gap={4}>
      {appointmentReports.map(({ appointment_offering, id, filename }) => {
        return (
          <AppointmentPreviewReportCard
            key={id}
            appointmentReportId={id}
            filename={filename}
            patientId={patientId}
            appointmentId={appointmentId}
            title={appointment_offering.center_offering.name}
          />
        );
      })}
    </Grid>
  );
};

export default AppointmentPreviewReportsList;
