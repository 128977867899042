import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import PatientChangeInformationFormContainer from "../PatientChangeInformationFormContainer";
import useAppointment from "../PatientChangeInformationFormContainer/useAppointment";
import PatientProvideInformationFormContainer from "../CheckInAppPatientProvideInformationFormContainer";
import Box from "../../Unknown/Box";
import CircularProgress from "../../Unknown/CircularProgress";
import messages from "../../../common/messages";

const PatientChangeInformationScreen: FC = () => {
  const { appointmentId } = useParams();
  const { appointment, isLoading } = useAppointment({ appointmentId });

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        pt={10}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!appointment) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        pt={10}
      >
        <FormattedMessage {...messages.appointmentNotFound} />
      </Box>
    );
  }

  return (
    <Box maxWidth={800} width="100%">
      {[
        "checked_in",
        "studies_unread",
        "studies_read",
        "reports_sent",
        "reports_error",
      ].includes(appointment?.status) ? (
        <PatientChangeInformationFormContainer appointment={appointment} />
      ) : (
        <PatientProvideInformationFormContainer appointment={appointment} />
      )}
    </Box>
  );
};

export default PatientChangeInformationScreen;
