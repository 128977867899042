import formatEmail from "../../../common/formatEmail";
import { AuthCheckUserFormProps } from "../AuthCheckUserForm";
import { FormikConfigResult } from "./types";
import useTranslations from "./useTranslations";

const useAuthCheckUserForm = (params: {
  formik: FormikConfigResult;
  errorMessage: string | null;
}): AuthCheckUserFormProps => {
  const { formik, errorMessage } = params;

  const { labelEmail } = useTranslations();

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = formik;

  const emailFieldProps: AuthCheckUserFormProps["emailFieldProps"] = {
    value: values.email,
    onChange: ({ target }) => setFieldValue("email", formatEmail(target.value)),
    onBlur: handleBlur,
    name: "email",
    type: "email",
    disabled: isSubmitting,
    required: true,
    label: labelEmail,
    error: touched.email && Boolean(errors.email),
    helperText: touched.email && errors.email,
  };

  const alert: AuthCheckUserFormProps["alert"] = errorMessage
    ? {
        props: { severity: "error" },
        children: errorMessage,
      }
    : undefined;

  return {
    emailFieldProps,
    handleSubmit,
    alert,
    isSubmitDisabled: isSubmitting,
    isSubmitLoading: isSubmitting,
  };
};

export default useAuthCheckUserForm;
