import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import supabaseClient from "./supabaseClient";

const checkIsAppointmentCheckedIn = async (appointmentId: string) => {
  const { data } = await supabaseClient
    .from<Pick<DatabaseRow<"appointments">, "id" | "status">>("appointments")
    .select("id, status")
    .eq("id", appointmentId)
    .single();

  return data && data.status === "checked_in";
};

export default checkIsAppointmentCheckedIn;
