import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import isFuture from "date-fns/isFuture";
import { UserData } from "./types";

const useExpiredSignOut = (params: {
  userData: UserData | null;
  signOut: () => Promise<void> | void;
}) => {
  const { userData, signOut } = params;

  const location = useLocation();

  useEffect(() => {
    if (!userData) return;

    if (isFuture(userData.exp)) return;

    signOut();
  }, [location, signOut, userData]);
};

export default useExpiredSignOut;
