import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { FirebaseAppProvider } from "reactfire";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import { ThemeProvider as GlobalThemeProvider } from "@mui/system";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { Provider } from "react-supabase";
import { Amplify } from "@aws-amplify/core";
import theme from "../../../common/theme";
import firebaseApp from "../../../common/firebaseApp";
import FirebaseSDKProviders from "../FirebaseSDKProviders";
import GlobalStyles from "../GlobalStyles";
import IntlProvider from "../IntlProvider";
import Root from "../Root";
import amplifyConfig from "../../../common/amplifyConfig";
import supabaseClient from "../../../common/supabaseClient";
import { UIContextProvider } from "../UIContext";
import { AuthContextProvider } from "../../Auth/AuthContextProvider";
import Head from "../Head";

Amplify.configure(amplifyConfig);

const App: React.FC = () => {
  return (
    <FirebaseAppProvider firebaseApp={firebaseApp}>
      <FirebaseSDKProviders>
        <Provider value={supabaseClient}>
          <GlobalThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <StyledEngineProvider injectFirst>
                <Router basename={process.env.PUBLIC_URL || "/"}>
                  <IntlProvider>
                    <ThemeProvider theme={theme}>
                      <Head />
                      <GlobalStyles />
                      <CssBaseline />
                      <UIContextProvider>
                        <AuthContextProvider>
                          <Root />
                        </AuthContextProvider>
                      </UIContextProvider>
                    </ThemeProvider>
                  </IntlProvider>
                </Router>
              </StyledEngineProvider>
            </ThemeProvider>
          </GlobalThemeProvider>
        </Provider>
      </FirebaseSDKProviders>
    </FirebaseAppProvider>
  );
};

export default App;
