import React, { FC } from "react";
import { useEffectOnce } from "react-use";
import { useNavigate } from "react-router-dom";
import CheckInAppPatientPrivacyFormContainer from "../CheckInAppPatientPrivacyFormContainer";
import { useFormContext } from "../../Unknown/FormContext";
import Box from "../../Unknown/Box";
import CircularProgress from "../../Unknown/CircularProgress";

const PatientPrivacyFormScreen: FC = () => {
  const { formPatientId, formAppointmentId } = useFormContext();
  const navigate = useNavigate();

  useEffectOnce(() => {
    if (!formPatientId || !formAppointmentId) {
      navigate("/");
    }
  });

  if (!formPatientId || !formAppointmentId) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        pt={10}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box maxWidth={800}>
      <CheckInAppPatientPrivacyFormContainer
        patientId={formPatientId}
        appointmentId={formAppointmentId}
      />
    </Box>
  );
};

export default PatientPrivacyFormScreen;
