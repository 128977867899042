import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { PatientChangeInformationFormProps } from "../PatientChangeInformationForm";
import usePatientChangeInformationFormTranslations from "./usePatientFormTranslations";

const usePatientChangeInformationFormComponent =
  (): PatientChangeInformationFormProps => {
    const navigate = useNavigate();

    const formTranslations = usePatientChangeInformationFormTranslations();

    const handleCancel = useCallback<
      PatientChangeInformationFormProps["handleCancel"]
    >(() => {
      navigate("/");
    }, [navigate]);

    return {
      formTranslations,
      handleCancel,
    };
  };

export default usePatientChangeInformationFormComponent;
