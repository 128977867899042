import React, { FC } from "react";
import { Typography } from "@mui/material";
import Alert from "../../Unknown/Alert";
import AlertTitle from "../../Unknown/AlertTitle";
import Paper from "../../Unknown/Paper";
import useStyles from "./useStyles";
import AppointmentPreviewActionButtons from "../AppointmentPreviewActionButtons";
import Box from "../../Unknown/Box";
import { ActionButton } from "../AppointmentPreviewActionButtons/types";

export type AppointmentInformationRequiredCardProps = {
  title: string;
  description: string;
  actionButtons: ActionButton[];
};

const AppointmentInformationRequiredCard: FC<
  AppointmentInformationRequiredCardProps
> = ({ title, description, actionButtons }) => {
  const { root } = useStyles();

  return (
    <Paper className={root}>
      <Alert severity="info" className={root}>
        <AlertTitle>{title}</AlertTitle>
        <Typography variant="body2" whiteSpace="pre-wrap" lineHeight="20.02px">
          {description}
        </Typography>
      </Alert>
      <Box p={3}>
        <AppointmentPreviewActionButtons actionButtons={actionButtons} />
      </Box>
    </Paper>
  );
};

export default AppointmentInformationRequiredCard;
