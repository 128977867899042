import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";
import commonMessages from "../../../common/messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      title: formatMessage(messages.title),
      description: formatMessage(messages.description),
      subtitleContactInformation: formatMessage(
        messages.subtitleContactInformation,
      ),
      helperPhoneNumber: formatMessage(messages.helperPhoneNumber),
      subtitleAddress: formatMessage(messages.subtitleAddress),
      subtitleMedicalInformation: formatMessage(
        messages.subtitleMedicalInformation,
      ),
      descriptionMedicalInformation: formatMessage(
        messages.descriptionMedicalInformation,
      ),
      subtitleReferringPhysician: formatMessage(
        messages.subtitleReferringPhysician,
      ),
      descriptionReferringPhysician: formatMessage(
        messages.descriptionReferringPhysician,
      ),
      subtitleImplantCard: formatMessage(messages.subtitleImplantCard),
      labelRace: formatMessage(commonMessages.race),
      labelSex: formatMessage(commonMessages.sex),
      labelHeightFt: formatMessage(commonMessages.heightFt),
      labelHeightIn: formatMessage(commonMessages.heightIn),
      labelWeightLbs: formatMessage(commonMessages.weightLbs),
      frontAndBack: formatMessage(messages.frontAndBack),
      btnCancel: formatMessage(messages.btnCancel),
      btnSubmit: formatMessage(messages.btnSubmit),
    };
  }, [formatMessage]);

  return translations;
};

export default useTranslations;
