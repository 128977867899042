import { useFormikContext } from "formik";
import React, { FC, useCallback, useMemo, useState } from "react";
import {
  getAllCountries,
  getStatesOfCountry,
} from "../../../common/regionHelpers";
import Button from "../../Unknown/Button";
import FormikTextField, {
  FormikTextFieldProps,
} from "../../Unknown/FormikTextField";
import Grid from "../../Unknown/Grid";
import MenuItem from "../../Unknown/MenuItem";
import Typography from "../../Unknown/Typography";
import getFormNames from "./getFormNames";
import { FormValues, Translations } from "./types";
import useTranslations from "./useTranslations";
import FormikPhoneInput from "../../Unknown/FormikPhoneInput";
import formatEmail from "../../../common/formatEmail";
import useRaceOptions from "../CustomerPortalAppPatientProvideInformationForm/useRaceOptions";
import useSexOptions from "../CustomerPortalAppPatientProvideInformationForm/useSexOptions";
import PatientConfirmInformationDialog from "../PatientConfirmInformationDialog";

export type PatientProvideInformationFormProps = {
  formTranslations: Translations;
  handleCancel: () => void | Promise<void>;
};

const PatientProvideInformationForm: FC<PatientProvideInformationFormProps> = ({
  formTranslations,
  handleCancel,
}) => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const translations = useTranslations();
  const formNames = getFormNames();
  const raceOptions = useRaceOptions();
  const sexOptions = useSexOptions();

  const {
    values,
    isSubmitting,
    isValid,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormikContext<FormValues>();

  const countries = getAllCountries();

  const states = useMemo(() => {
    if (!values.addressCountry) return [];
    const stateOptions = getStatesOfCountry(values.addressCountry);
    return stateOptions;
  }, [values.addressCountry]);

  const onOpenConfirmationDialog = () => {
    setIsConfirmationDialogOpen(true);
  };

  const onCloseConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };

  const onCountryChange = useCallback<
    NonNullable<FormikTextFieldProps["onChange"]>
  >(
    (event) => {
      setFieldValue("stateIsoCode", "");
      handleChange(event);
    },
    [handleChange, setFieldValue],
  );

  const {
    contactEmail,
    contactPhone,
    addressCity,
    addressCountry,
    addressPostalCode,
    addressPrimary,
    addressSecondary,
    addressState,
    sex,
    race,
    weightLbs,
    heightFt,
    heightIn,
    medicalCancerHistory,
    medicalReason,
    medicalSurgicalHistory,
  } = values;

  return (
    <>
      {!isConfirmationDialogOpen && (
        <Grid container spacing={4} direction="column" wrap="nowrap">
          <Grid item>
            <Typography variant="h5">{translations.title}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{translations.description}</Typography>
          </Grid>

          <Grid item container spacing={3} direction="column" wrap="nowrap">
            <Grid item>
              <Typography variant="h6">
                {translations.subtitleContactInformation}
              </Typography>
            </Grid>
            <Grid
              item
              container
              spacing={3}
              wrap="nowrap"
              direction={{ xs: "column", md: "row" }}
            >
              <Grid item xs>
                <FormikTextField
                  type="email"
                  required
                  fullWidth
                  disabled={isSubmitting}
                  name={formNames.contactEmail}
                  onChange={({ target }) =>
                    setFieldValue(
                      formNames.contactEmail,
                      formatEmail(target.value),
                    )
                  }
                  label={formTranslations.labelContactEmail}
                />
              </Grid>
              <Grid item xs>
                <FormikPhoneInput
                  name={formNames.contactPhone}
                  label={formTranslations.labelContactPhone}
                  required
                  fullWidth
                  disabled={isSubmitting}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item container spacing={3} direction="column" wrap="nowrap">
            <Grid item>
              <Typography variant="h6">
                {translations.subtitleAddress}
              </Typography>
            </Grid>
            <Grid
              item
              container
              spacing={3}
              wrap="nowrap"
              direction={{ xs: "column", md: "row" }}
            >
              <Grid item xs>
                <FormikTextField
                  required
                  fullWidth
                  disabled={isSubmitting}
                  name={formNames.addressPrimary}
                  label={formTranslations.labelAddressPrimary}
                />
              </Grid>
              <Grid item xs>
                <FormikTextField
                  fullWidth
                  disabled={isSubmitting}
                  name={formNames.addressSecondary}
                  label={formTranslations.labelAddressSecondary}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              spacing={3}
              wrap="nowrap"
              direction={{ xs: "column", md: "row" }}
            >
              <Grid item xs>
                <FormikTextField
                  required
                  fullWidth
                  disabled={isSubmitting}
                  name={formNames.addressCity}
                  label={formTranslations.labelAddressCity}
                />
              </Grid>
              <Grid item xs>
                <FormikTextField
                  select
                  required
                  fullWidth
                  disabled={isSubmitting}
                  name={formNames.addressState}
                  label={formTranslations.labelAddressState}
                >
                  {states.map((state) => (
                    <MenuItem key={state.isoCode} value={state.isoCode}>
                      {state.name}
                    </MenuItem>
                  ))}
                </FormikTextField>
              </Grid>
            </Grid>
            <Grid
              item
              container
              spacing={3}
              wrap="nowrap"
              direction={{ xs: "column", md: "row" }}
            >
              <Grid item xs>
                <FormikTextField
                  type="tel"
                  required
                  fullWidth
                  disabled={isSubmitting}
                  name={formNames.addressPostalCode}
                  label={formTranslations.labelAddressPostalCode}
                />
              </Grid>
              <Grid item xs>
                <FormikTextField
                  select
                  required
                  fullWidth
                  disabled={isSubmitting}
                  name={formNames.addressCountry}
                  label={formTranslations.labelAddressCountry}
                  onChange={onCountryChange}
                >
                  {countries.map((country) => (
                    <MenuItem key={country.isoCode} value={country.isoCode}>
                      {country.name}
                    </MenuItem>
                  ))}
                </FormikTextField>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container spacing={3} direction="column" wrap="nowrap">
            <Grid item container direction="column" wrap="nowrap" spacing={1}>
              <Grid item>
                <Typography variant="h6">
                  {translations.subtitleMedicalInformation}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {translations.descriptionMedicalInformation}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row" wrap="nowrap" spacing={3}>
              <Grid item xs={6}>
                <FormikTextField
                  required
                  fullWidth
                  type="number"
                  disabled={isSubmitting}
                  name={formNames.heightFt}
                  label={formTranslations.labelHeightFt}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  required
                  fullWidth
                  type="number"
                  disabled={isSubmitting}
                  name={formNames.heightIn}
                  label={formTranslations.labelHeightIn}
                />
              </Grid>
            </Grid>
            <Grid item xs>
              <FormikTextField
                required
                fullWidth
                type="number"
                disabled={isSubmitting}
                name={formNames.weightLbs}
                label={formTranslations.labelWeightLbs}
              />
            </Grid>
            <Grid item xs>
              <FormikTextField
                select
                required
                fullWidth
                disabled={isSubmitting}
                name={formNames.sex}
                label={formTranslations.labelSex}
              >
                {sexOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </FormikTextField>
            </Grid>
            <Grid item xs>
              <FormikTextField
                select
                required
                fullWidth
                disabled={isSubmitting}
                name={formNames.race}
                label={formTranslations.labelRace}
              >
                {raceOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </FormikTextField>
            </Grid>
            <Grid item xs>
              <FormikTextField
                required
                fullWidth
                disabled={isSubmitting}
                name={formNames.medicalReason}
                label={formTranslations.labelMedicalReason}
              />
            </Grid>
            <Grid item xs>
              <FormikTextField
                fullWidth
                disabled={isSubmitting}
                name={formNames.medicalSurgicalHistory}
                label={formTranslations.labelMedicalSurgicalHistory}
              />
            </Grid>
            <Grid item xs>
              <FormikTextField
                fullWidth
                disabled={isSubmitting}
                name={formNames.medicalCancerHistory}
                label={formTranslations.labelMedicalCancerHistory}
              />
            </Grid>
          </Grid>

          {/* <Grid item container spacing={3} direction="column" wrap="nowrap">
       <Grid item container direction="column" wrap="nowrap" spacing={1}>
         <Grid item>
           <Typography variant="h6">
             {translations.subtitleReferringPhysician}
           </Typography>
         </Grid>
         <Grid item>
           <Typography variant="body2">
             {translations.descriptionReferringPhysician}
           </Typography>
         </Grid>
       </Grid>
       <Grid
         item
         container
         spacing={3}
         wrap="nowrap"
         direction={{ xs: "column", md: "row" }}
       >
         <Grid item xs>
           <FormikTextField
             fullWidth
             disabled={isSubmitting}
             name={formNames.referringPracticeName}
             label={formTranslations.labelReferringPracticeName}
           />
         </Grid>
         <Grid item xs>
           <FormikTextField
             type="tel"
             fullWidth
             disabled={isSubmitting}
             name={formNames.referringPostalCode}
             label={formTranslations.labelReferringPostalCode}
           />
         </Grid>
       </Grid>
       <Grid
         item
         container
         spacing={3}
         wrap="nowrap"
         direction={{ xs: "column", md: "row" }}
       >
         <Grid item xs>
           <FormikTextField
             type="email"
             fullWidth
             disabled={isSubmitting}
             name={formNames.referringEmail}
             onChange={({ target }) =>
               setFieldValue(
                 formNames.referringEmail,
                 formatEmail(target.value),
               )
             }
             label={formTranslations.labelContactEmail}
           />
         </Grid>
         <Grid item xs>
           <FormikPhoneInput
             name={formNames.referringPhone}
             label={formTranslations.labelReferringPhone}
             fullWidth
             disabled={isSubmitting}
             autoComplete="off"
           />
         </Grid>
       </Grid>
     </Grid> */}

          <Grid
            item
            container
            spacing={1}
            direction="row"
            justifyContent="flex-end"
          >
            <Grid item>
              <Button
                variant="text"
                size="large"
                disabled={isSubmitting}
                onClick={handleCancel}
              >
                {translations.btnCancel}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="text"
                size="large"
                disabled={!isValid}
                onClick={onOpenConfirmationDialog}
              >
                {translations.btnSubmit}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      {isConfirmationDialogOpen && (
        <PatientConfirmInformationDialog
          handleClose={onCloseConfirmationDialog}
          handleSubmit={() => handleSubmit()}
          isLoading={isSubmitting}
          contactInformation={{
            contactEmail,
            contactPhone,
            addressCity,
            addressCountry,
            addressPostalCode,
            addressPrimary,
            addressSecondary,
            addressState,
          }}
          patientInformation={{
            sex,
            race,
            weightLbs,
            heightFt,
            heightIn,
          }}
          medicalInformation={{
            medicalCancerHistory,
            medicalReason,
            medicalSurgicalHistory,
          }}
        />
      )}
    </>
  );
};

export default PatientProvideInformationForm;
