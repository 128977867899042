import React, { FC } from "react";
import { useEffectOnce } from "react-use";
import { useAuthContext } from "../../Auth/AuthContextProvider/useAuthContext";
import Box from "../Box";
import CircularProgress from "../CircularProgress";
import { useUIContext } from "../UIContext";
import checkIsCheckInApp from "../../../common/checkIsCheckInApp";
import CheckInAppRoot from "../CheckInAppRoot";
import CustomerPortalAppRoot from "../CustomerPortalAppRoot";

const Root: FC = () => {
  const { isUserLoaded, userData } = useAuthContext();
  const { setSubdomain } = useUIContext();
  const subdomain = window.location.hostname.split(".")[0];
  useEffectOnce(() => {
    setSubdomain(subdomain);
  });
  const isCheckInApp = checkIsCheckInApp(subdomain);

  if (!isUserLoaded) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        pt={10}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isCheckInApp) {
    return <CheckInAppRoot userData={userData} />;
  }

  return <CustomerPortalAppRoot userData={userData} />;
};

export default Root;
