import React, { FC, useMemo } from "react";
import { useFormikContext } from "formik";
import { format, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import PatientFormHeader from "../../Unknown/PatientFormHeader";
import SimpleCheckbox from "../../Unknown/SimpleCheckbox";
import Button from "../../Unknown/Button";
import CircularProgress from "../../Unknown/CircularProgress";
import useTranslations from "./useTranslations";
import {
  Patient,
  FormValues,
} from "../CustomerPortalAppPatientPrivacyFormContainer/types";
import { getNowUTC } from "../../../common/dateHelpers";
import SimpleList from "../../Unknown/SimpleList";
import usePatientPrivacyFormSimpleLinks from "./usePatientPrivacyFormSimpleLinks";
import {
  hhsHipaaUnderstandingLink,
  legalEmail,
} from "../../../common/constants";

interface PatientPrivacyFormContainerProps {
  patient: Patient | null;
}

const PatientPrivacyForm: FC<PatientPrivacyFormContainerProps> = ({
  patient,
}) => {
  const navigate = useNavigate();
  const translations = useTranslations();
  const { values, isSubmitting, handleChange, handleSubmit } =
    useFormikContext<FormValues>();

  const patientInitials = `${patient?.first_name} ${patient?.last_name}`;

  const saveButtonDisabled =
    isSubmitting || !values.patientSignature || !values.patientUnerstand;

  const handleCancel = () => {
    navigate("/");
  };

  const {
    ourUsersAndDisclosuresSndSimpleListItems,
    ourUsersAndDisclosuresFirstSimpleListItems,
    yourRightsSimpleListItems,
    ourResponsibilitiesSimpleListItems,
  } = usePatientPrivacyFormSimpleLinks();

  const headerItems = useMemo(
    () => [
      {
        title: translations.headerNameTitle,
        value: patientInitials,
      },
      {
        title: translations.headerDateOfBirthTitle,
        value: format(parseISO(patient?.date_birth || ""), "M/d/yyyy"),
      },
      { title: translations.headerPatientIdTitle, value: patient?.mrn },
    ],
    [translations, patient, patientInitials],
  );

  return (
    <Grid container flexDirection="column">
      <Grid
        container
        flexDirection="column"
        gap={6}
        sx={{ wordBreak: "break-word" }}
      >
        <PatientFormHeader
          headerTitle={translations.title}
          headerItems={headerItems}
          type="privacy"
        />

        <Typography>
          {translations.mainDescriptionDefaultPart}{" "}
          <Typography
            component="span"
            fontWeight={700}
            sx={{ textDecoration: "underline" }}
          >
            {translations.mainDescriptionBoldPart}
          </Typography>
        </Typography>

        <Grid container flexDirection="column" gap={3.2}>
          <Typography variant="h6">{translations.yourRightsTitle}</Typography>

          <Typography>{translations.yourRightsFirstDesc}</Typography>

          <Typography>{translations.yourRightsSndDesc}</Typography>

          <SimpleList listItems={yourRightsSimpleListItems} />
        </Grid>

        <Grid container flexDirection="column" gap={3.2}>
          <Typography variant="h6">
            {translations.ourResponsibilities}
          </Typography>

          <SimpleList listItems={ourResponsibilitiesSimpleListItems} />

          <Typography>
            {translations.ourResponsibilitiesLinkPrefix}{" "}
            <Typography
              component="a"
              target="_blank"
              href={`${hhsHipaaUnderstandingLink}`}
              color="text.primary"
            >
              {hhsHipaaUnderstandingLink}
            </Typography>
          </Typography>
        </Grid>

        <Grid container flexDirection="column" gap={3.2}>
          <Typography variant="h6">
            {translations.ourUsersAndDisclosures}
          </Typography>

          <Typography>
            {translations.ourUsersAndDisclosuresFirstDesc}
          </Typography>

          <SimpleList listItems={ourUsersAndDisclosuresFirstSimpleListItems} />

          <Typography>{translations.ourUsersAndDisclosuresSndDesc}</Typography>

          <SimpleList listItems={ourUsersAndDisclosuresSndSimpleListItems} />

          <Typography fontStyle="italic">
            {translations.ourUsersAndDisclosuresThirdDesc}
          </Typography>

          <Typography>
            {translations.ourUsersAndDisclosuresFourthDesc}
          </Typography>

          <SimpleCheckbox
            name="patientUnerstand"
            onChange={handleChange}
            checked={values.patientUnerstand}
            label={translations.patientUnderstandCheckboxLabel}
          />
        </Grid>

        <Grid container gap={3.2} flexDirection="column">
          <Typography variant="h6">
            {translations.changesToTheTermsTitle}
          </Typography>

          <Typography>{translations.changesToTheTermsDesc}</Typography>
        </Grid>

        <Grid container gap={3.2} flexDirection="column">
          <Typography variant="h6">
            {translations.designatedOfficerTitle}
          </Typography>

          <Typography>
            {translations.designatedOfficerFirstDescFirstPart}{" "}
            <Typography
              component="a"
              href={`mailto:${legalEmail}`}
              color="text.primary"
            >
              {legalEmail}
            </Typography>{" "}
            {translations.designatedOfficerFirstDescSndPart}
          </Typography>

          <Typography>{translations.designatedOfficerSndDesc}</Typography>

          <Typography>
            <Typography component="span" fontWeight={700}>
              {translations.designatedOfficerThirdDescFirstPart}
            </Typography>{" "}
            {translations.designatedOfficerThirdDescSndPart}
          </Typography>
        </Grid>

        <Grid container gap={3.2} flexDirection="column">
          <Typography variant="h6" fontWeight={500}>
            {translations.signatureTitle}
          </Typography>

          <Typography variant="body1">{`${
            translations.dateTitle
          }: ${getNowUTC().toLocaleDateString()}`}</Typography>

          <Grid container flexDirection="column">
            <SimpleCheckbox
              name="patientSignature"
              onChange={handleChange}
              checked={values.patientSignature}
              label={`${patientInitials} ${translations.signatureCheckboxSuffix}`}
            />

            <Typography
              pl={6.6}
              fontSize={12}
              lineHeight="19.92px"
              letterSpacing="0.4px"
            >
              {translations.signatureCheckboxUnderstandText}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="row"
          justifyContent="flex-end"
          gap={1.6}
        >
          <Grid item>
            <Button
              variant="text"
              size="large"
              disabled={isSubmitting}
              onClick={handleCancel}
            >
              {translations.cancelButtonTitle}
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="text"
              size="large"
              disabled={saveButtonDisabled}
              onClick={() => handleSubmit()}
              startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
            >
              {translations.saveButtonTitle}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PatientPrivacyForm;
