import { defineMessages } from "react-intl";

export default defineMessages({
  firstTitle: {
    id: "AppointmentReport.AppointmentReportUnexpectedFindingsConfirmationScreen.firstTitle",
    defaultMessage:
      "Thank you for confirming the unexpected findings in your report.",
  },
  secondTitle: {
    id: "AppointmentReport.AppointmentReportUnexpectedFindingsConfirmationScreen.secondTitle",
    defaultMessage:
      "If you have any questions or concerns please contact us and we will be happy to assist you.",
  },
});
