const formatAddress = (params: {
  primaryAddress?: string;
  secondaryAddress?: string;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
  country?: string | null;
}) => {
  const { primaryAddress, secondaryAddress, city, state, postalCode, country } =
    params;

  const stateData = [state, postalCode, country].filter(Boolean).join(" ");

  const address = [primaryAddress, secondaryAddress, city, stateData]
    .filter(Boolean)
    .join(", ");

  return address;
};

export default formatAddress;
