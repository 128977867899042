import React from "react";
import {
  EventOutlinedIcon,
  ShoppingBagOutlinedIcon,
  StoreOutlinedIcon,
} from "../../Unknown/Icons";
import { Center, CenterService, GridItems } from "./types";
import useStyles from "./useStyles";
import { getTimeWithEnglishTimeNotation } from "../../../common/dateHelpers";

const useGridItemsList = (params: {
  date: Date;
  center: Center;
  centerServices: CenterService[];
}) => {
  const { date, center, centerServices } = params;

  const { captionRoot } = useStyles();

  const gridItemList: GridItems[] = [
    {
      id: "center",
      items: [
        {
          id: "date",
          icon: <EventOutlinedIcon color="primary" />,
          texts: [
            { text: date.toLocaleDateString(), props: { variant: "body2" } },
            {
              text: getTimeWithEnglishTimeNotation(date),
              props: { className: captionRoot },
            },
          ],
        },
        {
          id: "center",
          icon: <StoreOutlinedIcon color="primary" />,
          texts: [
            { text: center.name, props: { variant: "body2" } },
            { text: center.address, props: { className: captionRoot } },
          ],
        },
      ],
    },
    {
      id: "services",
      items: [
        {
          id: "services",
          icon: <ShoppingBagOutlinedIcon color="primary" />,
          texts: centerServices.map(({ name }) => ({
            text: name,
            props: { variant: "body2" },
          })),
        },
      ],
    },
  ];

  return gridItemList;
};

export default useGridItemsList;
