import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import { isBefore } from "date-fns";

type PatientForm = Pick<DatabaseRow<"patient_forms">, "type" | "expiration_at">;
export type PatientFormType = "privacy" | "consent";

interface CheckIsPatientFormsValid {
  patientForms: PatientForm[];
  type: PatientFormType;
}

const checkIsPatientFormValid = ({
  patientForms,
  type,
}: CheckIsPatientFormsValid): boolean => {
  const existedForm = patientForms?.find(
    (patientForm) => patientForm.type === type,
  );
  if (existedForm) {
    return isBefore(new Date(), new Date(existedForm.expiration_at));
  }

  return false;
};

export default checkIsPatientFormValid;
