import { RESEND_CODE_LIMIT_MINUTES } from "@Shape-Digital/kudzu-data/lib/constants/customerPortal";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      defaultError: formatMessage(commonMessages.defaultError),
      labelEmail: formatMessage(messages.labelEmail),
      errorEmailIsInvalid: formatMessage(messages.errorEmailIsInvalid),
      errorSendAttemptsLimit: formatMessage(messages.errorSendAttemptsLimit, {
        minutes: RESEND_CODE_LIMIT_MINUTES,
      }),
    };
  }, [formatMessage]);

  return translations;
};

export default useTranslations;
