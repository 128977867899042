import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import Box from "../../Unknown/Box";
import CircularProgress from "../../Unknown/CircularProgress";
import AppointmentList from "../AppointmentList";
import useAppointmentListComponent from "./useAppointmentListComponent";
import useAppointments from "./useAppointments";
import { useUIContext } from "../../Unknown/UIContext";
import { useAuthContext } from "../../Auth/AuthContextProvider/useAuthContext";
import checkIsCheckInApp from "../../../common/checkIsCheckInApp";

const AppointmentListContainer: FC = () => {
  const { signOut } = useAuthContext();
  const { subdomain } = useUIContext();
  const navigate = useNavigate();
  const isCheckInApp = checkIsCheckInApp(subdomain);
  const { isLoading, appointments } = useAppointments();

  const props = useAppointmentListComponent({ appointments });

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!appointments || !props) return null;

  if (isCheckInApp && !appointments.length) {
    signOut();
    navigate("/checked-in");
    return null;
  }

  return <AppointmentList {...props} />;
};

export default AppointmentListContainer;
