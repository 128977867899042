import { useFormik } from "formik";
import { useCallback } from "react";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import { useAuthContext } from "../AuthContextProvider/useAuthContext";
import { initialValues } from "./constants";
import { ConfirmUserFormikSubmitHandler, ConfirmUserFormValues } from "./types";
import useErrorMessages from "./useErrorMessages";
import useValidationSchema from "./useValidationSchema";

const useForm = (params: {
  email: string;
  handleError: (msg: string | null) => void;
}) => {
  const { email, handleError } = params;

  const validationSchema = useValidationSchema();

  const { getErrorMessageOnAnswerCustomChallenge } = useErrorMessages();

  const { runAsyncFunction } = useDefaultErrorWrapper();

  const { answerCustomChallenge } = useAuthContext();

  const onSubmit = useCallback<ConfirmUserFormikSubmitHandler>(
    async (values) => {
      try {
        const { code } = values;
        const data = await runAsyncFunction(answerCustomChallenge, {
          email,
          code: code.toString(),
        });

        if (data.status === "error") {
          const msg = getErrorMessageOnAnswerCustomChallenge(data.error.code);
          throw new Error(msg);
        }
      } catch (error) {
        handleError((error as Error).message);
      }
    },
    [
      runAsyncFunction,
      answerCustomChallenge,
      email,
      getErrorMessageOnAnswerCustomChallenge,
      handleError,
    ],
  );

  const formik = useFormik<ConfirmUserFormValues>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return formik;
};

export default useForm;
