import { useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { useUIContext } from "../../Unknown/UIContext";
import { FormConfig } from "./types";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import { useFormContext } from "../../Unknown/FormContext";
import useTranslations from "./useTranslations";
import { useAuthContext } from "../../Auth/AuthContextProvider/useAuthContext";
import checkIsAppointmentCheckedIn from "../../../common/checkIsAppointmentCheckedIn";

const useFormikComponent = (appointmentId: string) => {
  const navigate = useNavigate();

  const { setAlert } = useUIContext();

  const { signOut } = useAuthContext();

  const { setFormAlert, setFormAppointmentId } = useFormContext();

  const { appointmentScreeningFormSuccessMessage } = useTranslations();

  const provideCheckInAppAppointmentScreeningForm = useFirebaseAppFunction(
    "provideCheckInAppAppointmentScreeningForm",
  );

  const initialValues = useMemo<FormConfig["initialValues"]>(() => {
    return {
      patientSignature: false,
      doNotHaveAnyItem: false,
      sensitiveItems: [],
    };
  }, []);
  const { runAsyncFunction } = useDefaultErrorWrapper();

  const onSubmit = useCallback<FormConfig["onSubmit"]>(
    async (values) => {
      try {
        await runAsyncFunction(provideCheckInAppAppointmentScreeningForm, {
          appointmentId,
          sensitiveItems: values.sensitiveItems,
        });
        const isAppointmentCheckedIn = await checkIsAppointmentCheckedIn(
          appointmentId,
        );

        if (isAppointmentCheckedIn) {
          signOut();
          navigate("/checked-in");
          return;
        }

        setFormAlert({
          isShown: true,
          severity: "success",
          message: appointmentScreeningFormSuccessMessage,
        });
        setFormAppointmentId(null);

        navigate("/");
      } catch (error) {
        setAlert({
          isShown: true,
          severity: "error",
          message: (error as Error).message,
        });
      }
    },
    [
      appointmentId,
      setAlert,
      setFormAppointmentId,
      navigate,
      provideCheckInAppAppointmentScreeningForm,
      runAsyncFunction,
      setFormAlert,
      appointmentScreeningFormSuccessMessage,
      signOut,
    ],
  );

  return {
    enableReinitialize: true,
    initialValues,
    onSubmit,
  };
};

export default useFormikComponent;
