import makeStyles from "@mui/styles/makeStyles";
import { ITheme } from "../../../common/theme";

const useStyles = makeStyles<ITheme>(({ palette, spacing }) => {
  return {
    containerRoot: {
      backgroundColor: palette.background.default,
      paddingTop: spacing(6),
      paddingRight: spacing(6),
      paddingBottom: spacing(6),
      paddingLeft: spacing(6),
      margin: 0,
      minWidth: "100%",
    },
    layoutRoot: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      margin: 0,
      padding: 0,
    },
  };
});

export default useStyles;
