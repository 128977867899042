import React, { FC, useState } from "react";
import Box from "../../Unknown/Box";
import Paper from "../../Unknown/Paper";
import AuthCheckUserForm from "../AuthCheckUserForm";
import useAuthCheckUserForm from "./useAuthCheckUserForm";
import useForm from "./useForm";

export type AuthCheckUserFormContainerProps = {
  handleSubmit: (params: { phoneNumber: string; email: string }) => void;
};

const AuthCheckUserFormContainer: FC<AuthCheckUserFormContainerProps> = ({
  handleSubmit,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const formik = useForm({ handleError: setErrorMessage, handleSubmit });

  const props = useAuthCheckUserForm({ formik, errorMessage });

  return (
    <Paper>
      <Box p={6}>
        <AuthCheckUserForm {...props} />
      </Box>
    </Paper>
  );
};

export default AuthCheckUserFormContainer;
