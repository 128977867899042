import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      title: formatMessage(messages.title),
      description: formatMessage(messages.description),
      subtitleContactInformation: formatMessage(
        messages.subtitleContactInformation,
      ),
      helperPhoneNumber: formatMessage(messages.helperPhoneNumber),
      subtitleAddress: formatMessage(messages.subtitleAddress),
      btnCancel: formatMessage(messages.btnCancel),
      btnSubmit: formatMessage(messages.btnSubmit),
    };
  }, [formatMessage]);

  return translations;
};

export default useTranslations;
