import { convertDbDateToCenterTZ } from "../../../common/dateHelpers";
import formatAddress from "../../../common/formatAddress";
import { Appointment as ListItem } from "../AppointmentList/types";
import { primaryAddressType, secondaryAddressType } from "./constants";
import { Appointment, AppointmentOfferings } from "./types";

const getAppointmentOfferingName = (
  appointmentOffering: AppointmentOfferings,
): string => {
  let appointmentOfferingName = appointmentOffering.center_offering.name;
  if (appointmentOffering.side) {
    appointmentOfferingName = `${appointmentOfferingName} (${appointmentOffering.side})`;
  }
  return appointmentOfferingName;
};

const convertAppointmentToListItem = (appointment: Appointment) => {
  const {
    id,
    center,
    appointment_patient_details,
    center_time_slot,
    appointment_add_ons,
    appointment_packages,
    appointment_offerings,
  } = appointment;

  const {
    name,
    timezone,
    center_addresses,
    city,
    country: countryCode,
    postal_code,
    state: stateCode,
  } = center;

  const primaryAddress = center_addresses.find(
    ({ type }) => type === primaryAddressType,
  );

  const secondaryAddress = center_addresses.find(
    ({ type }) => type === secondaryAddressType,
  );

  const address = formatAddress({
    primaryAddress: primaryAddress?.address,
    secondaryAddress: secondaryAddress?.address,
    city,
    state: stateCode,
    postalCode: postal_code,
    country: countryCode,
  });

  const patientDetails = appointment_patient_details[0];

  const { first_name, last_name } = patientDetails;

  const { started_at } = center_time_slot;

  const date = convertDbDateToCenterTZ({ dateString: started_at, timezone });

  const filteredOfferings = appointment_offerings.filter(
    (appointmentOffering) => !appointmentOffering?.appointment_package_id,
  );

  const offerings = filteredOfferings.map((appointmentOffering) => {
    const appointmentOfferingName =
      getAppointmentOfferingName(appointmentOffering);

    return {
      name: appointmentOfferingName,
    };
  });

  const packages = appointment_packages.map((appointmentPackage) => ({
    name: appointmentPackage.center_package.name,
  }));

  const add_ons = appointment_add_ons.map((appointmentAddOn) => ({
    name: appointmentAddOn.center_add_on.name,
  }));

  const result: ListItem = {
    id,
    person: {
      firstName: first_name,
      lastName: last_name,
    },
    date,
    center: {
      name,
      address,
    },
    centerServices: [...packages, ...offerings, ...add_ons],
  };

  return result;
};

export default convertAppointmentToListItem;
