/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  isValidNumberForRegion as isValidNumberForRegionLib,
  isSupportedCountry,
  parsePhoneNumber,
  CountryCode,
} from "libphonenumber-js";

const isValidNumberForRegion = (phone: string, countryCode: string) => {
  if (!isSupportedCountry(countryCode)) {
    throw new Error("Country is not supported");
  }

  return isValidNumberForRegionLib(phone, countryCode as any);
};

const formatNational = <T extends string | null | undefined>(params: {
  phoneNumber: T;
  countryCode?: CountryCode;
}) => {
  const { phoneNumber, countryCode } = params;

  if (!phoneNumber || !countryCode) return phoneNumber;

  try {
    const formatted = parsePhoneNumber(
      phoneNumber,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      countryCode as any,
    ).formatNational();

    return formatted;
  } catch {
    return phoneNumber;
  }
};

const formatInternational = <T extends string | null | undefined>(params: {
  phoneNumber: T;
}) => {
  const { phoneNumber } = params;

  if (!phoneNumber) return phoneNumber;

  const isPrefixIncluded = phoneNumber.startsWith("+");

  try {
    const parsedPhoneNumber = parsePhoneNumber(
      `${isPrefixIncluded ? "" : "+"}${phoneNumber}`,
    );
    const internationalPhoneNumber = parsedPhoneNumber.formatInternational();

    const [countryCode, carrierCode, ...number] =
      internationalPhoneNumber.split(" ");

    if (number.length > 0) {
      return `${countryCode} (${carrierCode}) ${number.join(" ")}`;
    }

    if (carrierCode) {
      return `${countryCode} ${carrierCode}`;
    }

    return phoneNumber;
  } catch {
    return phoneNumber;
  }
};

const getValidPhoneNumber = (phoneNumber: string) => {
  return `+${phoneNumber.replace(/\D/g, "")}`;
};

export {
  formatNational,
  isValidNumberForRegion,
  formatInternational,
  getValidPhoneNumber,
};
