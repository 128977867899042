import { useMemo } from "react";
import { useIntl } from "react-intl";
import { PatientChangeInformationFormProps } from "../PatientChangeInformationForm";
import messages from "./messages";

const usePatientFormTranslations =
  (): PatientChangeInformationFormProps["formTranslations"] => {
    const { formatMessage } = useIntl();

    const translations = useMemo(() => {
      return {
        labelContactEmail: formatMessage(messages.labelContactEmail),
        labelContactPhone: formatMessage(messages.labelContactPhone),
        labelAddressPrimary: formatMessage(messages.labelAddressPrimary),
        labelAddressSecondary: formatMessage(messages.labelAddressSecondary),
        labelAddressCity: formatMessage(messages.labelAddressCity),
        labelAddressState: formatMessage(messages.labelAddressState),
        labelAddressPostalCode: formatMessage(messages.labelAddressPostalCode),
        labelAddressCountry: formatMessage(messages.labelAddressCountry),
      };
    }, [formatMessage]);

    return translations;
  };

export default usePatientFormTranslations;
