import { useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { useUIContext } from "../../Unknown/UIContext";
import { FormConfig } from "./types";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import { useFormContext } from "../../Unknown/FormContext";
import useTranslations from "./useTranslations";

const useFormikComponent = (appointmentId: string) => {
  const navigate = useNavigate();

  const { setAlert } = useUIContext();

  const { setFormAlert, setFormAppointmentId } = useFormContext();

  const { appointmentScreeningFormSuccessMessage } = useTranslations();

  const provideCustomerPortalAppointmentScreeningForm = useFirebaseAppFunction(
    "provideCustomerPortalAppointmentScreeningForm",
  );

  const initialValues = useMemo<FormConfig["initialValues"]>(() => {
    return {
      patientSignature: false,
      doNotHaveAnyItem: false,
      sensitiveItems: [],
    };
  }, []);
  const { runAsyncFunction } = useDefaultErrorWrapper();

  const onSubmit = useCallback<FormConfig["onSubmit"]>(
    async (values) => {
      try {
        await runAsyncFunction(provideCustomerPortalAppointmentScreeningForm, {
          appointmentId,
          sensitiveItems: values.sensitiveItems,
        });

        setFormAlert({
          isShown: true,
          severity: "success",
          message: appointmentScreeningFormSuccessMessage,
        });
        setFormAppointmentId(null);

        navigate("/");
      } catch (error) {
        setAlert({
          isShown: true,
          severity: "error",
          message: (error as Error).message,
        });
      }
    },
    [
      appointmentId,
      setAlert,
      setFormAppointmentId,
      navigate,
      provideCustomerPortalAppointmentScreeningForm,
      runAsyncFunction,
      setFormAlert,
      appointmentScreeningFormSuccessMessage,
    ],
  );

  return {
    enableReinitialize: true,
    initialValues,
    onSubmit,
  };
};

export default useFormikComponent;
