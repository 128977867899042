import { useFormik } from "formik";
import { useCallback } from "react";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import { useAuthContext } from "../AuthContextProvider/useAuthContext";
import { initialValues } from "./constants";
import { CheckUserFormikSubmitHandler, CheckUserFormValues } from "./types";
import useErrorMessages from "./useErrorMessages";
import useValidationSchema from "./useValidationSchema";

const useForm = (params: {
  handleError: (mgs: string | null) => void;
  handleSubmit: (params: { phoneNumber: string; email: string }) => void;
}) => {
  const { handleError, handleSubmit } = params;

  const { getErrorMessageOnSignIn } = useErrorMessages();

  const { runAsyncFunction } = useDefaultErrorWrapper();

  const { signIn } = useAuthContext();

  const validationSchema = useValidationSchema();

  const onSubmit = useCallback<CheckUserFormikSubmitHandler>(
    async (values) => {
      try {
        const { email } = values;
        const data = await runAsyncFunction(signIn, {
          email,
        });

        if (data.status === "error") {
          const msg = getErrorMessageOnSignIn(data.error.code);
          throw new Error(msg);
        }

        const { phoneNumber } = data.data;

        handleSubmit({ phoneNumber, email });
      } catch (error) {
        handleError((error as Error).message);
      }
    },
    [
      runAsyncFunction,
      signIn,
      handleSubmit,
      getErrorMessageOnSignIn,
      handleError,
    ],
  );

  const formik = useFormik<CheckUserFormValues>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return formik;
};

export default useForm;
