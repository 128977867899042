import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AppointmentListScreen from "../../Appointment/AppointmentListScreen";
import CustomerPortalAppPatientProvideInformationScreen from "../../Patient/CustomerPortalAppPatientProvideInformationScreen";
import PatientChangeInformationScreen from "../../Patient/PatientChangeInformationScreen";
import AuthenticatedLayout from "../AuthenticatedLayout";
import CustomerPortalAppPatientPrivacyFormScreen from "../../PatientForm/CustomerPortalAppPatientPrivacyFormScreen";
import CustomerPortalAppPatientConsentFormScreen from "../../PatientForm/CustomerPortalAppPatientConsentFormScreen";
import CustomerPortalAppointmentScreeningFormScreen from "../../AppointmentForm/CustomerPortalAppointmentScreeningFormScreen";
import AppointmentReportUnexpectedFindingsConfirmationScreen from "../../AppointmentReport/AppointmentReportUnexpectedFindingsConfirmationScreen";

const AuthenticatedRoot: FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthenticatedLayout>
            <AppointmentListScreen />
          </AuthenticatedLayout>
        }
      />
      <Route
        path="/appointment/:appointmentId/provide-patient-information"
        element={
          <AuthenticatedLayout>
            <CustomerPortalAppPatientProvideInformationScreen />
          </AuthenticatedLayout>
        }
      />
      <Route
        path="/appointment/:appointmentId/change-patient-information"
        element={
          <AuthenticatedLayout>
            <PatientChangeInformationScreen />
          </AuthenticatedLayout>
        }
      />
      <Route
        path="/provide-privacy-form"
        element={
          <AuthenticatedLayout>
            <CustomerPortalAppPatientPrivacyFormScreen />
          </AuthenticatedLayout>
        }
      />
      <Route
        path="/provide-consent-form"
        element={
          <AuthenticatedLayout>
            <CustomerPortalAppPatientConsentFormScreen />
          </AuthenticatedLayout>
        }
      />
      <Route
        path="/provide-screening-form"
        element={
          <AuthenticatedLayout>
            <CustomerPortalAppointmentScreeningFormScreen />
          </AuthenticatedLayout>
        }
      />
      <Route
        path="/confirmation/:confirmationToken"
        element={<AppointmentReportUnexpectedFindingsConfirmationScreen />}
      />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default AuthenticatedRoot;
