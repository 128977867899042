import React from "react";
import GlobalStylesBase from "@mui/material/GlobalStyles";
import { useTheme } from "@mui/styles";
import { ITheme } from "../../../common/theme";

const GlobalStyles: React.FC = () => {
  const theme = useTheme<ITheme>();

  return (
    <GlobalStylesBase
      styles={{
        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
          width: 14,
          borderLeft: `1px solid ${theme.palette.grey[100]}`,
        },
        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
          backgroundColor: theme.palette.text.secondary,
          backgroundClip: "content-box",
          border: "4px solid transparent",
          borderRadius: theme.borderRadius.m,
        },
        "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
          display: "none",
        },
      }}
    />
  );
};

export default GlobalStyles;
