import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "User.UserCheckedInNotificationScreen.title",
    defaultMessage: "Success!",
  },
  description: {
    id: "User.UserCheckedInNotificationScreen.description",
    defaultMessage: "You’re checked in and ready for your MRI scan.",
  },
});
