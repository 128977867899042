import * as React from "react";

const LogoWithText = () => (
  <svg
    width={162}
    height={42}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    viewBox="0 0 384.49 89.76"
  >
    <style id="style2" type="text/css">
      {".st0{fill:#2d9ab0}.st1{fill:#173e51}"}
    </style>
    <g id="g32" transform="translate(-56.43 -56.374)">
      <g id="g12">
        <g id="g8">
          <path
            id="path4"
            d="M56.66 83.27h-.23v10.08h.31c7.78 0 15.15 4.39 18.11 11.58 5.94 14.39-4.54 28.21-18.19 28.21h-.23v10.08h.23c19.75 0 35.09-19.2 28.36-39.92-3.94-12.12-15.63-20.03-28.36-20.03Z"
            className="st0"
          />
          <path
            id="path6"
            d="M126.53 93.07h.31V82.99h-.23c-12.85 0-24.64 7.99-28.62 20.21-6.8 20.91 8.69 40.29 28.62 40.29h.23v-10.08h-.23c-13.84 0-24.47-14.02-18.44-28.61 3.01-7.29 10.48-11.73 18.36-11.73z"
            className="st0"
          />
        </g>
        <path
          id="path10"
          d="M100.83 68.21a9.507 9.507 0 0 0-11.55-11.55c-3.31.8-6.03 3.52-6.83 6.83A9.505 9.505 0 0 0 94 75.05c3.31-.81 6.02-3.53 6.83-6.84z"
          className="st0"
        />
      </g>
      <g id="g30">
        <path
          id="path14"
          d="M155.8 90.38c0-1.22.45-2.28 1.36-3.19.9-.9 2.05-1.36 3.44-1.36 1.39 0 2.56.43 3.5 1.29.95.86 1.42 1.95 1.42 3.25s-.47 2.39-1.42 3.25c-.95.86-2.12 1.29-3.5 1.29-1.39 0-2.54-.45-3.44-1.36-.91-.89-1.36-1.95-1.36-3.17zm1.07 10.29h7.58v30.31h-7.58z"
          className="st1"
        />
        <path
          id="path16"
          d="M172.84 100.67h7.2v4.73h.13c.67-1.43 1.76-2.7 3.25-3.82 1.49-1.11 3.5-1.67 6.03-1.67 4.76 0 7.91 1.87 9.47 5.62 1.09-1.94 2.46-3.36 4.1-4.26 1.64-.9 3.6-1.36 5.87-1.36 2.02 0 3.72.34 5.11 1.01 1.39.67 2.51 1.6 3.38 2.78.87 1.18 1.48 2.56 1.86 4.14.38 1.58.57 3.27.57 5.08v18.06h-7.58V113.8c0-.92-.08-1.82-.25-2.68-.17-.86-.46-1.62-.88-2.27-.42-.65-.99-1.17-1.71-1.55-.72-.38-1.64-.57-2.78-.57-1.18 0-2.18.22-3 .66-.82.44-1.49 1.03-2.02 1.77s-.9 1.58-1.14 2.53c-.23.95-.35 1.9-.35 2.87v16.42h-7.58v-18.06c0-1.89-.4-3.4-1.2-4.51-.8-1.11-2.12-1.67-3.98-1.67-1.26 0-2.33.21-3.19.63-.86.42-1.58.99-2.15 1.71-.57.72-.98 1.54-1.23 2.46-.25.93-.38 1.89-.38 2.9v16.54h-7.58v-30.31z"
          className="st1"
        />
        <path
          id="path18"
          d="M247.03 126.81h-.19c-1.1 1.73-2.48 2.98-4.17 3.76-1.68.78-3.54 1.17-5.55 1.17-1.39 0-2.75-.19-4.07-.57-1.33-.38-2.5-.95-3.54-1.7a8.832 8.832 0 0 1-2.49-2.84c-.63-1.14-.95-2.46-.95-3.98 0-1.64.29-3.04.88-4.2a8.6 8.6 0 0 1 2.37-2.9c.99-.78 2.14-1.4 3.44-1.86 1.3-.46 2.66-.81 4.07-1.04 1.41-.23 2.83-.38 4.26-.44 1.43-.06 2.78-.09 4.04-.09h1.89v-.82c0-1.89-.65-3.32-1.96-4.26-1.3-.95-2.97-1.42-4.99-1.42-1.6 0-3.09.28-4.48.85-1.39.57-2.59 1.34-3.6 2.3l-3.98-3.98c1.68-1.72 3.65-2.97 5.9-3.72a21.68 21.68 0 0 1 6.98-1.14c2.15 0 3.96.24 5.43.73 1.47.48 2.69 1.11 3.66 1.86.97.76 1.71 1.63 2.24 2.62.53.99.9 1.99 1.14 3 .23 1.01.37 1.99.41 2.94.04.95.06 1.78.06 2.49V131h-6.82v-4.19zm-.45-9.41H245c-1.05 0-2.23.04-3.54.13-1.3.08-2.54.28-3.69.6-1.16.32-2.14.78-2.94 1.39-.8.61-1.2 1.46-1.2 2.56 0 .72.16 1.32.47 1.8.32.48.73.89 1.23 1.23.51.34 1.07.58 1.71.73.63.15 1.26.22 1.89.22 2.61 0 4.54-.62 5.78-1.86 1.24-1.24 1.86-2.94 1.86-5.08v-1.72z"
          className="st1"
        />
        <path
          id="path20"
          d="M264.97 146.13v-6.87l11.03.05c1.81 0 3.34-.25 4.58-.76 1.24-.5 2.23-1.2 2.97-2.08.74-.88 1.26-1.95 1.58-3.19.32-1.24.47-2.62.47-4.14v-2.21h-.13c-1.1 1.47-2.55 2.58-4.36 3.31-1.81.74-3.66 1.11-5.55 1.11-2.27 0-4.34-.41-6.19-1.23-1.85-.82-3.44-1.93-4.77-3.32-1.33-1.39-2.35-3.03-3.06-4.93-.72-1.89-1.07-3.91-1.07-6.06 0-2.23.34-4.31 1.01-6.25.67-1.93 1.66-3.62 2.97-5.05 1.3-1.43 2.87-2.56 4.7-3.38 1.83-.82 3.93-1.23 6.28-1.23 2.15 0 4.15.42 6 1.26 1.85.84 3.33 2.19 4.42 4.04h.13v-4.55h7.2v27.59c0 2.74-.34 5.21-1.01 7.42-.67 2.21-1.72 4.08-3.12 5.62-1.41 1.54-3.19 2.72-5.34 3.57-2.15.84-4.69 1.26-7.64 1.26h-11.1zm12.05-39.4c-1.35 0-2.57.22-3.66.66a8.24 8.24 0 0 0-2.81 1.83c-.78.78-1.39 1.73-1.83 2.84-.44 1.11-.66 2.35-.66 3.69 0 1.22.22 2.36.66 3.41a8.592 8.592 0 0 0 1.86 2.78c.8.8 1.75 1.43 2.84 1.89 1.1.46 2.27.69 3.54.69 1.39 0 2.64-.23 3.76-.69 1.11-.46 2.06-1.08 2.84-1.86a7.89 7.89 0 0 0 1.8-2.81c.42-1.1.63-2.27.63-3.54 0-2.65-.8-4.8-2.4-6.44-1.61-1.63-3.8-2.45-6.57-2.45z"
          className="st1"
        />
        <path
          id="path22"
          d="M300.25 115.82c0-2.4.43-4.58 1.29-6.54.86-1.96 2.03-3.63 3.5-5.02 1.47-1.39 3.22-2.46 5.24-3.22 2.02-.76 4.17-1.14 6.44-1.14 2.27 0 4.42.38 6.44 1.14 2.02.76 3.77 1.83 5.24 3.22 1.47 1.39 2.64 3.06 3.5 5.02.86 1.96 1.29 4.14 1.29 6.54 0 2.4-.43 4.58-1.29 6.54-.86 1.96-2.03 3.63-3.5 5.02-1.47 1.39-3.22 2.46-5.24 3.22-2.02.76-4.17 1.14-6.44 1.14-2.27 0-4.42-.38-6.44-1.14-2.02-.76-3.77-1.83-5.24-3.22-1.47-1.39-2.64-3.06-3.5-5.02-.86-1.96-1.29-4.14-1.29-6.54zm7.58 0c0 1.18.2 2.32.6 3.41.4 1.1.99 2.06 1.77 2.9a8.71 8.71 0 0 0 2.81 2.02c1.09.51 2.34.76 3.72.76 1.39 0 2.63-.25 3.73-.76a8.82 8.82 0 0 0 2.81-2.02c.78-.84 1.37-1.81 1.77-2.9.4-1.09.6-2.23.6-3.41 0-1.18-.2-2.31-.6-3.41-.4-1.1-.99-2.06-1.77-2.9a8.82 8.82 0 0 0-2.81-2.02c-1.1-.51-2.34-.76-3.73-.76-1.39 0-2.63.25-3.72.76a8.71 8.71 0 0 0-2.81 2.02c-.78.84-1.37 1.81-1.77 2.9-.4 1.1-.6 2.23-.6 3.41z"
          className="st1"
        />
        <path
          id="path24"
          d="M352.34 100.67h7.2v4.73h.13c.67-1.43 1.76-2.7 3.25-3.82 1.49-1.11 3.5-1.67 6.03-1.67 4.76 0 7.91 1.87 9.47 5.62 1.09-1.94 2.46-3.36 4.1-4.26 1.64-.9 3.6-1.36 5.87-1.36 2.02 0 3.72.34 5.11 1.01 1.39.67 2.51 1.6 3.38 2.78.87 1.18 1.48 2.56 1.86 4.14.38 1.58.57 3.27.57 5.08v18.06h-7.58V113.8c0-.92-.08-1.82-.25-2.68-.17-.86-.46-1.62-.88-2.27-.42-.65-.99-1.17-1.71-1.55-.72-.38-1.64-.57-2.78-.57-1.18 0-2.18.22-3 .66-.82.44-1.49 1.03-2.02 1.77s-.9 1.58-1.14 2.53c-.23.95-.35 1.9-.35 2.87v16.42h-7.58v-18.06c0-1.89-.4-3.4-1.2-4.51-.8-1.11-2.12-1.67-3.98-1.67-1.26 0-2.33.21-3.19.63-.86.42-1.58.99-2.15 1.71-.57.72-.98 1.54-1.23 2.46-.25.93-.38 1.89-.38 2.9v16.54h-7.58v-30.31z"
          className="st1"
        />
        <path
          id="path26"
          d="M407.58 100.67h7.58v4.8h.13c.84-1.77 2.04-3.14 3.6-4.1 1.56-.97 3.35-1.45 5.37-1.45.46 0 .92.04 1.36.13.44.08.89.19 1.36.32v7.32c-.63-.17-1.25-.31-1.86-.41-.61-.11-1.21-.16-1.8-.16-1.77 0-3.19.33-4.26.98-1.07.65-1.89 1.4-2.46 2.24-.57.84-.95 1.68-1.14 2.53-.19.84-.28 1.47-.28 1.89v16.23h-7.58v-30.32z"
          className="st1"
        />
        <path
          id="path28"
          d="M431.2 90.38c0-1.22.45-2.28 1.36-3.19.91-.91 2.05-1.36 3.44-1.36 1.39 0 2.56.43 3.5 1.29.95.86 1.42 1.95 1.42 3.25s-.47 2.39-1.42 3.25c-.95.86-2.12 1.29-3.5 1.29-1.39 0-2.54-.45-3.44-1.36-.91-.89-1.36-1.95-1.36-3.17zm1.07 10.29h7.58v30.31h-7.58z"
          className="st1"
        />
      </g>
    </g>
  </svg>
);

export default LogoWithText;
