import React, { FC } from "react";
import AppBar from "../AppBar";
import Box from "../Box";
import Grid from "../Grid";
import { LogoutOutlinedIcon, LogoWithText } from "../Icons";
import Toolbar from "../Toolbar";
import IconButton from "../IconButton";

export type NavbarProps = {
  onLogOutClick?: () => void | Promise<void>;
};

const Navbar: FC<NavbarProps> = ({ onLogOutClick }) => {
  return (
    <AppBar position="static" color="inherit">
      <Box px={6} py={4}>
        <Toolbar disableGutters variant="dense">
          <Grid
            container
            justifyContent="space-between"
            direction="row"
            wrap="nowrap"
          >
            <Grid item>
              <LogoWithText />
            </Grid>
            <Grid
              item
              flexGrow={1}
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              {onLogOutClick && (
                <Grid item>
                  <IconButton
                    aria-label="log out"
                    size="small"
                    color="primary"
                    onClick={onLogOutClick}
                  >
                    <LogoutOutlinedIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default Navbar;
