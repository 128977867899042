import { AuthConfirmUserFormProps } from "../AuthConfirmUserForm";
import { FormikConfigResult } from "./types";
import useResendCode from "./useResendCode";
import useTranslations from "./useTranslations";

const useAuthConfirmUserForm = (params: {
  formik: FormikConfigResult;
  phoneNumber: string;
  email: string;
  errorMessage: string | null;
  handleError: (msg: string | null) => void;
}): AuthConfirmUserFormProps => {
  const { formik, email, phoneNumber, errorMessage, handleError } = params;

  const { placeholderCode, helperCodeValidFor } = useTranslations();

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = formik;

  const codeFieldProps: AuthConfirmUserFormProps["codeFieldProps"] = {
    value: values.code,
    onChange: handleChange,
    onBlur: handleBlur,
    name: "code",
    type: "tel",
    disabled: isSubmitting,
    required: true,
    error: touched.code && Boolean(errors.code),
    helperText: touched.code && errors.code,
    placeholder: placeholderCode,
  };

  const alert: AuthConfirmUserFormProps["alert"] = errorMessage
    ? {
        props: {
          severity: "error",
        },
        children: errorMessage,
      }
    : {
        props: {
          severity: "info",
        },
        children: helperCodeValidFor,
      };

  const { isLoading: isResendLoading, handleResend } = useResendCode({
    email,
    handleError,
  });

  return {
    phoneNumber,
    codeFieldProps,
    handleSubmit,
    handleResend,
    alert,
    isResendDisabled: isSubmitting || isResendLoading,
    isSubmitDisabled: isSubmitting || isResendLoading,
    isSubmitLoading: isSubmitting,
  };
};

export default useAuthConfirmUserForm;
