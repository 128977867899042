import React, { FC, ReactNode } from "react";
import Alert, { AlertProps } from "../../Unknown/Alert";
import Box from "../../Unknown/Box";
import Button from "../../Unknown/Button";
import CircularProgress from "../../Unknown/CircularProgress";
import Grid from "../../Unknown/Grid";
import TextField from "../../Unknown/TextField";
import Typography from "../../Unknown/Typography";
import { CodeFieldProps } from "./types";
import useTranslations from "./useTranslations";

export type AuthConfirmUserFormProps = {
  phoneNumber: string;
  codeFieldProps: CodeFieldProps;
  handleResend: () => void | Promise<void>;
  handleSubmit: () => void | Promise<void>;
  alert?: {
    props: AlertProps;
    children: ReactNode | string;
  };
  isResendDisabled?: boolean;
  isSubmitDisabled?: boolean;
  isSubmitLoading?: boolean;
};

const AuthConfirmUserForm: FC<AuthConfirmUserFormProps> = ({
  phoneNumber,
  codeFieldProps,
  handleResend,
  handleSubmit,
  alert,
  isResendDisabled,
  isSubmitDisabled,
  isSubmitLoading,
}) => {
  const { btnSubmit, btnResendCode, helperCodeSent } = useTranslations({
    phoneNumber,
  });

  return (
    <Grid container direction="column" wrap="nowrap" spacing={3}>
      <Grid item>
        <Typography variant="body2">{helperCodeSent}</Typography>
      </Grid>
      {alert && (
        <Grid item mb={6}>
          <Alert {...alert.props}>{alert.children}</Alert>
        </Grid>
      )}
      <Grid item>
        <TextField {...codeFieldProps} fullWidth />
      </Grid>
      <Grid item>
        <Button
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
          fullWidth
          variant="contained"
          size="large"
          startIcon={isSubmitLoading ? <CircularProgress size={20} /> : null}
        >
          {btnSubmit}
        </Button>
        <Box mt={3} textAlign="center">
          <Typography
            variant="body2"
            sx={{ cursor: isResendDisabled ? "default" : "pointer" }}
            color={isResendDisabled ? "grey.500" : "primary.main"}
            onClick={isResendDisabled ? undefined : handleResend}
          >
            {btnResendCode}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AuthConfirmUserForm;
