import React, { FC, useMemo } from "react";
import { useIntl } from "react-intl";

import { getFormattedHeight } from "../../../common/getFormattedHeight";
import { formatInternational } from "../../../common/phoneNumberHelpers";
import Typography from "../../Unknown/Typography";
import Alert from "../../Unknown/Alert";
import AlertTitle from "../../Unknown/AlertTitle";
import Grid from "../../Unknown/Grid";
import Box from "../../Unknown/Box";
import Paper from "../../Unknown/Paper";
import Button from "../../Unknown/Button";
import CircularProgress from "../../Unknown/CircularProgress";
import {
  sexTranslationKeys,
  sexTranslations,
} from "../CustomerPortalAppPatientProvideInformationForm/useSexOptions";
import {
  raceTranslationKeys,
  raceTranslations,
} from "../CustomerPortalAppPatientProvideInformationForm/useRaceOptions";
import useTranslations from "./translations";
import useStyles from "./styles";

interface ContactInformation {
  contactEmail?: string;
  contactPhone?: string;
  addressPrimary?: string;
  addressSecondary?: string;
  addressCity?: string;
  addressState?: string;
  addressPostalCode?: string;
  addressCountry?: string;
}

interface PatientInformation {
  race?: string;
  sex?: string;
  heightFt?: string | number;
  heightIn?: string | number;
  weightLbs?: string | number;
}

interface MedicalInformation {
  medicalReason?: string;
  medicalSurgicalHistory?: string;
  medicalCancerHistory?: string;
}

export type PatientConfirmInformationDialogProps = {
  contactInformation: ContactInformation;
  patientInformation?: PatientInformation;
  medicalInformation?: MedicalInformation;
  errorMessage?: string | null;
  isLoading?: boolean;
  handleSubmit: () => void;
  handleClose: () => void;
};

const PatientConfirmInformationDialog: FC<
  PatientConfirmInformationDialogProps
> = ({
  patientInformation,
  contactInformation,
  medicalInformation,
  errorMessage,
  isLoading,
  handleClose,
  handleSubmit,
}) => {
  const { translations } = useTranslations();
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const {
    contactEmail,
    contactPhone,
    addressCity,
    addressCountry,
    addressPostalCode,
    addressPrimary,
    addressSecondary,
    addressState,
  } = contactInformation;

  const stateAddress = [addressCity, addressPostalCode, addressState]
    .filter(Boolean)
    .join(" ");

  const formattedAddress = [
    addressPrimary,
    addressSecondary,
    stateAddress,
    addressCountry,
  ]
    .filter(Boolean)
    .join(", ");

  const actionButtons = useMemo(() => {
    return [
      {
        text: translations.editInformation,
        key: "edit",
        onClick: handleClose,
        disabled: isLoading,
      },
      {
        text: translations.confirm,
        key: "submit",
        onClick: handleSubmit,
        disabled: isLoading || !!errorMessage,
        startIcon: isLoading ? (
          <CircularProgress size={20} color="inherit" />
        ) : undefined,
      },
    ];
  }, [
    translations.editInformation,
    translations.confirm,
    isLoading,
    errorMessage,
    handleClose,
    handleSubmit,
  ]);

  const formattedSexValue = useMemo(() => {
    if (!patientInformation?.sex) {
      return "";
    }

    const translationKey =
      sexTranslationKeys[
        patientInformation.sex as keyof typeof sexTranslationKeys
      ];
    const translation = sexTranslations[translationKey];

    return translation ? formatMessage(translation) : "";
  }, [formatMessage, patientInformation?.sex]);

  const formattedRaceValue = useMemo(() => {
    if (!patientInformation?.race) {
      return "";
    }

    const translationKey =
      raceTranslationKeys[
        patientInformation.race as keyof typeof raceTranslationKeys
      ];

    const translation = raceTranslations[translationKey];

    return translation ? formatMessage(translation) : "";
  }, [formatMessage, patientInformation?.race]);

  const formattedAntro = useMemo(() => {
    if (!patientInformation) return "";

    const { heightFt, heightIn, weightLbs } = patientInformation;

    const formattedWeight =
      typeof weightLbs === "number" ? `${weightLbs} lbs` : null;
    const formattedHeight =
      heightFt || heightIn
        ? getFormattedHeight(heightFt ? +heightFt : 0, heightIn ? +heightIn : 0)
        : null;

    return [formattedHeight, formattedWeight].filter(Boolean).join(" — ");
  }, [patientInformation]);

  return (
    <Paper>
      <Alert severity="info">
        <AlertTitle>{translations.title}</AlertTitle>
        <Typography whiteSpace="pre-wrap" variant="body2">
          {translations.warningMessage}
        </Typography>
      </Alert>
      <Box display="flex" flexDirection="column" gap={7} mt={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography className={classes.labelRoot}>
              {translations.labelEmail}
            </Typography>
            <Typography
              variant="body2"
              title={contactEmail}
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {contactEmail}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={classes.labelRoot}>
              {translations.labelPhone}
            </Typography>
            <Typography variant="body2">
              {!!contactPhone &&
                formatInternational({ phoneNumber: contactPhone })}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={classes.labelRoot}>
              {translations.labelAddress}
            </Typography>
            <Typography variant="body2">{formattedAddress}</Typography>
          </Grid>
        </Grid>

        {patientInformation && (
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography className={classes.labelRoot}>
                {translations.labelAntro}
              </Typography>
              <Typography variant="body2">{formattedAntro}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={classes.labelRoot}>
                {translations.labelSex}
              </Typography>
              <Typography variant="body2">{formattedSexValue}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={classes.labelRoot}>
                {translations.labelRace}
              </Typography>
              <Typography variant="body2">{formattedRaceValue}</Typography>
            </Grid>
          </Grid>
        )}

        {medicalInformation && (
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography className={classes.labelRoot}>
                {translations.labelReason}
              </Typography>
              <Typography variant="body2">
                {medicalInformation.medicalReason}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={classes.labelRoot}>
                {translations.labelSurgicalHistory}
              </Typography>
              <Typography variant="body2">
                {medicalInformation.medicalSurgicalHistory}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={classes.labelRoot}>
                {translations.labelCancerHistory}
              </Typography>
              <Typography variant="body2">
                {medicalInformation.medicalCancerHistory}
              </Typography>
            </Grid>
          </Grid>
        )}

        <Box display="flex" justifyContent="flex-end">
          {actionButtons.map(({ text, key, ...props }) => (
            <Button size="large" {...props} key={key}>
              {text}
            </Button>
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

export default PatientConfirmInformationDialog;
