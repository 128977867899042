import React, { FC } from "react";
import AppointmentListContainer from "../AppointmentListContainer";

const AppointmentListScreen: FC = () => {
  return (
    <>
      <AppointmentListContainer />
    </>
  );
};

export default AppointmentListScreen;
