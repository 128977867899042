import { useCallback, useState } from "react";
import { useEffectOnce } from "react-use";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import { useAuthContext } from "../../Auth/AuthContextProvider/useAuthContext";
import { useUIContext } from "../../Unknown/UIContext";
import getAppointments from "./getAppointments";
import { Appointment } from "./types";
import { appointmentListIgnoreStatuses } from "./constants";
import checkIsCheckInApp from "../../../common/checkIsCheckInApp";

const useAppointments = () => {
  const { userData } = useAuthContext();
  const { setAlert } = useUIContext();
  const { subdomain } = useUIContext();
  const isCheckInApp = checkIsCheckInApp(subdomain);

  const [isLoading, setIsLoading] = useState(false);
  const [appointments, setAppointments] = useState<Appointment[] | null>(null);

  const { runAsyncFunction } = useDefaultErrorWrapper();

  const refetch = useCallback(async () => {
    if (!userData) return;

    try {
      setIsLoading(true);
      const data = await runAsyncFunction(getAppointments, {
        customerId: userData.id,
        ignoreStatuses: appointmentListIgnoreStatuses,
        isCheckInApp,
      });

      setAppointments(data);
    } catch (error) {
      setAppointments(null);
      setAlert({
        isShown: true,
        severity: "error",
        message: (error as Error).message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [runAsyncFunction, setAlert, userData, isCheckInApp]);

  useEffectOnce(() => {
    refetch();
  });

  return {
    isLoading,
    appointments,
    refetch,
  };
};

export default useAppointments;
