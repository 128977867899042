import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";
import { AppointmentScreeningFormTranslations } from "./types";

const useTranslations = (): AppointmentScreeningFormTranslations => {
  const { formatMessage } = useIntl();

  return useMemo(() => {
    return {
      title: formatMessage(messages.title),
      dateTitle: formatMessage(messages.dateTitle),
      headerNameTitle: formatMessage(messages.headerNameTitle),
      headerDateOfBirthTitle: formatMessage(messages.headerDateOfBirthTitle),
      headerPatientIdTitle: formatMessage(messages.headerPatientIdTitle),
      saveButtonTitle: formatMessage(messages.saveButtonTitle),
      cancelButtonTitle: formatMessage(messages.cancelButtonTitle),
      patientSignatureTitle: formatMessage(messages.patientSignatureTitle),
      patientUnderstandingText: formatMessage(
        messages.patientUnderstandingText,
      ),
      metalicElectronicItemsTitle: formatMessage(
        messages.metalicElectronicItemsTitle,
      ),
      metalicElectronicItemsFirstParagraph: formatMessage(
        messages.metalicElectronicItemsFirstParagraph,
      ),
      metalicElectronicItemsSndParagraph: formatMessage(
        messages.metalicElectronicItemsSndParagraph,
      ),
      metalicElectronicItemsThirdParagraph: formatMessage(
        messages.metalicElectronicItemsThirdParagraph,
      ),
      metalicElectronicItemsFourthParagraph: formatMessage(
        messages.metalicElectronicItemsFourthParagraph,
      ),
      cardiacPacemaker: formatMessage(messages.cardiacPacemakerText),
      implantedCardiacDefibrillator: formatMessage(
        messages.implantedCardiacDefibrillatorText,
      ),
      aneurysmClip: formatMessage(messages.aneurysmClipText),
      cochlear: formatMessage(messages.cochlearText),
      hearingAid: formatMessage(messages.hearingAidText),
      softTissueExpander: formatMessage(messages.softTissueExpanderText),
      swanGanzCatheter: formatMessage(messages.swanGanzCatheterText),
      workedWithMetal: formatMessage(messages.workedWithMetalText),
      eyeProsthesis: formatMessage(messages.eyeProsthesisText),
      metallicFragmentOrForeignBody: formatMessage(
        messages.metallicFragmentOrForeignBodyText,
      ),
      heartValveProsthesis: formatMessage(messages.heartValveProsthesisText),
      electronicImplant: formatMessage(messages.electronicImplantText),
      neurostimulation: formatMessage(messages.neurostimulationText),
      boneGrowthStimulator: formatMessage(messages.boneGrowthStimulatorText),
      magneticallyActivatedImplant: formatMessage(
        messages.magneticallyActivatedImplantText,
      ),
      magneticStentCoilFilter: formatMessage(
        messages.magneticStentCoilFilterText,
      ),
      wireMeshImplant: formatMessage(messages.wireMeshImplantText),
      surgicalStaplesClipsMetallicSutures: formatMessage(
        messages.surgicalStaplesClipsMetallicSuturesText,
      ),
      anyOtherProsthesis: formatMessage(messages.anyOtherProsthesisText),
      jointReplacement: formatMessage(messages.jointReplacementText),
      boneJoint: formatMessage(messages.boneJointText),
      iudDiaphragmPessary: formatMessage(messages.iudDiaphragmPessaryText),
      medicinePatches: formatMessage(messages.medicinePatchesText),
      shunt: formatMessage(messages.shuntText),
      infusionPump: formatMessage(messages.infusionPumpText),
      dentalImplants: formatMessage(messages.dentalImplantsText),
      bodyPiercingJewelry: formatMessage(messages.bodyPiercingJewelryText),
      tattoosOrPermanentMakeup: formatMessage(
        messages.tattoosOrPermanentMakeupText,
      ),
      anyOtherDevice: formatMessage(messages.anyOtherDeviceText),
      metalicElectronicItemsFifthParagraph: formatMessage(
        messages.metalicElectronicItemsFifthParagraph,
      ),
      doNotHaveAnyItemTitle: formatMessage(messages.doNotHaveAnyItemTitle),
      itemRemovalTitle: formatMessage(messages.itemRemovalTitle),
      itemRemovalDescription: formatMessage(messages.itemRemovalDescription),
      itemRemovalFirstRecomendationTitle: formatMessage(
        messages.itemRemovalFirstRecomendationTitle,
      ),
      itemRemovalSndRecomendationTitle: formatMessage(
        messages.itemRemovalSndRecomendationTitle,
      ),
      itemRemovalThirdRecomendationTitle: formatMessage(
        messages.itemRemovalThirdRecomendationTitle,
      ),
      itemRemovalFourthRecomendationTitle: formatMessage(
        messages.itemRemovalFourthRecomendationTitle,
      ),
      itemRemovalFifthRecomendationTitle: formatMessage(
        messages.itemRemovalFifthRecomendationTitle,
      ),
    };
  }, [formatMessage]);
};

export default useTranslations;
