/* eslint-disable @typescript-eslint/no-explicit-any */
import supabaseClient from "../../../common/supabaseClient";
import { supabaseRequest } from "./constants";
import { Appointment } from "./types";

const getAppointment = async (params: { appointmentId: string }) => {
  const { appointmentId } = params;

  const query = supabaseClient
    .from<Appointment>("appointments")
    .select(supabaseRequest)
    .eq("id", appointmentId)
    .limit(1);

  const { data, error } = await query;

  if (error) {
    throw new Error(error.message);
  }

  return data[0];
};

export default getAppointment;
