import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      titleFiles: formatMessage(messages.titleFiles),
      helper: formatMessage(messages.helper),
    };
  }, [formatMessage]);

  return translations;
};

export default useTranslations;
