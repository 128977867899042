import { useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { useUIContext } from "../../Unknown/UIContext";
import { FormConfig, Patient } from "./types";
import useValidationSchema from "./useValidationSchema";
import { useFormContext } from "../../Unknown/FormContext";
import useTranslations from "./useTranslations";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import { useAuthContext } from "../../Auth/AuthContextProvider/useAuthContext";
import checkIsAppointmentCheckedIn from "../../../common/checkIsAppointmentCheckedIn";

const useFormikComponent = (
  patient: Patient | null,
  appointmentId?: string,
) => {
  const navigate = useNavigate();
  const { patientConsentFormSuccessMessage } = useTranslations();

  const { setAlert } = useUIContext();

  const { signOut } = useAuthContext();

  const { setFormAlert } = useFormContext();
  const provideCheckInAppPatientConsentForm = useFirebaseAppFunction(
    "provideCheckInAppPatientConsentForm",
  );

  const initialValues = useMemo<FormConfig["initialValues"]>(() => {
    return {
      patientSignature: false,
      pregnancyConfirmation: null,
    };
  }, []);

  const validationSchema = useValidationSchema();
  const { runAsyncFunction } = useDefaultErrorWrapper();

  const onSubmit = useCallback<FormConfig["onSubmit"]>(
    async (values) => {
      if (!patient || !appointmentId) return;

      try {
        await runAsyncFunction(provideCheckInAppPatientConsentForm, {
          patientId: patient.id,
          isPregnancyConfirmed: values.pregnancyConfirmation === "yes",
        });

        const isAppointmentCheckedIn = await checkIsAppointmentCheckedIn(
          appointmentId,
        );

        if (isAppointmentCheckedIn) {
          signOut();
          navigate("/checked-in");
          return;
        }

        setFormAlert({
          isShown: true,
          severity: "success",
          message: patientConsentFormSuccessMessage,
        });

        navigate("/");
      } catch (error) {
        setAlert({
          isShown: true,
          severity: "error",
          message: (error as Error).message,
        });
      }
    },
    [
      patient,
      setAlert,
      navigate,
      setFormAlert,
      patientConsentFormSuccessMessage,
      runAsyncFunction,
      provideCheckInAppPatientConsentForm,
      signOut,
      appointmentId,
    ],
  );

  return {
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  };
};

export default useFormikComponent;
