export const formNames = [
  "contactEmail",
  "contactPhone",
  "addressPrimary",
  "addressSecondary",
  "addressCity",
  "addressState",
  "addressPostalCode",
  "addressCountry",
] as const;

const getFormNames = () => {
  return formNames.reduce(
    (acc, formName) => ({ ...acc, [formName]: formName }),
    {} as Record<(typeof formNames)[number], string>,
  );
};

export default getFormNames;
