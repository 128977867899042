/* eslint-disable react/no-array-index-key */
import React, { FC, ReactNode } from "react";
import Typography from "../Typography";

interface SimpleListItem {
  title: ReactNode;
  sublistItems?: ReactNode[];
}
export interface SimpleListProps {
  listItems: SimpleListItem[];
}
const SimpleList: FC<SimpleListProps> = ({ listItems }) => {
  return (
    <Typography component="ul" pl={6}>
      {listItems.map((listItem, idx) => {
        return (
          <li key={`simple_list_item-${listItem.title}-${idx}`}>
            <Typography>{listItem.title}</Typography>
            {!!listItem.sublistItems?.length && (
              <Typography component="ul" pl={6}>
                {listItem.sublistItems.map((sublistItem, i) => {
                  return (
                    <li key={`simple_list_item-${sublistItem}-${i}`}>
                      <Typography>{sublistItem}</Typography>
                    </li>
                  );
                })}
              </Typography>
            )}
          </li>
        );
      })}
    </Typography>
  );
};

export default SimpleList;
