import { ChangeCustomerPortalPatientInformationParams } from "@Shape-Digital/kudzu-data/lib/types/actions";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { useUIContext } from "../../Unknown/UIContext";
import { Appointment, FormConfig } from "./types";
import useTranslations from "./useTranslations";
import useValidationSchema from "./useValidationSchema";
import { useFormContext } from "../../Unknown/FormContext";
import { getValidPhoneNumber } from "../../../common/phoneNumberHelpers";
import { DEFAULT_COUNTRY_CODE } from "../../../common/constants";

const useFormConfig = (params: {
  appointment: Appointment | null;
}): FormConfig => {
  const { appointment } = params;

  const navigate = useNavigate();

  const { defaultError, patientInformationSuccessSaved, errorEmailIsInUse } =
    useTranslations();

  const { runAsyncFunction } = useDefaultErrorWrapper();

  const { setAlert } = useUIContext();

  const { setFormAlert } = useFormContext();

  const changeCustomerPortalPatientInformation = useFirebaseAppFunction(
    "changeCustomerPortalPatientInformation",
  );

  const initialValues = useMemo<FormConfig["initialValues"]>(() => {
    const { appointment_patient_details } = appointment || {};
    const patientDetails = appointment_patient_details?.[0];

    const { email, mobile_phone_number, patient } = patientDetails || {};

    const { city, state, postal_code, country } = patient || {};

    const addressPrimary = patientDetails?.patient.patient_addresses.find(
      (addr) => addr.type === "primary",
    )?.address;

    const addressSecondary = patientDetails?.patient.patient_addresses.find(
      (addr) => addr.type === "secondary",
    )?.address;

    return {
      contactEmail: email || "",
      contactPhone: mobile_phone_number || "",
      addressPrimary: addressPrimary || "",
      addressSecondary: addressSecondary || "",
      addressCity: city || "",
      addressState: state || "",
      addressPostalCode: postal_code || "",
      addressCountry: country || DEFAULT_COUNTRY_CODE,
    };
  }, [appointment]);

  const validationSchema = useValidationSchema();

  const onSubmit = useCallback<FormConfig["onSubmit"]>(
    async (values) => {
      if (!appointment) return;

      const {
        contactEmail,
        contactPhone,
        addressPrimary,
        addressSecondary,
        addressCity,
        addressState,
        addressPostalCode,
        addressCountry,
      } = values;

      if (
        !contactEmail ||
        !contactPhone ||
        !addressPrimary ||
        !addressCity ||
        !addressState ||
        !addressPostalCode ||
        !addressCountry
      ) {
        throw new Error(defaultError);
      }

      try {
        const args: ChangeCustomerPortalPatientInformationParams = {
          appointmentId: appointment.id,
          contactEmail,
          contactPhone: getValidPhoneNumber(contactPhone),
          addressPrimary,
          addressSecondary,
          addressCity,
          addressState,
          addressPostalCode,
          addressCountry,
        };
        const { data } = await runAsyncFunction(
          changeCustomerPortalPatientInformation,
          args,
        );

        if (data.status === "error") {
          setAlert({
            isShown: true,
            severity: "error",
            message: errorEmailIsInUse,
          });
          return;
        }

        setFormAlert({
          isShown: true,
          severity: "success",
          message: patientInformationSuccessSaved,
        });

        navigate("/");
      } catch (error) {
        setAlert({
          isShown: true,
          severity: "error",
          message: (error as Error).message,
        });
      }
    },
    [
      appointment,
      defaultError,
      runAsyncFunction,
      changeCustomerPortalPatientInformation,
      setFormAlert,
      patientInformationSuccessSaved,
      navigate,
      setAlert,
      errorEmailIsInUse,
    ],
  );

  return {
    enableReinitialize: true,
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit,
  };
};

export default useFormConfig;
