import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useCallback } from "react";
import { useStorage } from "reactfire";

const useFirebaseStorageUploader = () => {
  const storage = useStorage();

  const fileUploader = useCallback(
    async (params: { path: string; file: File }) => {
      const { path, file } = params;

      const filename = `${Date.now()}_${file.name}`;

      const fileRef = ref(storage, `${path}/${filename}`);

      const snapshot = await uploadBytes(fileRef, file);

      const downloadUrl = await getDownloadURL(fileRef);

      return {
        snapshot,
        downloadUrl,
      };
    },
    [storage],
  );

  return fileUploader;
};

export default useFirebaseStorageUploader;
