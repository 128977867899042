import React, { FC, useCallback, useEffect, useMemo } from "react";
import { format, parseISO } from "date-fns";
import { useFormikContext } from "formik";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import PatientFormHeader from "../../Unknown/PatientFormHeader";
import SimpleCheckbox from "../../Unknown/SimpleCheckbox";
import Button from "../../Unknown/Button";
import CircularProgress from "../../Unknown/CircularProgress";
import useTranslations from "./useTranslations";
import {
  FormValues,
  ScreeningFormAppointment,
} from "../CustomerPortalAppAppointmentScreeningFormContainer/types";
import { getNowUTC } from "../../../common/dateHelpers";
import { useFormContext } from "../../Unknown/FormContext";
import { AppointmentScreeningFormTranslations } from "./types";
import messages from "./messages";

interface PatientPrivacyFormContainerProps {
  appointment: ScreeningFormAppointment;
}

const appointmentScreeningFormSensitiveItems: Array<
  keyof AppointmentScreeningFormTranslations
> = [
  "cardiacPacemaker",
  "implantedCardiacDefibrillator",
  "aneurysmClip",
  "cochlear",
  "hearingAid",
  "softTissueExpander",
  "swanGanzCatheter",
  "workedWithMetal",
  "eyeProsthesis",
  "metallicFragmentOrForeignBody",
  "heartValveProsthesis",
  "electronicImplant",
  "neurostimulation",
  "boneGrowthStimulator",
  "magneticallyActivatedImplant",
  "magneticStentCoilFilter",
  "wireMeshImplant",
  "surgicalStaplesClipsMetallicSutures",
  "anyOtherProsthesis",
  "jointReplacement",
  "boneJoint",
  "iudDiaphragmPessary",
  "medicinePatches",
  "shunt",
  "infusionPump",
  "dentalImplants",
  "bodyPiercingJewelry",
  "tattoosOrPermanentMakeup",
  "anyOtherDevice",
];

const AppointmentScreeningForm: FC<PatientPrivacyFormContainerProps> = ({
  appointment,
}) => {
  const navigate = useNavigate();
  const translations = useTranslations();
  const { setFormAppointmentId } = useFormContext();
  const { values, isSubmitting, setFieldValue, handleChange, handleSubmit } =
    useFormikContext<FormValues>();
  const { formatMessage } = useIntl();

  const { appointment_patient_details: appointmentPatientDetails } =
    appointment;

  const {
    first_name: firstName,
    last_name: lastName,
    patient,
    date_birth: dateBirth,
  } = appointmentPatientDetails[0] || {};
  const patientName = `${firstName} ${lastName}`;

  const saveButtonDisabled =
    isSubmitting ||
    !values.patientSignature ||
    (!values.doNotHaveAnyItem && !values.sensitiveItems?.length);

  const handleCancel = () => {
    setFormAppointmentId(null);
    navigate("/");
  };

  const headerItems = useMemo(
    () => [
      {
        title: translations.headerNameTitle,
        value: patientName,
      },
      {
        title: translations.headerDateOfBirthTitle,
        value: format(parseISO(dateBirth), "M/d/yyyy"),
      },
      {
        title: translations.headerPatientIdTitle,
        value: patient.mrn as string,
      },
    ],
    [translations, patientName, dateBirth, patient],
  );

  const handleSensitiveItemsCheck = useCallback(
    (sensitiveItem: string) => {
      if (values.sensitiveItems?.includes(sensitiveItem)) {
        setFieldValue(
          "sensitiveItems",
          values.sensitiveItems?.filter((value) => value !== sensitiveItem),
        );
        return;
      }
      setFieldValue("sensitiveItems", [
        ...(values.sensitiveItems || []),
        sensitiveItem,
      ]);
      setFieldValue("doNotHaveAnyItem", false);
    },
    [values.sensitiveItems, setFieldValue],
  );
  useEffect(() => {
    if (values.doNotHaveAnyItem) {
      setFieldValue("sensitiveItems", null);
    }
  }, [values.doNotHaveAnyItem, setFieldValue]);

  const implantCardDescription = formatMessage(
    messages.implantCardDescription,
    {
      patientInformationForm: (
        <b>{formatMessage(messages.patientInformationFormText)}</b>
      ),
    },
  );

  return (
    <Grid container flexDirection="column" sx={{ wordBreak: "break-word" }}>
      <Grid>
        <PatientFormHeader
          headerTitle={translations.title}
          headerItems={headerItems}
          type="screening"
        />
      </Grid>

      <Grid container pt={6} gap={3.2}>
        <Typography variant="h6">
          {translations.metalicElectronicItemsTitle}
        </Typography>

        <Typography>
          {translations.metalicElectronicItemsFirstParagraph}
        </Typography>

        <Typography>
          {translations.metalicElectronicItemsSndParagraph}
        </Typography>

        <Typography>
          {translations.metalicElectronicItemsThirdParagraph}
        </Typography>

        <Typography>
          {translations.metalicElectronicItemsFourthParagraph}
        </Typography>

        <Grid container flexDirection="column">
          {appointmentScreeningFormSensitiveItems.map((sensitiveItem) => {
            return (
              <SimpleCheckbox
                key={sensitiveItem}
                name={sensitiveItem}
                onChange={() => handleSensitiveItemsCheck(sensitiveItem)}
                checked={!!values.sensitiveItems?.includes(sensitiveItem)}
                label={translations[sensitiveItem]}
              />
            );
          })}
        </Grid>

        <Typography>
          {translations.metalicElectronicItemsFifthParagraph}
        </Typography>

        <SimpleCheckbox
          name="doNotHaveAnyItem"
          onChange={handleChange}
          checked={values.doNotHaveAnyItem}
          label={translations.doNotHaveAnyItemTitle}
        />
      </Grid>

      <Grid container pt={6} gap={3.2} flexDirection="column">
        <Typography variant="h6">
          {formatMessage(messages.implantCardTitle)}
        </Typography>

        <Typography>{implantCardDescription}</Typography>
      </Grid>

      <Grid container pt={6} gap={3.2} flexDirection="column">
        <Typography variant="h6">{translations.itemRemovalTitle}</Typography>

        <Typography>{translations.itemRemovalDescription}</Typography>

        <Typography component="ol" pl={4}>
          <li>
            <Typography>
              {translations.itemRemovalFirstRecomendationTitle}
            </Typography>
          </li>
          <li>
            <Typography>
              {translations.itemRemovalSndRecomendationTitle}
            </Typography>
          </li>
          <li>
            <Typography>
              {translations.itemRemovalThirdRecomendationTitle}
            </Typography>
          </li>
          <li>
            <Typography>
              {translations.itemRemovalFourthRecomendationTitle}
            </Typography>
          </li>
          <li>
            <Typography>
              {translations.itemRemovalFifthRecomendationTitle}
            </Typography>
          </li>
        </Typography>
      </Grid>

      <Grid container flexDirection="column" pt={6} gap={3.2}>
        <Typography variant="h6">
          {translations.patientSignatureTitle}
        </Typography>

        <Typography>
          {`${translations.dateTitle}: ${getNowUTC().toLocaleDateString()}`}
        </Typography>

        <Grid container flexDirection="column">
          <SimpleCheckbox
            name="patientSignature"
            onChange={handleChange}
            checked={values.patientSignature}
            label={`${patientName} *`}
          />

          <Typography
            pl={6.6}
            fontSize={12}
            lineHeight="19.92px"
            letterSpacing="0.4px"
          >
            {translations.patientUnderstandingText}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        justifyContent="flex-end"
        pt={6}
        gap={1.6}
      >
        <Grid item>
          <Button
            variant="text"
            size="large"
            disabled={isSubmitting}
            onClick={handleCancel}
          >
            {translations.cancelButtonTitle}
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant="text"
            size="large"
            disabled={saveButtonDisabled}
            onClick={() => handleSubmit()}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          >
            {translations.saveButtonTitle}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AppointmentScreeningForm;
