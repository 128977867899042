import { defineMessages } from "react-intl";

export default defineMessages({
  btnSubmit: {
    id: "Auth.AuthConfirmUserForm.btnSubmit",
    defaultMessage: "Log in",
  },
  btnResendCode: {
    id: "Auth.AuthConfirmUserForm.btnResendCode",
    defaultMessage: "Resend code",
  },
  helperCodeSent: {
    id: "Auth.AuthConfirmUserForm.helperCodeSent",
    defaultMessage:
      "We've sent an text message to <b>{phoneNumber}</b> with a code to confirm your login.",
  },
});
