import React, { FC, ReactNode } from "react";
import Grid from "../../Unknown/Grid";
import AppointmentPreview from "../AppointmentPreview";
import { Appointment } from "./types";

export type AppointmentListProps = {
  appointments: Array<{
    data: Appointment;
    children?: ReactNode;
  }>;
};

const AppointmentList: FC<AppointmentListProps> = ({ appointments }) => {
  return (
    <Grid container direction="column" wrap="nowrap" rowSpacing={6}>
      {appointments.map(({ data, children }) => {
        const { id, ...appointment } = data;
        return (
          <Grid item key={id}>
            <AppointmentPreview {...appointment}>{children}</AppointmentPreview>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AppointmentList;
