import React, { FC } from "react";
import { useField } from "formik";
import TextField, { TextFieldProps } from "../TextField";

export type FormikTextFieldProps = { name: string } & TextFieldProps;

const FormikTextField: FC<FormikTextFieldProps> = ({
  name,
  helperText,
  children,
  ...props
}) => {
  const [field, meta] = useField(name);
  const { touched, error } = meta;

  const innerHelperText = touched && Boolean(error) ? error : helperText;

  return (
    <TextField
      {...field}
      {...props}
      error={touched && Boolean(error)}
      helperText={innerHelperText}
    >
      {children}
    </TextField>
  );
};

export default FormikTextField;
