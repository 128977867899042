import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  return useMemo(() => {
    return {
      title: formatMessage(messages.title),
      dateTitle: formatMessage(messages.dateTitle),
      headerNameTitle: formatMessage(messages.headerNameTitle),
      headerDateOfBirthTitle: formatMessage(messages.headerDateOfBirthTitle),
      headerPatientIdTitle: formatMessage(messages.headerPatientIdTitle),
      patientUnderstandCheckboxLabel: formatMessage(
        messages.patientUnderstandCheckboxLabel,
      ),
      signatureCheckboxSuffix: formatMessage(messages.signatureCheckboxSuffix),
      signatureTitle: formatMessage(messages.signatureTitle),
      signatureCheckboxUnderstandText: formatMessage(
        messages.signatureCheckboxUnderstandText,
      ),
      saveButtonTitle: formatMessage(messages.saveButtonTitle),
      cancelButtonTitle: formatMessage(messages.cancelButtonTitle),
      mainDescriptionDefaultPart: formatMessage(
        messages.mainDescriptionDefaultPart,
      ),
      mainDescriptionBoldPart: formatMessage(messages.mainDescriptionBoldPart),
      yourRightsTitle: formatMessage(messages.yourRightsTitle),
      yourRightsFirstDesc: formatMessage(messages.yourRightsFirstDesc),
      yourRightsSndDesc: formatMessage(messages.yourRightsSndDesc),
      yourRightsFirstListItem: formatMessage(messages.yourRightsFirstListItem),
      complyWithLegalRequirements: formatMessage(
        messages.complyWithLegalRequirements,
      ),
      yourRightsFirstListItemSublistItem: formatMessage(
        messages.yourRightsFirstListItemSublistItem,
      ),
      yourRightsSndListItem: formatMessage(messages.yourRightsSndListItem),
      yourRightsThirdListItem: formatMessage(messages.yourRightsThirdListItem),
      yourRightsThirdListItemSublistItem: formatMessage(
        messages.yourRightsThirdListItemSublistItem,
      ),
      yourRightsFourthListItem: formatMessage(
        messages.yourRightsFourthListItem,
      ),
      yourRightsFourthListItemSublistItem: formatMessage(
        messages.yourRightsFourthListItemSublistItem,
      ),
      yourRightsFifthListItem: formatMessage(messages.yourRightsFifthListItem),
      yourRightsSixthListItem: formatMessage(messages.yourRightsSixthListItem),
      yourRightsSixthListItemSublistItem: formatMessage(
        messages.yourRightsSixthListItemSublistItem,
      ),
      yourRightsSeventhListItem: formatMessage(
        messages.yourRightsSeventhListItem,
      ),
      yourRightsSeventhListItemSublistItemFirstPart: formatMessage(
        messages.yourRightsSeventhListItemSublistItemFirstPart,
      ),
      yourRightsSeventhListItemSublistItemSndPart: formatMessage(
        messages.yourRightsSeventhListItemSublistItemSndPart,
      ),
      yourRightsSeventhListItemSublistItemThirdPart: formatMessage(
        messages.yourRightsSeventhListItemSublistItemThirdPart,
      ),
      ourResponsibilities: formatMessage(messages.ourResponsibilities),
      ourResponsibilitiesFirstListItem: formatMessage(
        messages.ourResponsibilitiesFirstListItem,
      ),
      ourResponsibilitiesSndListItem: formatMessage(
        messages.ourResponsibilitiesSndListItem,
      ),
      ourResponsibilitiesThirdListItem: formatMessage(
        messages.ourResponsibilitiesThirdListItem,
      ),
      ourResponsibilitiesFourthListItem: formatMessage(
        messages.ourResponsibilitiesFourthListItem,
      ),
      ourResponsibilitiesLinkPrefix: formatMessage(
        messages.ourResponsibilitiesLinkPrefix,
      ),
      ourUsersAndDisclosures: formatMessage(messages.ourUsersAndDisclosures),
      ourUsersAndDisclosuresFirstDesc: formatMessage(
        messages.ourUsersAndDisclosuresFirstDesc,
      ),
      ourUsersAndDisclosuresSndDesc: formatMessage(
        messages.ourUsersAndDisclosuresSndDesc,
      ),
      ourUsersAndDisclosuresThirdDesc: formatMessage(
        messages.ourUsersAndDisclosuresThirdDesc,
      ),
      ourUsersAndDisclosuresFourthDesc: formatMessage(
        messages.ourUsersAndDisclosuresFourthDesc,
      ),
      ourUsersAndDisclosuresFirstListItem: formatMessage(
        messages.ourUsersAndDisclosuresFirstListItem,
      ),
      ourUsersAndDisclosuresSndListItem: formatMessage(
        messages.ourUsersAndDisclosuresSndListItem,
      ),
      ourUsersAndDisclosuresThirdListItem: formatMessage(
        messages.ourUsersAndDisclosuresThirdListItem,
      ),
      ourUsersAndDisclosuresFourthListItem: formatMessage(
        messages.ourUsersAndDisclosuresFourthListItem,
      ),
      ourUsersAndDisclosuresFifthListItem: formatMessage(
        messages.ourUsersAndDisclosuresFifthListItem,
      ),
      ourUsersAndDisclosuresSixthListItem: formatMessage(
        messages.ourUsersAndDisclosuresSixthListItem,
      ),
      ourUsersAndDisclosuresSeventhListItem: formatMessage(
        messages.ourUsersAndDisclosuresSeventhListItem,
      ),
      changesToTheTermsTitle: formatMessage(messages.changesToTheTermsTitle),
      changesToTheTermsDesc: formatMessage(messages.changesToTheTermsDesc),
      designatedOfficerTitle: formatMessage(messages.designatedOfficerTitle),
      designatedOfficerFirstDescFirstPart: formatMessage(
        messages.designatedOfficerFirstDescFirstPart,
      ),
      designatedOfficerFirstDescSndPart: formatMessage(
        messages.designatedOfficerFirstDescSndPart,
      ),
      designatedOfficerSndDesc: formatMessage(
        messages.designatedOfficerSndDesc,
      ),
      designatedOfficerThirdDescFirstPart: formatMessage(
        messages.designatedOfficerThirdDescFirstPart,
      ),
      designatedOfficerThirdDescSndPart: formatMessage(
        messages.designatedOfficerThirdDescSndPart,
      ),
    };
  }, [formatMessage]);
};

export default useTranslations;
