import React, { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useFormikContext } from "formik";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import PatientFormHeader from "../../Unknown/PatientFormHeader";
import SimpleCheckbox from "../../Unknown/SimpleCheckbox";
import Button from "../../Unknown/Button";
import CircularProgress from "../../Unknown/CircularProgress";
import useTranslations from "./useTranslations";
import {
  Patient,
  FormValues,
} from "../CustomerPortalAppPatientConsentFormContainer/types";
import { getNowUTC } from "../../../common/dateHelpers";
import Radio from "../../Unknown/Radio";
import FormControlLabel from "../../Unknown/FormControlLabel";
import RadioGroup from "../../Unknown/RadioGroup";
import { formatInternational } from "../../../common/phoneNumberHelpers";

interface PatientPrivacyFormContainerProps {
  patient: Patient | null;
}

const PatientConsentForm: FC<PatientPrivacyFormContainerProps> = ({
  patient,
}) => {
  const navigate = useNavigate();
  const translations = useTranslations();
  const { values, isSubmitting, handleChange, handleSubmit } =
    useFormikContext<FormValues>();

  const handleCancel = () => {
    navigate("/");
  };

  const patientInitials = `${patient?.first_name} ${patient?.last_name}`;

  const saveButtonDisabled =
    isSubmitting ||
    !values.patientSignature ||
    values.pregnancyConfirmation === null;

  const headerItems = useMemo(
    () => [
      {
        title: translations.headerNameTitle,
        value: patientInitials,
      },
      {
        title: translations.headerAddressTitle,
        value: [
          patient?.patient_addresses
            ?.map((address) => address.address)
            .filter(Boolean),
          patient?.city,
          patient?.postal_code,
          patient?.state,
        ]
          .filter(Boolean)
          .join(", "),
      },
      { title: translations.headerEmailTitle, value: `${patient?.email}` },
      {
        title: translations.headerMobileNumberTitle,
        value: `${formatInternational({
          phoneNumber: patient?.mobile_phone_number,
        })}`,
      },
    ],
    [translations, patient, patientInitials],
  );

  return (
    <Grid
      container
      flexDirection="column"
      gap={6}
      sx={{ wordBreak: "break-word" }}
    >
      <PatientFormHeader
        headerTitle={translations.title}
        headerItems={headerItems}
        type="consent"
      />

      <Grid container gap={3.2} flexDirection="column">
        <Typography variant="h6">
          {translations.consentToImagingScanTitle}
        </Typography>

        <Typography>{translations.consentToImagingScanText}</Typography>
      </Grid>

      <Grid container gap={3.2} flexDirection="column">
        <Typography variant="h6">{translations.potentialRisksTitle}</Typography>

        <Typography>{translations.potentialRisksFirstText}</Typography>

        <Typography>{translations.potentialRisksSndText}</Typography>
      </Grid>

      <Grid container gap={3.2} flexDirection="column">
        <Typography variant="h6">{translations.patientConsentTitle}</Typography>

        <Typography>
          <Typography component="span" fontWeight={700}>
            {translations.patientConsentFirstTextFirstPart}
          </Typography>{" "}
          {translations.patientConsentFirstTextSndPart}
        </Typography>

        <Typography>
          <Typography component="span" fontWeight={700}>
            {translations.patientConsentSndTextFirstPart}
          </Typography>{" "}
          {translations.patientConsentSndTextSndPart}
        </Typography>

        <Typography>
          {translations.patientConsentThirdTextFirstPart}{" "}
          <Typography
            component="span"
            fontWeight={700}
            textTransform="uppercase"
          >
            {translations.patientConsentThirdTextSndPart}
          </Typography>{" "}
          {translations.patientConsentThirdTextThirdPart}{" "}
          <Typography component="span" sx={{ textDecoration: "underline" }}>
            {translations.patientConsentThirdTextFourthPart}
          </Typography>
        </Typography>

        <Typography>
          <Typography component="span" fontWeight={700}>
            {translations.patientConsentFourthTextFirstPart}
          </Typography>{" "}
          {translations.patientConsentFourthTextSndPart}
        </Typography>

        <Typography>
          <Typography component="span" fontWeight={700}>
            {translations.patientConsentFifthTextFirstPart}
          </Typography>{" "}
          {translations.patientConsentFifthTextSndPart}{" "}
          <Typography
            component="span"
            fontWeight={700}
            textTransform="uppercase"
          >
            {translations.patientConsentFifthTextThirdPart}
          </Typography>{" "}
          {translations.patientConsentFifthTextFourthPart}{" "}
          <Typography
            component="span"
            fontWeight={700}
            sx={{ textDecoration: "underline" }}
          >
            {translations.patientConsentFifthTextFifthPart}
          </Typography>{" "}
          {translations.patientConsentFifthTextSixthPart}
        </Typography>

        <Typography>
          <Typography component="span" fontWeight={700}>
            {translations.patientConsentSixthTextFirstPart}
          </Typography>{" "}
          {translations.patientConsentSixthTextSndPart}
        </Typography>
      </Grid>

      <Grid container gap={3.2} flexDirection="column">
        <Typography variant="h6" fontWeight={500}>
          {translations.patientPregnancyConfirmationLabel}
        </Typography>

        <Typography variant="body1">
          {translations.patientPregnancyConfirmationText}
        </Typography>

        <RadioGroup
          row
          name="pregnancyConfirmation"
          value={values.pregnancyConfirmation}
          onChange={handleChange}
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />

          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </Grid>

      {values.pregnancyConfirmation === "yes" && (
        <Grid container gap={3.2} flexDirection="column">
          <Typography variant="h6">
            {translations.pregnancyConsentTitle}
          </Typography>

          <Typography>{translations.pregnancyConsentFirstText}</Typography>

          <Typography>{translations.pregnancyConsentSndText}</Typography>

          <Typography>
            {`${translations.pregnancyConsentThirdText}, ${patient?.first_name} ${patient?.last_name}, ${translations.pregnancyConsentFourthText}`}
          </Typography>
        </Grid>
      )}
      <Grid container gap={3.2} flexDirection="column">
        <Typography variant="h6">
          {translations.acknowledgementTitle}
        </Typography>

        <Typography>{translations.acknowledgementText}</Typography>
      </Grid>

      <Grid container gap={3.2} flexDirection="column">
        <Typography variant="h6" fontWeight={500}>
          {translations.signatureTitle}
        </Typography>

        <Typography variant="body1">{`${
          translations.dateTitle
        }: ${getNowUTC().toLocaleDateString()}`}</Typography>

        <Grid container flexDirection="column">
          <SimpleCheckbox
            name="patientSignature"
            onChange={handleChange}
            checked={values.patientSignature}
            label={`${patientInitials} ${translations.signatureCheckboxSuffix}`}
          />

          <Typography
            pl={6.6}
            fontSize={12}
            lineHeight="19.92px"
            letterSpacing="0.4px"
          >
            {translations.signatureCheckboxUnderstandText}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container direction="row" justifyContent="flex-end" gap={1.6}>
        <Grid item>
          <Button
            variant="text"
            size="large"
            disabled={isSubmitting}
            onClick={handleCancel}
          >
            {translations.cancelButtonTitle}
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant="text"
            size="large"
            disabled={saveButtonDisabled}
            onClick={() => handleSubmit()}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          >
            {translations.saveButtonTitle}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PatientConsentForm;
