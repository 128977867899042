import { Country, State } from "country-state-city";

const getStatesOfCountry = (countryCode: string) => {
  return State.getStatesOfCountry(countryCode);
};

const getStateByCodeAndCountry = (params: {
  countryCode: string;
  stateCode: string;
}) => {
  const { countryCode, stateCode } = params;

  return State.getStateByCodeAndCountry(stateCode, countryCode);
};

const getAllCountries = () => {
  return Country.getAllCountries();
};

const getCountryByCode = (countryCode: string) => {
  return Country.getCountryByCode(countryCode);
};

export {
  getStatesOfCountry,
  getStateByCodeAndCountry,
  getAllCountries,
  getCountryByCode,
};
