import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  return useMemo(() => {
    return {
      appointmentScreeningFormSuccessMessage: formatMessage(
        messages.appointmentScreeningFormSuccessMessage,
      ),
    };
  }, [formatMessage]);
};

export default useTranslations;
