import { useCallback } from "react";
import { SignInErrorCode } from "../AuthContextProvider/types";
import useTranslations from "./useTranslations";

const useErrorMessages = () => {
  const { defaultError, errorEmailIsInvalid, errorSendAttemptsLimit } =
    useTranslations();

  const getErrorMessageOnSignIn = useCallback(
    (errorCode: SignInErrorCode) => {
      switch (errorCode) {
        case "USER_NOT_FOUND": {
          throw new Error(errorEmailIsInvalid);
        }
        case "ATTEMPTS_LIMIT": {
          throw new Error(errorSendAttemptsLimit);
        }
        default: {
          throw new Error(defaultError);
        }
      }
    },
    [defaultError, errorEmailIsInvalid, errorSendAttemptsLimit],
  );

  return { getErrorMessageOnSignIn };
};

export default useErrorMessages;
