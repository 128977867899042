import * as Yup from "yup";
import {
  EMAIL_REGEXP_STR,
  MAX_STRING_LEN,
  PHONE_NUMBER_REGEXP_STR,
} from "../../../common/constants";
import useValidationSchemaTranslations from "./useValidationSchemaTranslations";
import { maxMedicalInfoStrLength } from "./constants";

const optionalString = Yup.string().trim();
const requiredString = optionalString.required();

const useValidationSchema = () => {
  const translations = useValidationSchemaTranslations();

  const shape = {
    contactEmail: requiredString
      .matches(
        new RegExp(`^${EMAIL_REGEXP_STR}$`),
        translations.errorEmailIsInvalid,
      )
      .max(MAX_STRING_LEN)
      .label(translations.labelContactEmail),
    contactPhone: requiredString
      .trim()
      .matches(PHONE_NUMBER_REGEXP_STR)
      .required()
      .label(translations.labelContactPhone),
    addressPrimary: requiredString
      .max(MAX_STRING_LEN)
      .label(translations.labelAddressPrimary),
    addressSecondary: optionalString
      .max(MAX_STRING_LEN)
      .label(translations.labelAddressSecondary),
    addressCity: requiredString
      .max(MAX_STRING_LEN)
      .label(translations.labelAddressCity),
    addressState: requiredString.label(translations.labelAddressState),
    addressPostalCode: requiredString
      .max(MAX_STRING_LEN)
      .label(translations.labelAddressPostalCode),
    addressCountry: requiredString.label(translations.labelAddressCountry),
    medicalReason: requiredString
      .max(maxMedicalInfoStrLength)
      .label(translations.labelMedicalReason),
    medicalSurgicalHistory: optionalString
      .max(maxMedicalInfoStrLength)
      .label(translations.labelMedicalSurgicalHistory),
    medicalCancerHistory: optionalString
      .max(maxMedicalInfoStrLength)
      .label(translations.labelMedicalCancerHistory),
    referringPracticeName: optionalString
      .max(MAX_STRING_LEN)
      .label(translations.labelReferringPracticeName),
    referringPostalCode: optionalString
      .max(MAX_STRING_LEN)
      .label(translations.labelReferringPostalCode),
    referringEmail: optionalString
      .matches(
        new RegExp(`^${EMAIL_REGEXP_STR}$`),
        translations.errorEmailIsInvalid,
      )
      .max(MAX_STRING_LEN)
      .label(translations.labelReferringEmail),
    referringPhone: optionalString
      .trim()
      .matches(PHONE_NUMBER_REGEXP_STR)
      .label(translations.labelContactPhone),
    doctorsOrderFiles: Yup.array().of(Yup.mixed()),
    race: requiredString.label(translations.labelRace),
    sex: requiredString.label(translations.labelSex),
    heightFt: Yup.number()
      .min(0)
      .integer()
      .required()
      .label(translations.labelHeightFt),
    heightIn: Yup.number()
      .min(0)
      .max(11)
      .integer()
      .required()
      .label(translations.labelHeightIn),
    weightLbs: Yup.number()
      .min(0)
      .integer()
      .required()
      .label(translations.labelWeightLbs),
  };

  const schema = Yup.object().shape(shape);

  return schema;
};

export default useValidationSchema;
