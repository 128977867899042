import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      title: formatMessage(messages.title),
      editInformation: formatMessage(messages.editInformation),
      confirm: formatMessage(messages.confirm),
      warningMessage: formatMessage(messages.warningMessage),
      labelEmail: formatMessage(messages.labelEmail),
      labelPhone: formatMessage(messages.labelPhone),
      labelAddress: formatMessage(messages.labelAddress),
      labelAntro: formatMessage(messages.labelAntro),
      labelSex: formatMessage(messages.labelSex),
      labelRace: formatMessage(messages.labelRace),
      labelReason: formatMessage(messages.labelReason),
      labelSurgicalHistory: formatMessage(messages.labelSurgicalHistory),
      labelCancerHistory: formatMessage(messages.labelCancerHistory),
    };

    return { translations };
  }, [formatMessage]);
  return result;
};
export default useTranslations;
