import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthLoginScreen from "../../Auth/AuthLoginScreen";
import UserCheckedInNotificationScreen from "../../User/UserCheckedInNotificationScreen";
import GuestLayout from "../../Auth/GuestLayout";
import { UserData } from "../../Auth/AuthContextProvider/types";
import AuthenticatedRootCheckInApp from "../../Auth/AuthenticatedRootCheckInApp";

const CheckInAppRoot = ({ userData }: { userData: UserData | null }) => {
  if (!userData) {
    return (
      <Routes>
        <Route
          path="/"
          element={
            <GuestLayout>
              <AuthLoginScreen />
            </GuestLayout>
          }
        />
        <Route
          path="/checked-in"
          element={
            <GuestLayout>
              <UserCheckedInNotificationScreen />
            </GuestLayout>
          }
        />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    );
  }

  return <AuthenticatedRootCheckInApp />;
};

export default CheckInAppRoot;
