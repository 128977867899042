import React from "react";
import { Helmet } from "react-helmet";

const Head: React.FC = () => {
  return (
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
  );
};

export default Head;
