import React, { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import Box from "../../Unknown/Box";
import CircularProgress from "../../Unknown/CircularProgress";
import Navbar from "../../Unknown/Navbar";
import Container from "../../Unknown/Container";
import useStyles from "./useStyles";
import Typography from "../../Unknown/Typography";
import useTranslations from "./useTranslations";
import Paper from "../../Unknown/Paper";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { useUIContext } from "../../Unknown/UIContext";

const AppointmentReportUnexpectedFindingsConfirmationScreen: FC = () => {
  const { layoutRoot, containerRoot } = useStyles();
  const { confirmationToken } = useParams();
  const navigate = useNavigate();
  const { firstTitle, secondTitle } = useTranslations();
  const { runAsyncFunction } = useDefaultErrorWrapper();
  const reportUnexpectedFindingsConfrimation = useFirebaseAppFunction(
    "reportUnexpectedFindingsConfrimation",
  );
  const { setAlert } = useUIContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffectOnce(() => {
    const update = async () => {
      if (!confirmationToken) return;
      try {
        await runAsyncFunction(reportUnexpectedFindingsConfrimation, {
          confirmationToken,
        });
      } catch (error) {
        navigate("/");
        setAlert({
          isShown: true,
          severity: "error",
          message: (error as Error).message,
        });
      } finally {
        setIsLoading(false);
      }
    };
    update();
  });

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        pt={10}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className={layoutRoot}>
      <Navbar />
      <Container className={containerRoot}>
        <Paper>
          <Box
            p={6}
            textAlign="center"
            width="100%"
            display="flex"
            flexDirection="column"
            gap={6}
          >
            <Typography>{firstTitle}</Typography>
            <Typography>{secondTitle}</Typography>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default AppointmentReportUnexpectedFindingsConfirmationScreen;
