import { ProvideCustomerPortalPatientInformationParams } from "@Shape-Digital/kudzu-data/lib/types/actions";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { useUIContext } from "../../Unknown/UIContext";
import { Appointment, FormConfig } from "./types";
import useFileUploader from "./useFileUploader";
import useTranslations from "./useTranslations";
import useValidationSchema from "./useValidationSchema";
import { useFormContext } from "../../Unknown/FormContext";
import { useAuthContext } from "../../Auth/AuthContextProvider/useAuthContext";
import checkIsAppointmentCheckedIn from "../../../common/checkIsAppointmentCheckedIn";
import { getValidPhoneNumber } from "../../../common/phoneNumberHelpers";
import { DEFAULT_COUNTRY_CODE } from "../../../common/constants";

const useFormConfig = (params: {
  appointment: Appointment | null;
}): FormConfig => {
  const { appointment } = params;

  const navigate = useNavigate();

  const { signOut } = useAuthContext();

  const { defaultError, patientInformationSuccessSaved, errorEmailIsInUse } =
    useTranslations();

  const uploadFiles = useFileUploader();

  const { runAsyncFunction } = useDefaultErrorWrapper();

  const { setFormAlert } = useFormContext();

  const { setAlert } = useUIContext();

  const provideCheckInAppPatientInformation = useFirebaseAppFunction(
    "provideCheckInAppPatientInformation",
  );

  const initialValues = useMemo<FormConfig["initialValues"]>(() => {
    const { appointment_patient_details, appointment_patient_details_form } =
      appointment || {};
    const patientDetails = appointment_patient_details?.[0];
    const appointmentPatientDetailsForm = appointment_patient_details_form?.[0];
    const { appointment_files } = appointment || {};
    const appointmentFiles = appointment_files || [];

    const { email, mobile_phone_number, patient } = patientDetails || {};

    const { city, state, postal_code, country, race, sex } = patient || {};

    const addressPrimary = patientDetails?.patient.patient_addresses.find(
      (addr) => addr.type === "primary",
    )?.address;

    const addressSecondary = patientDetails?.patient.patient_addresses.find(
      (addr) => addr.type === "secondary",
    )?.address;

    const {
      exam_reason,
      cancer_history,
      surgical_history,
      referring_physician: refferingPhysician,
      weight_lbs,
      height_ft,
      height_in,
    } = appointmentPatientDetailsForm || {};

    return {
      contactEmail: email || "",
      contactPhone: mobile_phone_number || "",
      addressPrimary: addressPrimary || "",
      addressSecondary: addressSecondary || "",
      addressCity: city || "",
      addressState: state || "",
      addressPostalCode: postal_code || "",
      addressCountry: country || DEFAULT_COUNTRY_CODE,
      medicalReason: exam_reason || "",
      medicalSurgicalHistory: surgical_history || "",
      medicalCancerHistory: cancer_history || "",
      referringPracticeName: refferingPhysician?.name || "",
      referringPostalCode: refferingPhysician?.zip_code || "",
      referringEmail: refferingPhysician?.email || "",
      referringPhone: refferingPhysician?.phone_number || "",
      doctorsOrderFiles: [],
      existedDoctorsOrderFiles: appointmentFiles || [],
      race: race || "",
      sex: sex || "",
      heightFt: typeof height_ft === "number" ? height_ft : "",
      heightIn: typeof height_in === "number" ? height_in : "",
      weightLbs: typeof weight_lbs === "number" ? weight_lbs : "",
    };
  }, [appointment]);

  const validationSchema = useValidationSchema();

  const onSubmit = useCallback<FormConfig["onSubmit"]>(
    async (values) => {
      if (!appointment) return;

      const {
        contactEmail,
        contactPhone,
        addressPrimary,
        addressSecondary,
        addressCity,
        addressState,
        addressPostalCode,
        addressCountry,
        medicalReason,
        medicalSurgicalHistory,
        medicalCancerHistory,
        referringPracticeName,
        referringPostalCode,
        referringEmail,
        referringPhone,
        doctorsOrderFiles,
        race,
        sex,
        heightFt,
        heightIn,
        weightLbs,
      } = values;

      if (
        !contactEmail ||
        !contactPhone ||
        !addressPrimary ||
        !addressCity ||
        !addressState ||
        !addressPostalCode ||
        !addressCountry ||
        !medicalReason
      ) {
        throw new Error(defaultError);
      }

      const files = await uploadFiles({
        appointmentId: appointment.id,
        files: doctorsOrderFiles,
      });

      try {
        const args: ProvideCustomerPortalPatientInformationParams = {
          appointmentId: appointment.id,
          contactEmail,
          contactPhone: getValidPhoneNumber(contactPhone),
          addressPrimary,
          addressSecondary,
          addressCity,
          addressState,
          addressPostalCode,
          addressCountry,
          medicalReason,
          medicalSurgicalHistory,
          medicalCancerHistory,
          referringPracticeName,
          referringPostalCode,
          referringEmail,
          referringPhone: referringPhone
            ? getValidPhoneNumber(referringPhone)
            : undefined,
          doctorsOrderFiles: files,
          race,
          sex,
          heightFt: +heightFt,
          heightIn: +heightIn,
          weightLbs: +weightLbs,
        };

        const { data } = await runAsyncFunction(
          provideCheckInAppPatientInformation,
          args,
        );

        if (data.status === "error") {
          setAlert({
            isShown: true,
            severity: "error",
            message: errorEmailIsInUse,
          });
          return;
        }

        const isAppointmentCheckedIn = await checkIsAppointmentCheckedIn(
          appointment.id,
        );

        if (isAppointmentCheckedIn) {
          signOut();
          navigate("/checked-in");
          return;
        }
        setFormAlert({
          isShown: true,
          severity: "success",
          message: patientInformationSuccessSaved,
        });

        navigate("/");
      } catch (error) {
        setAlert({
          isShown: true,
          severity: "error",
          message: (error as Error).message,
        });
      }
    },
    [
      appointment,
      uploadFiles,
      defaultError,
      runAsyncFunction,
      provideCheckInAppPatientInformation,
      setFormAlert,
      patientInformationSuccessSaved,
      navigate,
      setAlert,
      errorEmailIsInUse,
      signOut,
    ],
  );

  return {
    enableReinitialize: true,
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit,
  };
};

export default useFormConfig;
