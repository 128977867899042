import React, { useMemo } from "react";
import { hhsHipaaCompilantsLink, legalEmail } from "../../../common/constants";
import { SimpleListProps } from "../../Unknown/SimpleList";
import Typography from "../../Unknown/Typography";
import useTranslations from "./useTranslations";

const usePatientPrivacyFormSimpleLinks = () => {
  const translations = useTranslations();
  const yourRightsSimpleListItems: SimpleListProps["listItems"] = useMemo(
    () => [
      {
        title: translations.yourRightsFirstListItem,
        sublistItems: [translations.yourRightsFirstListItemSublistItem],
      },
      {
        title: translations.yourRightsSndListItem,
      },
      {
        title: translations.yourRightsThirdListItem,
        sublistItems: [translations.yourRightsThirdListItemSublistItem],
      },
      {
        title: translations.yourRightsFourthListItem,
        sublistItems: [translations.yourRightsFourthListItemSublistItem],
      },
      {
        title: translations.yourRightsFifthListItem,
      },
      {
        title: translations.yourRightsSixthListItem,
        sublistItems: [translations.yourRightsSixthListItemSublistItem],
      },
      {
        title: translations.yourRightsSeventhListItem,
        sublistItems: [
          <Typography key="yourRightsSeventhListItem">
            {translations.yourRightsSeventhListItemSublistItemFirstPart}{" "}
            <Typography
              component="a"
              href={`mailto:${legalEmail}`}
              color="text.primary"
            >
              {legalEmail}
            </Typography>
            {". "}
            {translations.yourRightsSeventhListItemSublistItemSndPart}{" "}
            <Typography
              component="a"
              target="_blank"
              href={`${hhsHipaaCompilantsLink}`}
              color="text.primary"
            >
              {hhsHipaaCompilantsLink}
            </Typography>
            {". "}
            {translations.yourRightsSeventhListItemSublistItemThirdPart}
          </Typography>,
        ],
      },
    ],
    [translations],
  );

  const ourResponsibilitiesSimpleListItems: SimpleListProps["listItems"] =
    useMemo(
      () => [
        {
          title: translations.ourResponsibilitiesFirstListItem,
        },
        {
          title: translations.ourResponsibilitiesSndListItem,
        },
        {
          title: translations.ourResponsibilitiesThirdListItem,
        },
        {
          title: translations.ourResponsibilitiesFourthListItem,
        },
      ],
      [translations],
    );

  const ourUsersAndDisclosuresFirstSimpleListItems: SimpleListProps["listItems"] =
    useMemo(
      () => [
        {
          title: translations.ourUsersAndDisclosuresFirstListItem,
        },
        {
          title: translations.ourUsersAndDisclosuresSndListItem,
        },
        {
          title: translations.ourUsersAndDisclosuresThirdListItem,
        },
        {
          title: translations.complyWithLegalRequirements,
        },
        {
          title: translations.ourUsersAndDisclosuresFourthListItem,
        },
      ],
      [translations],
    );

  const ourUsersAndDisclosuresSndSimpleListItems: SimpleListProps["listItems"] =
    useMemo(
      () => [
        {
          title: translations.ourUsersAndDisclosuresFifthListItem,
        },
        {
          title: translations.ourUsersAndDisclosuresSixthListItem,
        },
        {
          title: translations.ourUsersAndDisclosuresSeventhListItem,
        },
      ],
      [translations],
    );

  return {
    yourRightsSimpleListItems,
    ourResponsibilitiesSimpleListItems,
    ourUsersAndDisclosuresFirstSimpleListItems,
    ourUsersAndDisclosuresSndSimpleListItems,
  };
};

export default usePatientPrivacyFormSimpleLinks;
