import { useCallback, useState } from "react";
import { useEffectOnce } from "react-use";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import { useUIContext } from "../../Unknown/UIContext";
import getPatient from "./getPatient";
import { Patient } from "./types";

const usePatient = (params: { patientId?: string }) => {
  const { patientId } = params;

  const { setAlert } = useUIContext();

  const [isLoading, setIsLoading] = useState(false);
  const [patient, setPatient] = useState<Patient | null>(null);

  const { runAsyncFunction } = useDefaultErrorWrapper();

  const refetch = useCallback(async () => {
    if (!patientId) return;

    try {
      setIsLoading(true);
      const data = await runAsyncFunction(getPatient, {
        patientId,
      });

      setPatient(data);
    } catch (error) {
      setPatient(null);
      setAlert({
        isShown: true,
        severity: "error",
        message: (error as Error).message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [patientId, runAsyncFunction, setAlert]);

  useEffectOnce(() => {
    refetch();
  });

  return { isLoading, patient, refetch };
};

export default usePatient;
