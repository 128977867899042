import { defineMessages, useIntl } from "react-intl";

export const sexTranslations = defineMessages({
  male: {
    id: "common.messages.male",
    defaultMessage: "Male",
  },
  female: {
    id: "common.messages.female",
    defaultMessage: "Female",
  },
  preferNotToAnswer: {
    id: "common.messages.preferNotToAnswer",
    defaultMessage: "Prefer not to answer",
  },
});

export type Sex = "male" | "female" | "prefer_not_to_answer";

export const sexTranslationKeys: Record<Sex, keyof typeof sexTranslations> = {
  male: "male",
  female: "female",
  prefer_not_to_answer: "preferNotToAnswer",
};

const useSexOptions = () => {
  const { formatMessage } = useIntl();

  const options: { value: Sex; label: string }[] = [
    {
      value: "male",
      label: formatMessage(sexTranslations.male),
    },
    {
      value: "female",
      label: formatMessage(sexTranslations.female),
    },
    {
      value: "prefer_not_to_answer",
      label: formatMessage(sexTranslations.preferNotToAnswer),
    },
  ];

  return options;
};

export default useSexOptions;
