import { useMemo } from "react";
import { useIntl } from "react-intl";
import { bold } from "../../../common/getFormatMessageChunks";
import messages from "./messages";

const useTranslations = (params: { phoneNumber: string }) => {
  const { phoneNumber } = params;
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      btnSubmit: formatMessage(messages.btnSubmit),
      btnResendCode: formatMessage(messages.btnResendCode),
      helperCodeSent: formatMessage(messages.helperCodeSent, {
        phoneNumber,
        b: bold,
      }),
    };
  }, [formatMessage, phoneNumber]);

  return translations;
};

export default useTranslations;
