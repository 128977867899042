import { useCallback, useState } from "react";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { AuthConfirmUserFormProps } from "../AuthConfirmUserForm";
import useErrorMessages from "./useErrorMessages";

const useResendCode = (params: {
  email: string;
  handleError: (msg: string | null) => void;
}) => {
  const { email, handleError } = params;

  const [isLoading, setIsLoading] = useState(false);

  const { getErrorMessageOnResendCode } = useErrorMessages();

  const { runAsyncFunction } = useDefaultErrorWrapper();

  const sendCustomerPortalAuthCode = useFirebaseAppFunction(
    "sendCustomerPortalAuthCode",
  );

  const handleResend = useCallback<
    AuthConfirmUserFormProps["handleResend"]
  >(async () => {
    try {
      handleError(null);
      setIsLoading(true);
      const { data } = await runAsyncFunction(sendCustomerPortalAuthCode, {
        email,
      });

      if (data.status === "error") {
        const msg = getErrorMessageOnResendCode(data.error.code);
        throw new Error(msg);
      }
    } catch (error) {
      handleError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [
    handleError,
    runAsyncFunction,
    sendCustomerPortalAuthCode,
    email,
    getErrorMessageOnResendCode,
  ]);

  return { handleResend, isLoading };
};

export default useResendCode;
