import React, { ReactNode } from "react";
import Box from "../../Unknown/Box";
import Container from "../../Unknown/Container";
import Navbar from "../../Unknown/Navbar";
import useStyles from "./useStyles";

interface UnauthenticatedLayoutProps {
  children?: ReactNode;
}

const GuestLayout = ({ children }: UnauthenticatedLayoutProps) => {
  const { layoutRoot, containerRoot } = useStyles();

  return (
    <Box className={layoutRoot}>
      <Navbar />
      <Container className={containerRoot}>
        <Box mx="auto" maxWidth={500}>
          {children}
        </Box>
      </Container>
    </Box>
  );
};

export default GuestLayout;
