import React from "react";
import { useEffectOnce } from "react-use";
import { useNavigate } from "react-router-dom";
import Paper from "../../Unknown/Paper";
import Box from "../../Unknown/Box";
import Grid from "../../Unknown/Grid";
import useTranslations from "./useTranslations";
import Typography from "../../Unknown/Typography";
import { delayBeforeRedirectToMainViewSeconds } from "../../../common/constants";

const delayBeforeRedirectToMainViewMilliseconds =
  delayBeforeRedirectToMainViewSeconds * 1000;

const UserCheckedInNotificationScreen = () => {
  const navigate = useNavigate();
  const { title, description } = useTranslations();

  useEffectOnce(() => {
    const timeout = setTimeout(() => {
      navigate("/");
    }, delayBeforeRedirectToMainViewMilliseconds);

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <Paper>
      <Box p={3.2}>
        <Grid container flexDirection="column" textAlign="center" gap={2}>
          <Typography variant="h6">{title}</Typography>
          <Typography>{description}</Typography>
        </Grid>
      </Box>
    </Paper>
  );
};

export default UserCheckedInNotificationScreen;
