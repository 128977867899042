import React, { FC, useCallback, useState } from "react";
import AuthCheckUserFormContainer from "../AuthCheckUserFormContainer";
import AuthConfirmUserFormContainer from "../AuthConfirmUserFormContainer";

const AuthLoginFormContainer: FC = () => {
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);

  const handleCheckUserSubmit = useCallback(
    (params: { phoneNumber: string; email: string }) => {
      setPhoneNumber(params.phoneNumber);
      setEmail(params.email);
    },
    [],
  );

  if (!phoneNumber || !email) {
    return <AuthCheckUserFormContainer handleSubmit={handleCheckUserSubmit} />;
  }

  return (
    <AuthConfirmUserFormContainer email={email} phoneNumber={phoneNumber} />
  );
};

export default AuthLoginFormContainer;
