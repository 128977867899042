import { defineMessages } from "react-intl";

export default defineMessages({
  labelContactEmail: {
    id: "Patient.PatientChangeInformationFormContainer.labelContactEmail",
    defaultMessage: "Email address",
  },
  labelContactPhone: {
    id: "Patient.PatientChangeInformationFormContainer.labelContactPhone",
    defaultMessage: "Mobile phone number",
  },
  labelAddressPrimary: {
    id: "Patient.PatientChangeInformationFormContainer.labelAddressPrimary",
    defaultMessage: "Address line 1",
  },
  labelAddressSecondary: {
    id: "Patient.PatientChangeInformationFormContainer.labelAddressSecondary",
    defaultMessage: "Address line 2",
  },
  labelAddressCity: {
    id: "Patient.PatientChangeInformationFormContainer.labelAddressCity",
    defaultMessage: "City",
  },
  labelAddressState: {
    id: "Patient.PatientChangeInformationFormContainer.labelAddressState",
    defaultMessage: "State",
  },
  labelAddressPostalCode: {
    id: "Patient.PatientChangeInformationFormContainer.labelAddressPostalCode",
    defaultMessage: "ZIP",
  },
  labelAddressCountry: {
    id: "Patient.PatientChangeInformationFormContainer.labelAddressCountry",
    defaultMessage: "Country",
  },
  errorEmailIsInvalid: {
    id: "Patient.PatientChangeInformationFormContainer.errorEmailIsInvalid",
    defaultMessage: "Email is invalid",
  },
});
