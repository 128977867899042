import { lighten, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette }) => {
  return {
    root: {
      backgroundColor: lighten(palette.primary.light, 0.9),
    },
  };
});

export default useStyles;
