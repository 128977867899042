import React, { FC, useState } from "react";
import Box from "../../Unknown/Box";
import Paper from "../../Unknown/Paper";
import AuthConfirmUserForm from "../AuthConfirmUserForm";
import useAuthConfirmUserForm from "./useAuthConfirmUserForm";
import useForm from "./useForm";

export type AuthConfirmUserFormContainerProps = {
  phoneNumber: string;
  email: string;
};

const AuthConfirmUserFormContainer: FC<AuthConfirmUserFormContainerProps> = ({
  phoneNumber,
  email,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const formik = useForm({
    email,
    handleError: setErrorMessage,
  });

  const props = useAuthConfirmUserForm({
    formik,
    phoneNumber,
    email,
    errorMessage,
    handleError: setErrorMessage,
  });

  return (
    <Paper>
      <Box p={6}>
        <AuthConfirmUserForm {...props} />
      </Box>
    </Paper>
  );
};

export default AuthConfirmUserFormContainer;
