import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "Patient.ConfirmInformationDialog.title",
    defaultMessage: "Confirm your information",
  },
  editInformation: {
    id: "Patient.ConfirmInformationDialog.editInformation",
    defaultMessage: "Edit information",
  },
  confirm: {
    id: "Patient.ConfirmInformationDialog.confirm",
    defaultMessage: "Confirm",
  },
  warningMessage: {
    id: "Patient.ConfirmInformationDialog.warningMessage",
    defaultMessage: "Please ensure your information is correct before saving",
  },
  labelEmail: {
    id: "Patient.ConfirmInformationDialog.labelEmail",
    defaultMessage: "Email Address",
  },
  labelPhone: {
    id: "Patient.ConfirmInformationDialog.labelPhone",
    defaultMessage: "Mobile phone number",
  },
  labelAddress: {
    id: "Patient.ConfirmInformationDialog.labelAddress",
    defaultMessage: "Address",
  },
  labelAntro: {
    id: "Patient.ConfirmInformationDialog.labelAntro",
    defaultMessage: "Height and Weight",
  },
  labelSex: {
    id: "Patient.ConfirmInformationDialog.labelSex",
    defaultMessage: "Sex",
  },
  labelRace: {
    id: "Patient.ConfirmInformationDialog.labelRace",
    defaultMessage: "Race",
  },
  labelReason: {
    id: "Patient.ConfirmInformationDialog.labelReason",
    defaultMessage: "Reason for Exam",
  },
  labelSurgicalHistory: {
    id: "Patient.ConfirmInformationDialog.labelSurgicalHistory",
    defaultMessage: "Surgical History",
  },
  labelCancerHistory: {
    id: "Patient.ConfirmInformationDialog.labelCancerHistory",
    defaultMessage: "Cancer History",
  },
});

export default messages;
