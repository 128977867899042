import { defineMessages } from "react-intl";

export default defineMessages({
  labelContactEmail: {
    id: "Patient.CheckInAppPatientProvideInformationFormContainer.labelContactEmail",
    defaultMessage: "Email address",
  },
  labelContactPhone: {
    id: "Patient.CheckInAppPatientProvideInformationFormContainer.labelContactPhone",
    defaultMessage: "Mobile phone number",
  },
  labelAddressPrimary: {
    id: "Patient.CheckInAppPatientProvideInformationFormContainer.labelAddressPrimary",
    defaultMessage: "Address line 1",
  },
  labelAddressSecondary: {
    id: "Patient.CheckInAppPatientProvideInformationFormContainer.labelAddressSecondary",
    defaultMessage: "Address line 2",
  },
  labelAddressCity: {
    id: "Patient.CheckInAppPatientProvideInformationFormContainer.labelAddressCity",
    defaultMessage: "City",
  },
  labelAddressState: {
    id: "Patient.CheckInAppPatientProvideInformationFormContainer.labelAddressState",
    defaultMessage: "State",
  },
  labelAddressPostalCode: {
    id: "Patient.CheckInAppPatientProvideInformationFormContainer.labelAddressPostalCode",
    defaultMessage: "ZIP",
  },
  labelAddressCountry: {
    id: "Patient.CheckInAppPatientProvideInformationFormContainer.labelAddressCountry",
    defaultMessage: "Country",
  },
  labelMedicalReason: {
    id: "Patient.CheckInAppPatientProvideInformationFormContainer.labelMedicalReason",
    defaultMessage: "Reason for exam",
  },
  labelMedicalSurgicalHistory: {
    id: "Patient.CheckInAppPatientProvideInformationFormContainer.labelMedicalSurgicalHistory",
    defaultMessage: "Surgical history",
  },
  labelMedicalCancerHistory: {
    id: "Patient.CheckInAppPatientProvideInformationFormContainer.labelMedicalCancerHistory",
    defaultMessage: "Cancer history",
  },
  labelReferringPracticeName: {
    id: "Patient.CheckInAppPatientProvideInformationFormContainer.labelReferringPracticeName",
    defaultMessage: "Physician or practice name",
  },
  labelReferringPostalCode: {
    id: "Patient.CheckInAppPatientProvideInformationFormContainer.labelReferringPostalCode",
    defaultMessage: "ZIP code",
  },
  labelReferringEmail: {
    id: "Patient.CheckInAppPatientProvideInformationFormContainer.labelReferringEmail",
    defaultMessage: "Email address",
  },
  labelReferringPhone: {
    id: "Patient.CheckInAppPatientProvideInformationFormContainer.labelReferringPhone",
    defaultMessage: "Phone number",
  },
  errorEmailIsInvalid: {
    id: "Patient.CheckInAppPatientProvideInformationFormContainer.errorEmailIsInvalid",
    defaultMessage: "Email is invalid",
  },
  errorEmailIsInUse: {
    id: "Patient.CheckInAppPatientProvideInformationFormContainer.errorEmailIsInUse",
    defaultMessage:
      "This email address cannot be used as it is already in use by another customer or patient",
  },
});
