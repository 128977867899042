import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "Patient.PatientPrivacyForm.title",
    defaultMessage: "Patient Information",
  },
  headerNameTitle: {
    id: "Patient.PatientPrivacyForm.headerNameTitle",
    defaultMessage: "Name",
  },
  headerDateOfBirthTitle: {
    id: "Patient.PatientPrivacyForm.headerDateOfBirthTitle",
    defaultMessage: "Date of birth",
  },
  headerPatientIdTitle: {
    id: "Patient.PatientPrivacyForm.headerPatientIdTitle",
    defaultMessage: "Patient ID",
  },
  patientUnderstandCheckboxLabel: {
    id: "Patient.PatientPrivacyForm.patientUnderstandCheckboxLabel",
    defaultMessage:
      "I understand you will only use my information to contact me regarding your services and scheduling",
  },
  dateTitle: {
    id: "Patient.PatientPrivacyForm.dateTitle",
    defaultMessage: "Date",
  },
  signatureCheckboxSuffix: {
    id: "Patient.PatientPrivacyForm.signatureCheckboxSuffix",
    defaultMessage: "explicit consent",
  },
  signatureCheckboxUnderstandText: {
    id: "Patient.PatientPrivacyForm.signatureCheckboxUnderstandText",
    defaultMessage:
      "By checking this I confirm I understand this acts as a digital signature",
  },
  signatureTitle: {
    id: "Patient.PatientPrivacyForm.signatureCheckboxUnderstandText",
    defaultMessage: "Signature",
  },
  cancelButtonTitle: {
    id: "Patient.PatientPrivacyForm.cancelButtonTitle",
    defaultMessage: "Cancel",
  },
  saveButtonTitle: {
    id: "Patient.PatientPrivacyForm.saveButtonTitle",
    defaultMessage: "Save",
  },
  mainDescriptionDefaultPart: {
    id: "Patient.PatientPrivacyForm.mainDescriptionDefaultPart",
    defaultMessage:
      "This Notice describes how medical information about you may be used and disclosed and how you can get access to this information. Our facility’s policy generally is to not share any medical records with any individual or treating provider. It is our policy that it is YOUR sole responsibility to provide your treating provider with your MRI report if you choose to do so; however, this notice serves to explain to you your rights, instances where your information may be shared, and our privacy responsibilities.",
  },
  mainDescriptionBoldPart: {
    id: "Patient.PatientPrivacyForm.mainDescriptionBoldPart",
    defaultMessage: "Please review it carefully.",
  },
  yourRightsTitle: {
    id: "Patient.PatientPrivacyForm.yourRightsTitle",
    defaultMessage: "Your Rights",
  },
  yourRightsFirstDesc: {
    id: "Patient.PatientPrivacyForm.yourRightsFirstDesc",
    defaultMessage:
      "When it comes to your health information, you have certain rights. This section explains your rights and some of our responsibilities to help you.",
  },
  yourRightsSndDesc: {
    id: "Patient.PatientPrivacyForm.yourRightsSndDesc",
    defaultMessage: "You have the right to:",
  },
  yourRightsFirstListItem: {
    id: "Patient.PatientPrivacyForm.yourRightsFirstListItem",
    defaultMessage: "Get a copy of your medical record",
  },
  yourRightsFirstListItemSublistItem: {
    id: "Patient.PatientPrivacyForm.yourRightsFirstListItemSublistItem",
    defaultMessage:
      'You can ask to see or get an electronic copy of your medical record and other health information we have about you. Ask us how to do this. We will provide a copy or a summary of your health information, usually within 30 days of your request. You can ask us to correct health information about you that you think is incorrect or incomplete. Ask us how to do this. We may say "no" to your request, but we\'ll tell you why in writing within 60 days. You are entitled to a paper copy of your medical record by specific request.',
  },
  yourRightsSndListItem: {
    id: "Patient.PatientPrivacyForm.yourRightsSndListItem",
    defaultMessage: "Correct your medical record",
  },
  yourRightsThirdListItem: {
    id: "Patient.PatientPrivacyForm.yourRightsThirdListItem",
    defaultMessage: "Request confidential communication",
  },
  yourRightsThirdListItemSublistItem: {
    id: "Patient.PatientPrivacyForm.yourRightsThirdListItemSublistItem",
    defaultMessage:
      "You can ask us to contact you in a specific way to maintain your confidentiality and privacy (for example, home or office phone) or to send mail to a different address. We will work to accommodate all reasonable requests.",
  },
  yourRightsFourthListItem: {
    id: "Patient.PatientPrivacyForm.yourRightsFourthListItem",
    defaultMessage: "Ask us to limit the information we share",
  },
  yourRightsFourthListItemSublistItem: {
    id: "Patient.PatientPrivacyForm.yourRightsFourthListItemSublistItem",
    defaultMessage:
      'You can ask us not to use or share certain health information for treatment, payment, or our operations. We are not required to agree to your request, and we may say "no" if it would affect your care; however, you are entitled to an explanation regarding any denial of your request.. Upon your request, we will not share that information for this purpose unless state or federal law requires us to do so. Get a list of those with whom we\'ve shared information.',
  },
  yourRightsFifthListItem: {
    id: "Patient.PatientPrivacyForm.yourRightsFifthListItem",
    defaultMessage:
      "Get a list of those with whom we've shared your information",
  },
  yourRightsSixthListItem: {
    id: "Patient.PatientPrivacyForm.yourRightsFifthListItem",
    defaultMessage: "Get a copy of this privacy notice",
  },
  yourRightsSixthListItemSublistItem: {
    id: "Patient.PatientPrivacyForm.yourRightsSixthListItemSublistItem",
    defaultMessage:
      "You may ask for a paper copy of this notice at any time, even if you have agreed to receive the notice electronically. We will provide you with a paper copy promptly.",
  },
  yourRightsSeventhListItem: {
    id: "Patient.PatientPrivacyForm.yourRightsSeventhListItem",
    defaultMessage:
      "Choose someone to act for you/ File a complaint if you believe your privacy rights have been violated",
  },
  yourRightsSeventhListItemSublistItemFirstPart: {
    id: "Patient.PatientPrivacyForm.yourRightsSeventhListItemSublistItemFirstPart",
    defaultMessage:
      "If you have given someone medical power of attorney or if someone is your legal guardian, that person can exercise your rights and make choices about your health information. We will make sure the person has this authority and can act for you before we take any action. File a complaint if you feel your rights are violated. You can complain if you feel we have violated your rights by emailing us at",
  },
  yourRightsSeventhListItemSublistItemSndPart: {
    id: "Patient.PatientPrivacyForm.yourRightsSeventhListItemSublistItemSndPart",
    defaultMessage:
      "You can file a complaint with the U.S. Department of Health and Human Services Office for Civil Rights by sending a letter to 200 Independence Avenue, S.W., Washington, D.C. 20201, calling 1-877-696-6775, or visiting",
  },
  yourRightsSeventhListItemSublistItemThirdPart: {
    id: "Patient.PatientPrivacyForm.yourRightsSeventhListItemSublistItemThirdPart",
    defaultMessage: "We will not retaliate against you for filing a complaint.",
  },
  ourResponsibilities: {
    id: "Patient.PatientPrivacyForm.ourResponsibilities",
    defaultMessage: "Our Responsibilities",
  },
  ourResponsibilitiesFirstListItem: {
    id: "Patient.PatientPrivacyForm.ourResponsibilitiesFirstListItem",
    defaultMessage:
      "We are required by law to maintain the privacy and security of your protected health information.",
  },
  ourResponsibilitiesSndListItem: {
    id: "Patient.PatientPrivacyForm.ourResponsibilitiesSndListItem",
    defaultMessage:
      "We will notify you promptly if a breach occurs that may have compromised the privacy or security of your information.",
  },
  ourResponsibilitiesThirdListItem: {
    id: "Patient.PatientPrivacyForm.ourResponsibilitiesThirdListItem",
    defaultMessage:
      "We must follow the duties and privacy practices described in this notice and give you a copy of it.",
  },
  ourResponsibilitiesFourthListItem: {
    id: "Patient.PatientPrivacyForm.ourResponsibilitiesFourthListItem",
    defaultMessage:
      "We will not use or share your information other than as described here unless you tell us we can in writing. If you tell us we can, you may change your mind at any time. Let us know in writing if you change your mind.",
  },
  ourResponsibilitiesLinkPrefix: {
    id: "Patient.PatientPrivacyForm.ourResponsibilitiesLinkPrefix",
    defaultMessage: "For more information:",
  },
  ourUsersAndDisclosures: {
    id: "Patient.PatientPrivacyForm.ourUsersAndDisclosures",
    defaultMessage: "Our Uses and Disclosures",
  },
  ourUsersAndDisclosuresFirstDesc: {
    id: "Patient.PatientPrivacyForm.ourUsersAndDisclosuresFirstDesc",
    defaultMessage:
      "How do we typically use or share your health information? We typically use or share your health information in the following ways. Any use or disclosure of your information will be done so in confidentiality and anonymity unless otherwise required by law. We may use and share your information as we:",
  },
  ourUsersAndDisclosuresSndDesc: {
    id: "Patient.PatientPrivacyForm.ourUsersAndDisclosuresSndDesc",
    defaultMessage:
      "In certain cases, such as the following, you have both the right and choice to tell us to:",
  },
  ourUsersAndDisclosuresThirdDesc: {
    id: "Patient.PatientPrivacyForm.ourUsersAndDisclosuresThirdDesc",
    defaultMessage:
      "If you are not able to tell us your preference, for example if you are unconscious, we may deem it medically necessary to share your information if we believe it is best interest. We may also share your information when needed to lessen a serious and imminent threat to health or safety.",
  },
  ourUsersAndDisclosuresFourthDesc: {
    id: "Patient.PatientPrivacyForm.ourUsersAndDisclosuresFourthDesc",
    defaultMessage:
      "We will never share or sell your information unless you give explicit, written permission. As part of your consent to imaging you have consented and acknowledged that Imago may text or email you directly with the contact information you have provided regarding scheduling and Imago services and products.",
  },
  ourUsersAndDisclosuresFirstListItem: {
    id: "Patient.PatientPrivacyForm.ourUsersAndDisclosuresFirstDesc",
    defaultMessage: "Help with public health and safety issues",
  },
  ourUsersAndDisclosuresSndListItem: {
    id: "Patient.PatientPrivacyForm.ourUsersAndDisclosuresSndListItem",
    defaultMessage:
      "Address workers' compensation, law enforcement, and other government requests",
  },
  ourUsersAndDisclosuresThirdListItem: {
    id: "Patient.PatientPrivacyForm.ourUsersAndDisclosuresThirdListItem",
    defaultMessage: "Respond to lawsuits and legal actions",
  },
  complyWithLegalRequirements: {
    id: "Patient.PatientPrivacyForm.complyWithLegalRequirements",
    defaultMessage: "Comply with legal requirements",
  },
  ourUsersAndDisclosuresFourthListItem: {
    id: "Patient.PatientPrivacyForm.ourUsersAndDisclosuresFourthListItem",
    defaultMessage:
      "We can use and share your health information to run our practice and contact you when necessary. In such instances we shall exercise our efforts to keep your information confidential.",
  },
  ourUsersAndDisclosuresFifthListItem: {
    id: "Patient.PatientPrivacyForm.ourUsersAndDisclosuresFifthListItem",
    defaultMessage:
      "Share information with your family, close friends, or others involved in your care",
  },
  ourUsersAndDisclosuresSixthListItem: {
    id: "Patient.PatientPrivacyForm.ourUsersAndDisclosuresSixthListItem",
    defaultMessage: "Share information in a disaster relief situation",
  },
  ourUsersAndDisclosuresSeventhListItem: {
    id: "Patient.PatientPrivacyForm.ourUsersAndDisclosuresSeventhListItem",
    defaultMessage: "Include your information in a directory of patients",
  },
  changesToTheTermsTitle: {
    id: "Patient.PatientPrivacyForm.changesToTheTermsTitle",
    defaultMessage: "Changes To The Terms Of This Notice",
  },
  changesToTheTermsDesc: {
    id: "Patient.PatientPrivacyForm.changesToTheTermsDesc",
    defaultMessage:
      "We can change the terms of this notice, and the changes will apply to all information we have about you. The new notice will be available upon request, in our office, and on our website.",
  },
  designatedOfficerTitle: {
    id: "Patient.PatientPrivacyForm.designatedOfficerTitle",
    defaultMessage: "Designated Officer",
  },
  designatedOfficerFirstDescFirstPart: {
    id: "Patient.PatientPrivacyForm.designatedOfficerFirstDescFirstPart",
    defaultMessage:
      "If you have any questions about this Notice of Privacy Practices, our procedures or would like a copy of this Notice of Privacy Practices, please contact our Privacy Officer at",
  },
  designatedOfficerFirstDescSndPart: {
    id: "Patient.PatientPrivacyForm.designatedOfficerFirstDescSndPart",
    defaultMessage:
      "or in writing via mail at Imago MRI 3480 Peachtree Rd NE, Atlanta, GA 30326.",
  },
  designatedOfficerSndDesc: {
    id: "Patient.PatientPrivacyForm.designatedOfficerSndDesc",
    defaultMessage: "You may have a copy of the Notice of Privacy Practices.",
  },
  designatedOfficerThirdDescFirstPart: {
    id: "Patient.PatientPrivacyForm.designatedOfficerThirdDescFirstPart",
    defaultMessage: "Notice of Privacy Practices.",
  },
  designatedOfficerThirdDescSndPart: {
    id: "Patient.PatientPrivacyForm.designatedOfficerThirdDescSndPart",
    defaultMessage:
      "I acknowledge that I have received the Practice's Notice of Privacy Practices, which describes the ways in which the Practice may use and disclose my healthcare information for its treatment, payment, healthcare operations and other prescribed and permitted uses and disclosures. I understand that this information may be disclosed electronically by the Practice or Practice's Business associates. I understand that I may contact the Practice's Officer designated on the notice if I have a question or complaint. I am at least 18 years of age, of sound mind and not under the influence of alcohol or hallucinogenic drugs. I have no reservations and give my consent to start and complete the exam(s) by my signature and date here.",
  },
});
