import React, { FC } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import Paper from "../../Unknown/Paper";
import Box from "../../Unknown/Box";
import PatientPrivacyForm from "../PatientPrivacyForm";
import usePatient from "./usePatient";
import CircularProgress from "../../Unknown/CircularProgress";
import useFormikComponent from "./useFormikComponent";
import checkIsPatientFormValid from "../../../common/checkIsPatientFormValid";

interface PatientPrivacyFormContainerProps {
  patientId?: string;
  appointmentId?: string;
}

const PatientPrivacyFormContainer: FC<PatientPrivacyFormContainerProps> = ({
  patientId,
  appointmentId,
}) => {
  const navigate = useNavigate();
  const { isLoading, patient } = usePatient({ patientId });
  const formikProps = useFormikComponent(patient, appointmentId);

  if (isLoading || !patient) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        pt={10}
      >
        <CircularProgress />
      </Box>
    );
  }

  const isValidPrivacyForm = checkIsPatientFormValid({
    patientForms: patient.patient_forms,
    type: "privacy",
  });
  if (isValidPrivacyForm) {
    navigate("/");
    return null;
  }

  return (
    <Formik {...formikProps}>
      <Paper>
        <Box p={6}>
          <PatientPrivacyForm patient={patient} />
        </Box>
      </Paper>
    </Formik>
  );
};

export default PatientPrivacyFormContainer;
