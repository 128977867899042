import React, { FC } from "react";
import { Formik } from "formik";
import Paper from "../../Unknown/Paper";
import Box from "../../Unknown/Box";
import AppointmentScreeningForm from "../AppointmentScreeningForm";
import CircularProgress from "../../Unknown/CircularProgress";
import useFormikComponent from "./useFormikComponent";
import useAppointment from "./useAppointment";

interface PatientPrivacyFormContainerProps {
  appointmentId: string;
}

const AppointmentScreeningFormContainer: FC<
  PatientPrivacyFormContainerProps
> = ({ appointmentId }) => {
  const { appointment, isLoading } = useAppointment({ appointmentId });
  const formikProps = useFormikComponent(appointmentId);

  if (isLoading || !appointment) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        pt={10}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Formik {...formikProps}>
      <Paper>
        <Box p={6}>
          <AppointmentScreeningForm appointment={appointment} />
        </Box>
      </Paper>
    </Formik>
  );
};

export default AppointmentScreeningFormContainer;
