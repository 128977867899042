import { defineMessages } from "react-intl";

export default defineMessages({
  titleFiles: {
    id: "Unknown.FilesField.titleFiles",
    defaultMessage: "Files",
  },
  helper: {
    id: "Unknown.FilesField.helper",
    defaultMessage: "Drag and drop files here or click to select files",
  },
});
