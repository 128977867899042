export const supabaseRequest = `
id,
appointment_patient_details (
  id,
  email,
  mobile_phone_number,
  patient: patient_id (
    id,
    city,
    country,
    postal_code,
    state,
    race,
    sex,
    patient_addresses (
      type,
      address
    )
  )
),
appointment_patient_details_form (
  id,
  exam_reason,
  surgical_history,
  cancer_history,
  height_ft,
  height_in,
  weight_lbs,
  referring_physician: referring_physician_id (
    name,
    zip_code,
    email,
    phone_number
  )
),
appointment_files (
  name,
  url,
  appointment_id
)
`;

export const maxMedicalInfoStrLength = 10000;
