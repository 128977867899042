import { defineMessages } from "react-intl";

const commonMessages = defineMessages({
  patientConsentFormSuccessMessage: {
    id: "PatientForm.CustomerPortalAppPatientConsentFormContainer.patientConsentFormSuccessMessage",
    defaultMessage: "Patient consent form saved successfully",
  },
});

export default commonMessages;
