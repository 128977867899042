import { useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { useUIContext } from "../../Unknown/UIContext";
import { FormConfig, Patient } from "./types";
import useValidationSchema from "./useValidationSchema";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import { useFormContext } from "../../Unknown/FormContext";
import useTranslations from "./useTranslations";

const useFormikComponent = (patient: Patient | null) => {
  const navigate = useNavigate();

  const { setAlert } = useUIContext();

  const { setFormAlert } = useFormContext();

  const { patientPrivacyFormSuccessMessage } = useTranslations();

  const provideCustomerPortalPatientPrivacyForm = useFirebaseAppFunction(
    "provideCustomerPortalPatientPrivacyForm",
  );

  const initialValues = useMemo<FormConfig["initialValues"]>(() => {
    return {
      patientSignature: false,
      patientUnerstand: false,
    };
  }, []);

  const validationSchema = useValidationSchema();
  const { runAsyncFunction } = useDefaultErrorWrapper();

  const onSubmit = useCallback<FormConfig["onSubmit"]>(async () => {
    if (!patient) return;

    try {
      await runAsyncFunction(provideCustomerPortalPatientPrivacyForm, {
        patientId: patient.id,
      });

      setFormAlert({
        isShown: true,
        severity: "success",
        message: patientPrivacyFormSuccessMessage,
      });

      navigate("/");
    } catch (error) {
      setAlert({
        isShown: true,
        severity: "error",
        message: (error as Error).message,
      });
    }
  }, [
    patient,
    setAlert,
    navigate,
    provideCustomerPortalPatientPrivacyForm,
    runAsyncFunction,
    setFormAlert,
    patientPrivacyFormSuccessMessage,
  ]);

  return {
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  };
};

export default useFormikComponent;
