import React, { FC } from "react";
import { useEffectOnce } from "react-use";
import { useNavigate } from "react-router-dom";
import CustomerPortalAppPatientConsentFormContainer from "../CustomerPortalAppPatientConsentFormContainer";
import { useFormContext } from "../../Unknown/FormContext";
import Box from "../../Unknown/Box";
import CircularProgress from "../../Unknown/CircularProgress";

const PatientConsentFormScreen: FC = () => {
  const { formPatientId } = useFormContext();
  const navigate = useNavigate();

  useEffectOnce(() => {
    if (!formPatientId) {
      navigate("/");
    }
  });

  if (!formPatientId) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        pt={10}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box maxWidth={800}>
      <CustomerPortalAppPatientConsentFormContainer patientId={formPatientId} />
    </Box>
  );
};

export default PatientConsentFormScreen;
