import React, { useCallback, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import Button from "../../Unknown/Button";
import CircularProgress from "../../Unknown/CircularProgress";
import { AlertParams, useUIContext } from "../../Unknown/UIContext";
import Alert from "../../Unknown/Alert";
import AlertTitle from "../../Unknown/AlertTitle";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import useTranslations from "./useTranslations";

export interface AppointmentPreviewReportCardActionButton {
  id: string;
  buttonTitle: string;
  isLoading?: boolean;
  buttonAction: () => void;
}

interface AppointmentPreviewReportCardProps {
  title: string;
  filename: string;
  patientId: string;
  appointmentId: string;
  appointmentReportId: string;
}

const AppointmentPreviewReportCard = ({
  title,
  filename,
  patientId,
  appointmentId,
  appointmentReportId,
}: AppointmentPreviewReportCardProps) => {
  const [isRequestingImages, setIsRequestingImages] = useState(false);
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  const [notification, setNotification] = useState<AlertParams | null>(null);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const translations = useTranslations();

  const { runAsyncFunction } = useDefaultErrorWrapper();

  const getAppointmentReportSignedUrl = useFirebaseAppFunction(
    "getAppointmentReportSignedUrl",
  );

  const requestImageArchiveDownloading = useFirebaseAppFunction(
    "requestImageArchiveDownloading",
  );

  const { setAlert } = useUIContext();

  const onDownloadReportClick = useCallback(async () => {
    setIsDownloadingReport(true);
    try {
      const { data } = await runAsyncFunction(getAppointmentReportSignedUrl, {
        appointmentId,
        appointmentReportId,
        fileName: filename,
        patientId,
      });

      window.open(data.url, "_self");
    } catch (error) {
      setAlert({
        isShown: true,
        severity: "error",
        message: (error as Error).message,
      });
    } finally {
      setIsDownloadingReport(false);
    }
  }, [
    appointmentId,
    appointmentReportId,
    filename,
    getAppointmentReportSignedUrl,
    patientId,
    runAsyncFunction,
    setAlert,
  ]);

  const onRequestImagesClick = useCallback(async () => {
    setIsRequestingImages(true);
    try {
      await runAsyncFunction(requestImageArchiveDownloading, {
        appointmentReportId,
      });

      setNotification({
        severity: "success",
        message: translations.requestSuccessMessage,
        description: translations.requestSuccessDescription,
        isShown: true,
      });
    } catch (error) {
      setNotification({
        severity: "error",
        message: translations.requestErrorMessage,
        description: translations.requestErrorDescription,
        isShown: true,
      });
    } finally {
      setIsRequestingImages(false);
    }
  }, [
    appointmentReportId,
    requestImageArchiveDownloading,
    runAsyncFunction,
    translations.requestErrorDescription,
    translations.requestErrorMessage,
    translations.requestSuccessDescription,
    translations.requestSuccessMessage,
  ]);

  const actionButtons: AppointmentPreviewReportCardActionButton[] = [
    {
      id: "requestImages",
      isLoading: isRequestingImages,
      buttonTitle: translations.btnRequestImages,
      buttonAction: onRequestImagesClick,
    },
    {
      id: "downloadReport",
      isLoading: isDownloadingReport,
      buttonTitle: translations.btnDownloadReport,
      buttonAction: onDownloadReportClick,
    },
  ];

  return (
    <Grid
      container
      flexDirection="column"
      gap={3.2}
      bgcolor="background.lightGray"
      borderRadius="4px"
      p={3.2}
    >
      <Typography variant="body2" fontWeight={500} lineHeight={1.57}>
        {title}
      </Typography>
      {notification && (
        <Alert severity={notification.severity}>
          <AlertTitle>{notification.message}</AlertTitle>
          {notification.description}
        </Alert>
      )}
      <Grid
        container
        direction={{ xs: "column", md: "row" }}
        justifyContent={{ xs: "center", md: "flex-end" }}
        spacing={3}
      >
        {actionButtons.map(({ buttonTitle, isLoading, buttonAction }) => {
          return (
            <Grid key={`${buttonTitle}`} item>
              <Button
                size="large"
                sx={{
                  whiteSpace: "normal",
                  minWidth: isLargeScreen ? "max-content" : "unset",
                }}
                variant="contained"
                disabled={isLoading}
                startIcon={
                  isLoading && <CircularProgress size={20} color="inherit" />
                }
                fullWidth={!isLargeScreen}
                onClick={() => buttonAction()}
              >
                {buttonTitle}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default AppointmentPreviewReportCard;
