import { useMemo } from "react";
import { useIntl } from "react-intl";
import { PatientProvideInformationFormProps } from "../CheckInAppPatientProvideInformationForm";
import messages from "./messages";
import commonMessages from "../../../common/messages";

const usePatientFormTranslations =
  (): PatientProvideInformationFormProps["formTranslations"] => {
    const { formatMessage } = useIntl();

    const translations = useMemo(() => {
      return {
        labelContactEmail: formatMessage(messages.labelContactEmail),
        labelContactPhone: formatMessage(messages.labelContactPhone),
        labelAddressPrimary: formatMessage(messages.labelAddressPrimary),
        labelAddressSecondary: formatMessage(messages.labelAddressSecondary),
        labelAddressCity: formatMessage(messages.labelAddressCity),
        labelAddressState: formatMessage(messages.labelAddressState),
        labelAddressPostalCode: formatMessage(messages.labelAddressPostalCode),
        labelAddressCountry: formatMessage(messages.labelAddressCountry),
        labelMedicalReason: formatMessage(messages.labelMedicalReason),
        labelRace: formatMessage(commonMessages.race),
        labelSex: formatMessage(commonMessages.sex),
        labelHeightFt: formatMessage(commonMessages.heightFt),
        labelHeightIn: formatMessage(commonMessages.heightIn),
        labelWeightLbs: formatMessage(commonMessages.weightLbs),
        labelMedicalSurgicalHistory: formatMessage(
          messages.labelMedicalSurgicalHistory,
        ),
        labelMedicalCancerHistory: formatMessage(
          messages.labelMedicalCancerHistory,
        ),
        labelReferringPracticeName: formatMessage(
          messages.labelReferringPracticeName,
        ),
        labelReferringPostalCode: formatMessage(
          messages.labelReferringPostalCode,
        ),
        labelReferringEmail: formatMessage(messages.labelReferringEmail),
        labelReferringPhone: formatMessage(messages.labelReferringPhone),
      };
    }, [formatMessage]);

    return translations;
  };

export default usePatientFormTranslations;
