import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AppointmentListScreen from "../../Appointment/AppointmentListScreen";
import CheckInAppPatientProvideInformationScreen from "../../Patient/CheckInAppPatientProvideInformationScreen";
import PatientChangeInformationScreen from "../../Patient/PatientChangeInformationScreen";
import AuthenticatedLayout from "../AuthenticatedLayout";
import CheckInAppPatientPrivacyFormScreen from "../../PatientForm/CheckInAppPatientPrivacyFormScreen";
import CheckInAppPatientConsentFormScreen from "../../PatientForm/CheckInAppPatientConsentFormScreen";
import CheckInAppointmentScreeningFormScreen from "../../AppointmentForm/CheckInAppointmentScreeningFormScreen";

const AuthenticatedRoot: FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthenticatedLayout>
            <AppointmentListScreen />
          </AuthenticatedLayout>
        }
      />
      <Route
        path="/appointment/:appointmentId/provide-patient-information"
        element={
          <AuthenticatedLayout>
            <CheckInAppPatientProvideInformationScreen />
          </AuthenticatedLayout>
        }
      />
      <Route
        path="/appointment/:appointmentId/change-patient-information"
        element={
          <AuthenticatedLayout>
            <PatientChangeInformationScreen />
          </AuthenticatedLayout>
        }
      />
      <Route
        path="/provide-privacy-form"
        element={
          <AuthenticatedLayout>
            <CheckInAppPatientPrivacyFormScreen />
          </AuthenticatedLayout>
        }
      />
      <Route
        path="/provide-consent-form"
        element={
          <AuthenticatedLayout>
            <CheckInAppPatientConsentFormScreen />
          </AuthenticatedLayout>
        }
      />
      <Route
        path="/provide-screening-form"
        element={
          <AuthenticatedLayout>
            <CheckInAppointmentScreeningFormScreen />
          </AuthenticatedLayout>
        }
      />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default AuthenticatedRoot;
