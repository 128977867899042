import { ButtonProps } from "@mui/material";

export const supabaseRequest = `
id,
status,
appointment_patient_details_form (
  id
),
center_time_slot: time_slot_id !inner (
  started_at
),
center: center_id (
  name,
  timezone,
  city,
  country,
  postal_code,
  state,
  center_addresses (
    type,
    address
  )
),
appointment_customer_details !inner (
  id,
  first_name,
  last_name,
  customer: customer_id !inner (
    id
  )
),
appointment_patient_details (
  id,
  first_name,
  last_name,
  patient: patient_id (
    id,
    patient_forms (
      id,
      type,
      expiration_at
    )
  )
),
appointment_offerings (
  id,
  side,
  appointment_package_id,
  center_offering: center_offering_id (
    name
  )
),
appointment_packages (
  id,
  center_package: center_package_id (
    name
  )
),
appointment_add_ons (
  id,
  center_add_on: center_add_on_id (
    name
  )
),
appointment_forms (
  type
),
appointment_reports (
  id,
  filename,
  appointment_offering: appointment_offering_id (
    id,
    side,
    center_offering:center_offering_id (
      name
    )
  )
)
`;

export const primaryAddressType = "primary";
export const secondaryAddressType = "secondary";

export const requiredInformationButtonProps: Record<
  "editPatient" | "default",
  ButtonProps
> = {
  editPatient: { variant: "outlined" },
  default: { variant: "contained" },
};

export const appointmentListIgnoreStatuses = [
  "canceled",
  "no_show",
  "incomplete",
  "abandoned",
  "checkout",
];
