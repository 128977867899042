import { useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { useUIContext } from "../../Unknown/UIContext";
import { FormConfig, Patient } from "./types";
import useValidationSchema from "./useValidationSchema";
import { useFormContext } from "../../Unknown/FormContext";
import useTranslations from "./useTranslations";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";

const useFormikComponent = (patient: Patient | null) => {
  const navigate = useNavigate();
  const { patientConsentFormSuccessMessage } = useTranslations();

  const { setAlert } = useUIContext();

  const { setFormAlert } = useFormContext();
  const provideCustomerPortalPatientConsentForm = useFirebaseAppFunction(
    "provideCustomerPortalPatientConsentForm",
  );

  const initialValues = useMemo<FormConfig["initialValues"]>(() => {
    return {
      patientSignature: false,
      pregnancyConfirmation: null,
    };
  }, []);

  const validationSchema = useValidationSchema();
  const { runAsyncFunction } = useDefaultErrorWrapper();

  const onSubmit = useCallback<FormConfig["onSubmit"]>(
    async (values) => {
      if (!patient) return;

      try {
        await runAsyncFunction(provideCustomerPortalPatientConsentForm, {
          patientId: patient.id,
          isPregnancyConfirmed: values.pregnancyConfirmation === "yes",
        });

        setFormAlert({
          isShown: true,
          severity: "success",
          message: patientConsentFormSuccessMessage,
        });

        navigate("/");
      } catch (error) {
        setAlert({
          isShown: true,
          severity: "error",
          message: (error as Error).message,
        });
      }
    },
    [
      patient,
      setAlert,
      navigate,
      setFormAlert,
      patientConsentFormSuccessMessage,
      runAsyncFunction,
      provideCustomerPortalPatientConsentForm,
    ],
  );

  return {
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  };
};

export default useFormikComponent;
