import React, { FC } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "../../Unknown/Button";
import Grid from "../../Unknown/Grid";
import { ActionButton } from "./types";

export type AppointmentPreviewActionButtonsProps = {
  actionButtons: ActionButton[];
};

const AppointmentPreviewActionButtons: FC<
  AppointmentPreviewActionButtonsProps
> = ({ actionButtons }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid
      container
      spacing={3}
      wrap="wrap"
      direction={{ xs: "column", md: "row" }}
      justifyContent={{ xs: "center", md: "flex-end" }}
    >
      {actionButtons.map(({ id, text, props }) => (
        <Grid key={id} item>
          <Button
            size="large"
            sx={{
              whiteSpace: "normal",
              minWidth: isLargeScreen ? "max-content" : "unset",
            }}
            fullWidth={!isLargeScreen}
            {...props}
          >
            {text}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default AppointmentPreviewActionButtons;
