import supabaseClient from "../../../common/supabaseClient";
import { ScreeningFormAppointment } from "./types";

export const supabaseRequest = `
  id,
  appointment_patient_details (
    id,
    first_name,
    last_name,
    date_birth,
    patient:patient_id (
      mrn
    )
  )
`;

const getAppointment = async (params: { appointmentId: string }) => {
  const { appointmentId } = params;

  const query = supabaseClient
    .from<ScreeningFormAppointment>("appointments")
    .select(supabaseRequest)
    .eq("id", appointmentId)
    .single();

  const { data, error } = await query;

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export default getAppointment;
