import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "Patient.CustomerPortalAppPatientProvideInformationForm.title",
    defaultMessage: "Patient Information",
  },
  description: {
    id: "Patient.CustomerPortalAppPatientProvideInformationForm.description",
    defaultMessage:
      "We need some additional information about you before you can receive your MRI scan.",
  },
  subtitleContactInformation: {
    id: "Patient.CustomerPortalAppPatientProvideInformationForm.subtitleContactInformation",
    defaultMessage: "Contact Information",
  },
  helperPhoneNumber: {
    id: "Patient.CustomerPortalAppPatientProvideInformationForm.helperPhoneNumber",
    defaultMessage: "Enter only numbers, including the area code",
  },
  subtitleAddress: {
    id: "Patient.CustomerPortalAppPatientProvideInformationForm.subtitleAddress",
    defaultMessage: "Address",
  },
  subtitleMedicalInformation: {
    id: "Patient.CustomerPortalAppPatientProvideInformationForm.subtitleMedicalInformation",
    defaultMessage: "Medical information",
  },
  descriptionMedicalInformation: {
    id: "Patient.CustomerPortalAppPatientProvideInformationForm.descriptionMedicalInformation",
    defaultMessage:
      "This information will help us best prepare for your MRI scan.",
  },
  subtitleReferringPhysician: {
    id: "Patient.CustomerPortalAppPatientProvideInformationForm.subtitleReferringPhysician",
    defaultMessage: "Referring Physician",
  },
  descriptionReferringPhysician: {
    id: "Patient.CustomerPortalAppPatientProvideInformationForm.descriptionReferringPhysician",
    defaultMessage: "Have you been referred by a physician?",
  },
  subtitleDoctorsOrder: {
    id: "Patient.CustomerPortalAppPatientProvideInformationForm.subtitleDoctorsOrder",
    defaultMessage: "Implant Card",
  },
  descriptionImplantCard: {
    id: "Patient.CustomerPortalAppPatientProvideInformationForm.descriptionImplantCard",
    defaultMessage:
      "Please upload an image of the {frontAndBack} of your implant card (if you have one) so we can confirm your implant is safe for MRI.",
  },
  frontAndBack: {
    id: "Patient.CustomerPortalAppPatientProvideInformationForm.frontAndBack",
    defaultMessage: "front and back",
  },
  btnCancel: {
    id: "Patient.CustomerPortalAppPatientProvideInformationForm.btnCancel",
    defaultMessage: "Cancel",
  },
  btnSubmit: {
    id: "Patient.CustomerPortalAppPatientProvideInformationForm.btnSubmit",
    defaultMessage: "Save",
  },
});
