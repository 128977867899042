import React, { FC } from "react";
import { inputsSpacing } from "./constants";
import Grid from "../Grid";
import Typography from "../Typography";
import DragDropFileFieldBase, {
  DragDropFileFieldBaseProps,
} from "../DragDropFileFieldBase";
import useTranslations from "./useTranslations";
import useStyles from "./useStyles";
import { AppointmentFile } from "../../Patient/CheckInAppPatientProvideInformationFormContainer/types";
import FormHelperText from "../FormHelperText";

export type FilesFieldProps = {
  dragDropFileFieldBaseProps: DragDropFileFieldBaseProps;
  files: File[];
  existedFiles: AppointmentFile[];
  errors?: string[];
};

const FilesField: FC<FilesFieldProps> = ({
  dragDropFileFieldBaseProps,
  files,
  errors,
  existedFiles,
}) => {
  const { titleFiles, helper } = useTranslations();

  const { titleFilesRoot } = useStyles();

  return (
    <Grid container spacing={inputsSpacing} direction="column">
      <Grid item xs>
        <DragDropFileFieldBase
          helperText={helper}
          {...dragDropFileFieldBaseProps}
        />
        {!!errors?.length && (
          <>
            {errors.map((errorMessage) => {
              return (
                <FormHelperText error key={errorMessage}>
                  {errorMessage}
                </FormHelperText>
              );
            })}
          </>
        )}
      </Grid>
      <Grid item xs>
        <Grid item xs>
          <Typography className={titleFilesRoot}>{titleFiles}</Typography>
        </Grid>
        {files.map((file) => (
          <Grid item key={`${file.name}-${file.lastModified}`}>
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {file.name}
            </Typography>
          </Grid>
        ))}
        {existedFiles.map((existedFile) => (
          <Grid item key={`${existedFile.name}-${existedFile.appointment_id}`}>
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {existedFile.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default FilesField;
