import { defineMessages } from "react-intl";

export default defineMessages({
  labelEmail: {
    id: "Auth.AuthCheckUserFormContainer.labelEmail",
    defaultMessage: "Email address",
  },
  errorEmailIsInvalid: {
    id: "Auth.AuthCheckUserFormContainer.errorEmailIsInvalid",
    defaultMessage: "Email address is invalid",
  },
  errorSendAttemptsLimit: {
    id: "Auth.AuthCheckUserFormContainer.errorSendAttemptsLimit",
    defaultMessage:
      "Resend code limit exceeded. Please try again in {minutes} minutes",
  },
});
