import { useCallback } from "react";
import { AnswerCustomChallengeErrorCode } from "../AuthContextProvider/types";
import useTranslations from "./useTranslations";

const useErrorMessages = () => {
  const {
    defaultError,
    errorCodeInvalid,
    errorEnterAttemptsLimit,
    errorResendAttemptsLimit,
  } = useTranslations();

  const getErrorMessageOnAnswerCustomChallenge = useCallback(
    (errorCode: AnswerCustomChallengeErrorCode) => {
      switch (errorCode) {
        case "AUTH_CODE_INVALID": {
          throw new Error(errorCodeInvalid);
        }
        case "ATTEMPTS_LIMIT": {
          throw new Error(errorEnterAttemptsLimit);
        }
        default: {
          throw new Error(defaultError);
        }
      }
    },
    [defaultError, errorCodeInvalid, errorEnterAttemptsLimit],
  );

  const getErrorMessageOnResendCode = useCallback(
    (errorCode: AnswerCustomChallengeErrorCode) => {
      switch (errorCode) {
        case "ATTEMPTS_LIMIT": {
          throw new Error(errorResendAttemptsLimit);
        }
        default: {
          throw new Error(defaultError);
        }
      }
    },
    [defaultError, errorResendAttemptsLimit],
  );

  return {
    getErrorMessageOnAnswerCustomChallenge,
    getErrorMessageOnResendCode,
  };
};

export default useErrorMessages;
