import React, { FC, ReactNode } from "react";
import Grid from "../../Unknown/Grid";
import Paper from "../../Unknown/Paper";
import Typography from "../../Unknown/Typography";
import { Appointment } from "./types";
import useGridItemsList from "./useGridItemsList";

export type AppointmentPreviewProps = Appointment & {
  children?: ReactNode;
};

const AppointmentPreview: FC<AppointmentPreviewProps> = ({
  person,
  date,
  center,
  centerServices,
  children,
}) => {
  const gridItemsList = useGridItemsList({ date, center, centerServices });
  const title = `${person.lastName}, ${person.firstName}`;

  return (
    <Paper>
      <Grid p={3} container direction="column" wrap="nowrap" spacing={0}>
        <Grid item>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid
          item
          container
          pt={4}
          spacing={3}
          columns={{ xs: 6, md: 12 }}
          wrap="wrap"
          direction={{ xs: "column", md: "row" }}
        >
          {gridItemsList.map((gridItems) => {
            return (
              <Grid
                key={gridItems.id}
                item
                container
                spacing={3}
                direction="column"
                wrap="nowrap"
                xs={6}
              >
                {gridItems.items.map(({ id, icon, texts }) => (
                  <Grid key={id} item container direction="row" wrap="nowrap">
                    <Grid item mr={2.5}>
                      {icon}
                    </Grid>
                    <Grid item container direction="column" wrap="nowrap">
                      {texts.map(({ text, props }) => (
                        <Grid key={text} item>
                          <Typography {...props}>{text}</Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            );
          })}
        </Grid>
        {children && (
          <Grid item pt={4}>
            {children}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default AppointmentPreview;
