import { ProvideCustomerPortalPatientInformationParams } from "@Shape-Digital/kudzu-data/lib/types/actions";
import { useCallback } from "react";
import useFirebaseStorageUploader from "../../../hooks/useFirebaseStorageUploader";

type ResultFile = NonNullable<
  ProvideCustomerPortalPatientInformationParams["doctorsOrderFiles"]
>[number];

const useFileUploader = () => {
  const firebaseStorageUploader = useFirebaseStorageUploader();

  const uploadFiles = useCallback(
    async (params: { appointmentId: string; files: File[] }) => {
      const { appointmentId, files } = params;

      const path = `appointment/${appointmentId}/files`;

      const filesData = await Promise.all(
        files.map(async (file) => {
          const { downloadUrl } = await firebaseStorageUploader({
            path,
            file,
          });

          const fileData: ResultFile = {
            name: file.name,
            url: downloadUrl,
          };

          return fileData;
        }),
      );

      return filesData;
    },
    [firebaseStorageUploader],
  );

  return uploadFiles;
};

export default useFileUploader;
