import { createClient } from "@supabase/supabase-js";

const { REACT_APP_SUPABASE_URL, REACT_APP_SUPABASE_KEY } = process.env;

const createSupabaseClient = () => {
  if (!REACT_APP_SUPABASE_URL) {
    throw new Error("Please provide REACT_APP_SUPABASE_URL in .env file");
  }

  if (!REACT_APP_SUPABASE_KEY) {
    throw new Error("Please provide REACT_APP_SUPABASE_KEY in .env file");
  }

  const client = createClient(REACT_APP_SUPABASE_URL, REACT_APP_SUPABASE_KEY, {
    shouldThrowOnError: true,
  });

  return client;
};

const supabaseClient = createSupabaseClient();

export default supabaseClient;
