import parseISO from "date-fns/parseISO";
import compareDesc from "date-fns/compareDesc";
import compareAsc from "date-fns/compareAsc";
import {
  convertDbDateToCenterTZ,
  isTimeZonePast,
} from "../../../common/dateHelpers";
import { Appointment } from "./types";
import { compareBooleanDesc } from "../../../common/booleanHelpers";

const sortAppointmentsComparator = (
  currentValue: Appointment,
  compareValue: Appointment,
) => {
  const currentValueStartedAt = parseISO(
    currentValue.center_time_slot.started_at,
  );
  const compareValueStartedAt = parseISO(
    compareValue.center_time_slot.started_at,
  );

  const isCurrentValuePast = isTimeZonePast({
    date: currentValueStartedAt,
    timezone: currentValue.center.timezone,
  });
  const isCompareValuePast = isTimeZonePast({
    date: compareValueStartedAt,
    timezone: compareValue.center.timezone,
  });

  const timeGroupComparator = compareBooleanDesc(
    isCurrentValuePast,
    isCompareValuePast,
  );

  if (timeGroupComparator) return timeGroupComparator;

  const currentValueStartedAtCenterTimezone = convertDbDateToCenterTZ({
    dateString: currentValue.center_time_slot.started_at,
    timezone: currentValue.center.timezone,
  });

  const compareValueStartedAtCenterTimezone = convertDbDateToCenterTZ({
    dateString: compareValue.center_time_slot.started_at,
    timezone: compareValue.center.timezone,
  });

  if (isCurrentValuePast) {
    return compareDesc(
      currentValueStartedAtCenterTimezone,
      compareValueStartedAtCenterTimezone,
    );
  }

  return compareAsc(
    currentValueStartedAtCenterTimezone,
    compareValueStartedAtCenterTimezone,
  );
};

export default sortAppointmentsComparator;
