import { SupabaseClient } from "@supabase/supabase-js";
import { SUPABASE_TOKEN } from "../common/constants";
import supabaseClient from "../common/supabaseClient";

const { REACT_APP_SUPABASE_KEY } = process.env;

const useSupabase = (): SupabaseClient => supabaseClient;

export default useSupabase;

export const setSupabaseAccessToken = (token: string) => {
  supabaseClient.auth.setAuth(token);
  localStorage.setItem(SUPABASE_TOKEN, token);
};

export const getSupabaseAccessToken = () => {
  return localStorage.getItem(SUPABASE_TOKEN);
};

export const initializeSupabaseAccessToken = () => {
  const supabaseToken =
    localStorage.getItem(SUPABASE_TOKEN) || REACT_APP_SUPABASE_KEY || "";
  setSupabaseAccessToken(supabaseToken);
};
