import { alpha, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette }) => {
  return {
    captionRoot: {
      fontSize: 12,
      color: alpha(palette.common.black, 0.6),
    },
  };
});

export default useStyles;
