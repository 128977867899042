import * as Yup from "yup";

const useValidationSchema = () => {
  return Yup.object().shape({
    patientSignature: Yup.boolean().oneOf([true]).required(),
    patientUnerstand: Yup.boolean().oneOf([true]).required(),
  });
};

export default useValidationSchema;
