import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "Patient.PatientChangeInformationForm.title",
    defaultMessage: "Patient Information",
  },
  description: {
    id: "Patient.PatientChangeInformationForm.description",
    defaultMessage:
      "We need some additional information about you before you can receive your MRI scan.",
  },
  subtitleContactInformation: {
    id: "Patient.PatientChangeInformationForm.subtitleContactInformation",
    defaultMessage: "Contact Information",
  },
  helperPhoneNumber: {
    id: "Patient.PatientChangeInformationForm.helperPhoneNumber",
    defaultMessage: "Enter only numbers, including the area code",
  },
  subtitleAddress: {
    id: "Patient.PatientChangeInformationForm.subtitleAddress",
    defaultMessage: "Address",
  },
  btnCancel: {
    id: "Patient.PatientChangeInformationForm.btnCancel",
    defaultMessage: "Cancel",
  },
  btnSubmit: {
    id: "Patient.PatientChangeInformationForm.btnSubmit",
    defaultMessage: "Save",
  },
});
