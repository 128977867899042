import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";
import { ValidationSchemaTranslations } from "./types";

const useValidationSchemaTranslations = (): ValidationSchemaTranslations => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      labelContactEmail: formatMessage(messages.labelContactEmail),
      labelContactPhone: formatMessage(messages.labelContactPhone),
      labelAddressPrimary: formatMessage(messages.labelAddressPrimary),
      labelAddressSecondary: formatMessage(messages.labelAddressSecondary),
      labelAddressCity: formatMessage(messages.labelAddressCity),
      labelAddressState: formatMessage(messages.labelAddressState),
      labelAddressPostalCode: formatMessage(messages.labelAddressPostalCode),
      labelAddressCountry: formatMessage(messages.labelAddressCountry),
      errorEmailIsInvalid: formatMessage(messages.errorEmailIsInvalid),
    };
  }, [formatMessage]);

  return translations;
};

export default useValidationSchemaTranslations;
